import React from "react";

import ConfirmModal from "components/confirm-modal";

const PickupBlockCreate = ({ show, onConfirm, close }) => (
  <ConfirmModal
    show={show}
    onConfirm={onConfirm}
    close={() => close()}
    confirmText="Are you sure you want to create these pickup block(s)?"
    title="Pickup Blocks"
    hideButton
  />
);

export default PickupBlockCreate;
