import {
  CREATE_PICKUP_COMPLETED,
  RECEIVE_PICKUPS,
  REQUEST_PICKUPS,
  REQUEST_PICKUPS_DONOR_PAGE,
  RECEIVE_PICKUPS_DONOR_PAGE,
  REQUEST_PICKUP,
  RECEIVE_PICKUP,
  CLEAR_PICKUPS,
  UPDATE_PICKUP_COMPLETED,
} from "./pickups.actions";

const defaultPickupState = {
  isFetching: false,
  loaded: false,
  pickups: [],
  minPickupsDonorPage: {
    isFetching: false,
    loaded: false,
    pickups: [],
  },
  currentPickup: {
    isFetching: false,
    loaded: false,
    pickup: null,
  },
};

function pickups(state = defaultPickupState, action) {
  switch (action.type) {
    case REQUEST_PICKUPS:
      return {
        ...state,
        loaded: false,
        isFetching: true,
      };
    case RECEIVE_PICKUPS:
      return {
        ...state,
        pickups: action.pickups,
        count: action.count,
        pagination: action.pagination,
        lastUpdated: action.receivedAt,
        loaded: true,
        isFetching: false,
      };

    case REQUEST_PICKUPS_DONOR_PAGE:
      return {
        ...state,
        minPickupsDonorPage: {
          ...state.minPickupsDonorPage,
          loaded: false,
          isFetching: true,
        },
      };
    case RECEIVE_PICKUPS_DONOR_PAGE:
      return {
        ...state,
        minPickupsDonorPage: {
          pickups: action.pickups,
          loaded: true,
          isFetching: false,
        },
      };
    case REQUEST_PICKUP:
      return {
        ...state,
        currentPickup: {
          loaded: false,
          isFetching: true,
        },
      };
    case RECEIVE_PICKUP:
      return {
        ...state,
        currentPickup: {
          pickup: action.pickup,
          loaded: true,
          isFetching: false,
        },
      };
    case UPDATE_PICKUP_COMPLETED:
      return {
        ...state,
        pickups: state.pickups.map((pickup) =>
          pickup.id === action.pickup.id ? action.pickup : pickup
        ),
        minPickupsDonorPage: {
          ...state.minPickupsDonorPage,
          pickups: state.minPickupsDonorPage.pickups.map((pickup) =>
            pickup.id === action.pickup.id ? action.pickup : pickup
          ),
        },
        currentPickup: {
          ...state.currentPickup,
          pickup:
            action.pickup.id !== state.currentPickup.pickup.id
              ? state.currentPickup.pickup
              : { ...state.currentPickup.pickup, ...action.pickup },
        },
      };

    case CREATE_PICKUP_COMPLETED:
      return {
        ...state,
        pickups: [...state.pickups, action.pickup],
        minPickupsDonorPage: {
          ...state.minPickupsDonorPage,
          pickups: [...state.minPickupsDonorPage.pickups, action.pickup],
        },
      };

    case CLEAR_PICKUPS:
      return { ...defaultPickupState };
    default:
      return state;
  }
}

export default pickups;
