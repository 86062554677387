import React, { useCallback, useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import { formatQueryDate, dateOnly } from "utils/dates";
import PageLayout from "layouts/pages/page-default";
import MarketingReportsForm from "forms/create-update-campaign";
import Modal from "components/modal";
import ConfirmModal from "components/confirm-modal";
import Tabs from "components/tabs";
import { Table, RowActions, checkComponentOriginProp } from "components/table";
import { sortByDateString } from "utils/helpers";
import { getDate } from "utils/dates";

const CampaignsComponent = (props) => {
  const {
    createMarketingReports,
    isFetching,
    requestMarketingReports,
    marketingReports,
    updateMarketingReports,
    deleteMarketingReports,
  } = props;

  const [showEnabled, setShowEnabled] = useState(true);
  const [formattedReports, setFormattedReports] = useState([]);
  const [selectedReportId, setSelectedReport] = useState("");
  const [selectedDeleteId, setSelectedDelete] = useState("");
  const [reportFormOpen, setReportFormOpen] = useState(false);
  const [selectedArchiveId, setSelectedArchive] = useState("");
  const [tableData, setTableData] = useState([]);
  const [reportToCopyId, setReportToCopyId] = useState(null);
  const [reportCopied, setReportCopied] = useState({});

  const firstRender = useRef(true);

  const refreshMarketingReports = useCallback(() => {
    requestMarketingReports().then(({ marketingReports }) => {
      formatReports(marketingReports);
    });
  }, [requestMarketingReports]);

  useEffect(() => {
    if (firstRender.current) {
      refreshMarketingReports();
      firstRender.current = false;
    } else {
      formatReports(marketingReports);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketingReports, refreshMarketingReports]);

  useEffect(() => {
    setTableData(
      formattedReports.filter((campaign) => campaign.disabled !== showEnabled)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedReports, showEnabled]);

  const formatReports = (reports) => {
    const reportsForTable = reports.map((report) => {
      const { endDate, startDate, createdAt } = report;

      const formattedCreatedAt = dateOnly(createdAt);
      const formattedStartDate = dateOnly(startDate);
      const formattedEndDate = endDate ? dateOnly(endDate) : "";

      return {
        ...report,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        createdAt: formattedCreatedAt,
      };
    });

    setFormattedReports(reportsForTable);
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Start Date",
      accessor: "startDate",
      sortType: (a, b) => {
        return sortByDateString(a.values.startDate, b.values.startDate);
      },
    },
    {
      Header: "End Date",
      accessor: "endDate",
      sortType: (a, b) => {
        return sortByDateString(a.values.endDate, b.values.endDate);
      },
    },
    {
      Header: "Created At",
      accessor: "createdAt",
      sortType: (a, b) => {
        return sortByDateString(a.values.createdAt, b.values.createdAt);
      },
    },
  ];

  const actions = [
    {
      Header: "Actions",
      Component: (props) =>
        checkComponentOriginProp(props, (original) => (
          <RowActions
            id={original.id}
            actions={[
              {
                text: "Edit",
                icon: "edit",
                onClick: () => {
                  setSelectedReport(original.id);
                  setReportFormOpen(true);
                },
              },
              {
                text: "Delete",
                icon: "delete",
                onClick: () => setSelectedDelete(original.id),
              },
              {
                text: "Export",
                icon: "download",
                onClick: () => handleGenerateCsv(original.id),
              },
              {
                text: showEnabled ? "Archive" : "Unarchive",
                icon: "archive",
                onClick: () => setSelectedArchive(original.id),
              },
            ]}
          />
        )),
    },
  ];

  const formSubmit = (data) => {
    data.endDate = getDate(data.endDate);
    data.startDate = getDate(data.startDate);
    const type = !selectedReportId
      ? () => createMarketingReports(data)
      : () => updateMarketingReports(data);

    return type().then((response) => {
      if (response.marketingReport) {
        setReportToCopyId(null);
        setReportCopied({});
        setSelectedReport("");
        setReportFormOpen(false);
      }
    });
  };

  const findReportToEdit = () => {
    const report = marketingReports.filter(
      (report) => report.id === selectedReportId
    );
    return report[0];
  };

  const findReportToCopy = (id) => {
    const report = marketingReports.filter((report) => report.id === id);
    if (report[0]) {
      setReportCopied({
        name: report[0].name,
        zipCodes: report[0].zipCodes,
        startDate: report[0].startDate,
        endDate: report[0].endDate,
      });
    }
  };

  const findReportToDelete = () => {
    const report =
      marketingReports.filter((report) => report.id === selectedDeleteId)[0] ||
      [];
    const { name = "" } = report;
    return name;
  };

  const findReportToArchive = () => {
    const report =
      marketingReports.filter((report) => report.id === selectedArchiveId)[0] ||
      [];
    const { name = "" } = report;
    return name;
  };

  const handleCancel = () => {
    setSelectedReport("");
    setReportToCopyId(null);
    setReportCopied({});
    setReportFormOpen(false);
  };

  const handleDelete = () => {
    deleteMarketingReports(selectedDeleteId);
    setSelectedDelete("");
  };

  const handleArchive = () => {
    const report =
      marketingReports.filter((report) => report.id === selectedArchiveId)[0] ||
      [];

    updateMarketingReports({
      ...report,
      startDate: formatQueryDate(report.startDate),
      endDate: formatQueryDate(report.endDate),
      disabled: !report.disabled,
    }).then(refreshMarketingReports);
  };

  const handleGenerateCsv = (id) => {
    // Find matching marketing report
    const report = marketingReports.filter((report) => report.id === id)[0];
    const { startDate, endDate } = report;

    // Setup API URL
    let apiHostname = window.location.origin;
    if (process.env.NODE_ENV === "development") {
      apiHostname = "https://localhost:3002";
    }
    const baseUrl = `${apiHostname}/api`;
    let exportUrl = `${baseUrl}/users/csv?marketingReportId=${id}&startDate=${startDate}`;

    if (endDate) {
      exportUrl += `&endDate=${endDate}`;
    } else {
      exportUrl += `&endDate=${new Date().toISOString()}`;
    }

    window.open(exportUrl, "_self");
  };

  const AddNew = (props) => (
    <Button onClick={() => setReportFormOpen(true)}>Add New</Button>
  );

  return (
    <PageLayout title="Campaigns" actionComponents={[AddNew]}>
      <div>
        <Tabs active={showEnabled} onChange={setShowEnabled}>
          <Tabs.Tab value={true}>Enabled</Tabs.Tab>
          <Tabs.Tab value={false}>Archived</Tabs.Tab>
        </Tabs>
        <Table
          data={tableData}
          loading={isFetching} // Display the loading overlay when we need it
          columns={columns}
          actions={actions}
          defaultSorted={[{ desc: true, id: "startDate" }]}
          pageSize={20}
        />
      </div>

      <Modal
        show={reportFormOpen}
        title={selectedReportId ? "Edit Campaign" : "New Campaign"}
        onHide={handleCancel}
      >
        <MarketingReportsForm
          actionButtons={
            selectedReportId
              ? [
                  {
                    label: "Copy",
                    action: async (props) => {
                      setSelectedReport("");
                      await props.onCancel();
                      setReportToCopyId(props.initialValues.id);
                      findReportToCopy(props.initialValues.id);
                      setReportFormOpen(true);
                    },
                  },
                ]
              : []
          }
          onSubmit={formSubmit}
          onCancel={handleCancel}
          initialValues={
            selectedReportId
              ? findReportToEdit()
              : reportToCopyId
              ? reportCopied
              : {}
          }
        />
      </Modal>

      <ConfirmModal
        title={`Please Confirm`}
        confirmText={`Remove ${findReportToDelete()}?`}
        confirmSubText={`This action can't be undone. This campaign will be lost forever.`}
        onConfirm={() => handleDelete()}
        close={() => setSelectedDelete("")}
        show={!!selectedDeleteId}
        hideButton
      />

      <ConfirmModal
        title="Please Confirm"
        confirmText={`Archive ${findReportToArchive()}?`}
        confirmSubText="Are you sure you'd like to archive this campaign?"
        onConfirm={() => handleArchive()}
        close={() => setSelectedArchive("")}
        show={!!selectedArchiveId}
        hideButton
      />
    </PageLayout>
  );
};

export default CampaignsComponent;
