import React from "react";
import { Button } from "react-bootstrap";
import Icon from "components/icon";
import ConfirmModal from "components/confirm-modal";

export const RenderButtonPanel = ({
  isPending,
  isCancelled,
  openPhones,
  pickup,
  setCurrentPickup,
  startPickup,
  updatePickup,
  setConfirmStatusModal,
}) => {
  return (
    <div className="driver-pickup-bottom-wrapper">
      <div className="panel">
        <Button
          variant="outline-primary"
          onClick={() => {
            openPhones(true);
          }}
        >
          <Icon type="phone" />
        </Button>
        <Button
          variant="outline-primary"
          onClick={() => {
            window.open(
              "https://maps.google.com?q=" +
                pickup.location.coordinates.lat +
                "," +
                pickup.location.coordinates.lng
            );
          }}
        >
          <Icon type="map" />
        </Button>

        {!isCancelled &&
          (!pickup.nextStopNotificationSent ? (
            <ConfirmModal
              title={`Start Pickup`}
              confirmText={`Confirm you want to start this pickup`}
              confirmSubText={`A notification will be sent to the donor that the driver is on the way.`}
              onConfirm={() => {
                startPickup(pickup.id)
                  .then((res) => {
                    setCurrentPickup({ ...pickup, ...res.data });
                  })
                  .catch(() => {});
              }}
              buttonText="Start Pickup"
            />
          ) : !pickup.arrivedAt ? (
            <ConfirmModal
              title="Mark Arrived"
              confirmText="Confirm you have arrived"
              confirmSubText="A notification will be sent to the donor that the driver has arrived."
              onConfirm={() => {
                updatePickup({
                  ...pickup,
                  arrivedAt: new Date(),
                }).then((res) => {
                  setCurrentPickup({ ...pickup, ...res });
                });
              }}
              buttonText="Mark Arrived"
            />
          ) : (
            <Button
              variant="primary"
              onClick={() => {
                setConfirmStatusModal(true);
              }}
            >
              {isPending ? "Done" : "Change Status"}
            </Button>
          ))}
      </div>
    </div>
  );
};
