import { GeoJSON } from "react-leaflet";
import { useEffect, useRef } from "react";
const ZoneLayer = ({ zone, onClick, chunks, highlightedZip, isActiveZone }) => {
  const computeGeoJSON = (chunks, zone) => {
    if (!chunks || !zone) {
      return null;
    }
    const zips = new Set(zone.zipCodes);
    return {
      type: "FeatureCollection",
      features: chunks
        .map(({ data }) => data.features)
        .flat()
        .filter((feature) => zips.has(feature.properties.ZCTA5CE10)),
    };
  };

  //update layers on data changed
  useEffect(() => {
    if (geoJsonLayer.current) {
      geoJsonLayer.current.clearLayers().addData(computeGeoJSON(chunks, zone));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chunks, zone.zipCodes]);

  // change style of hovered feature in zone display childs
  useEffect(() => {
    if (geoJsonLayer.current) {
      geoJsonLayer.current.setStyle((e) => {
        return Number(highlightedZip) === Number(e.properties.ZCTA5CE10)
          ? setStyle({ fillOpacity: 0.5 })
          : setStyle({});
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedZip]);

  useEffect(() => {
    if (geoJsonLayer.current) {
      isActiveZone && geoJsonLayer.current.bringToFront();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveZone, zone]);

  const setStyle = ({ color, fillColor, fillOpacity = 0.3, ...rest }) => {
    return {
      color: color || `#${zone.color}`,
      fillColor: fillColor || `#${zone.color}`,
      fillOpacity,
      // zIndex: isActiveZone ? 1000 : 0,
      ...rest,
    };
  };

  const onMouseOver = (event) => {
    event.target.setStyle(setStyle({ fillOpacity: 0.5 }));
  };
  const onMouseOut = (event) => {
    event.target.setStyle(setStyle({}));
  };

  const onEachFeature = (feature, layer) => {
    layer.on({
      mouseover: onMouseOver,
      mouseout: onMouseOut,
    });
  };

  const geoJsonLayer = useRef(null);

  return (
    <GeoJSON
      ref={geoJsonLayer}
      eventHandlers={{
        click: onClick,
      }}
      onEachFeature={onEachFeature}
      style={() => setStyle({})}
      data={computeGeoJSON(chunks, zone)}
    />
  );
};
export default ZoneLayer;
