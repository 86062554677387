import { connect } from "react-redux";
import { compose } from "redux";
import { requestSettings } from "actions/settings.actions";
import { requestCommon } from "actions/common.actions";
import { showToast } from "actions/toast.actions";
import { useEffect } from "react";

const GlobalSettings = (props) => {
  const { settings, common, fetchSettings, fetchCommon } = props;

  useEffect(() => {
    if (!settings.globalSettings) {
      fetchSettings();
    }
  }, [settings.globalSettings]);
  useEffect(() => {
    if (!common.loaded) {
      fetchCommon();
    }
  }, [common.loaded]);
  if (settings.fetchFailed) {
    showToast({
      message: "Unable to fetch app settings",
      type: "error",
      noClose: true,
    });
    return null;
  }
  if (common.failed) {
    showToast({
      message: "Unable to fetch app common data",
      type: "error",
    });
  }

  return props.children;
};

const mapStateToProps = (state) => ({
  settings: {
    globalSettings: state.settings.globalSettings,
    fetchFailed: state.settings.failed,
    isComplete: state.settings.hasBeenFetched,
  },
  common: {
    loaded: state.common.loaded,
    data: state.common.data,
    failed: state.common.failed,
  },
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    fetchSettings: () => dispatch(requestSettings()),
    fetchCommon: () => dispatch(requestCommon()),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(GlobalSettings);
