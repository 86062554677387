import { getUsers } from "api/users.js";

export const CLEAR_SEARCH_CUSTOMERS = "CLEAR_SEARCH_CUSTOMERS";
export const SEARCH_CUSTOMERS = "SEARCH_CUSTOMERS";
export const RECEIVE_CUSTOMERS = "RECEIVE_CUSTOMERS";

export function searchCustomers(query = {}) {
  return (dispatch) => {
    dispatch({ type: SEARCH_CUSTOMERS, data: { query } });

    getUsers(query)
      .then((res) => dispatch(receiveCustomers(res.data)))
      .catch((res) => console.error(res));
  };
}

function receiveCustomers(res = {}) {
  const { data, count, pagination } = res;
  return {
    type: RECEIVE_CUSTOMERS,
    customers: data,
    count: count,
    pagination: pagination,
    receivedAt: Date.now(),
  };
}

export function clearSearchCustomers() {
  return { type: CLEAR_SEARCH_CUSTOMERS };
}
