import { useCallback } from "react";
import NotificationTemplatesContext from "./context";
import {
  getNotificationTemplates,
  updateNotificationTemplate,
} from "../../api/notification-templates";
import useResource from "../useResource";

const Provider = ({ children }) => {
  const [
    notificationTemplates,
    setNotificationTemplates,
    notificationTemplatesLoading,
    notificationTemplatesError,
    clearNotificationTemplatesError,
    wrapper,
  ] = useResource([]);

  const getTemplates = wrapper(
    useCallback(
      () => getNotificationTemplates().then(setNotificationTemplates),
      [setNotificationTemplates]
    )
  );

  const updateTemplate = wrapper(
    useCallback(
      (slug, data) =>
        updateNotificationTemplate(slug, data).then((updated) =>
          setNotificationTemplates(
            notificationTemplates.map((template) =>
              template.slug === updated.slug ? updated : template
            )
          )
        ),
      [notificationTemplates, setNotificationTemplates]
    )
  );

  return (
    <NotificationTemplatesContext.Provider
      value={{
        notificationTemplates,
        notificationTemplatesLoading,
        notificationTemplatesError,
        getNotificationTemplates: getTemplates,
        updateNotificationTemplate: updateTemplate,
        clearNotificationTemplatesError,
      }}
    >
      {children}
    </NotificationTemplatesContext.Provider>
  );
};

export default Provider;
