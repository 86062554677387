/**
 *
 * Icon
 *
 */

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faSearch,
  faUserPlus,
  faAnalytics,
  faBullseyePointer,
  faUserFriends,
  faClock,
  faUsersCog,
  faCalendarAlt,
  faTruck,
  faUser,
  faPhone,
  faCouch,
  faBox,
  faFlag,
  faMap,
  faFileAlt,
  faMapMarker,
  faLanguage,
  faEllipsisH,
  faMapSigns,
  faCamera,
  faImage,
} from "@fortawesome/pro-duotone-svg-icons";
import {
  faArrowUpRightFromSquare,
  faAnglesUpDown,
} from "@fortawesome/pro-regular-svg-icons";

import {
  faDownload,
  faTrash,
  faEnvelope,
  faComments,
  faBars,
  faTimes,
  faCheck,
  faEdit,
  faBell,
  faArchive,
  faKey,
  faChevronDown,
  faChevronUp,
  faCog,
  faAngleLeft,
  faAngleRight,
  faArrowUpArrowDown,
} from "@fortawesome/pro-solid-svg-icons";

const Icon = function ({ type = "default", onClick, style }) {
  const icons = {
    default: faTachometerAlt,
    dashboard: faTachometerAlt,
    search: faSearch,
    addDonor: faUserPlus,
    analytics: faAnalytics,
    download: faDownload,
    campaigns: faBullseyePointer,
    users: faUserFriends,
    timeWindow: faClock,
    regions: faTruck,
    userSettings: faUsersCog,
    calendar: faCalendarAlt,
    edit: faEdit,
    truck: faTruck,
    close: faTimes,
    check: faCheck,
    delete: faTrash,
    mail: faEnvelope,
    user: faUser,
    phone: faPhone,
    cog: faCog,
    furniture: faCouch,
    standard: faBox,
    flag: faFlag,
    chevronDown: faChevronDown,
    chevronUp: faChevronUp,
    map: faMap,
    password: faKey,
    fileAlt: faFileAlt,
    bell: faBell,
    archive: faArchive,
    mapMarker: faMapMarker,
    language: faLanguage,
    ellipsis: faEllipsisH,
    comments: faComments,
    bars: faBars,
    mapSigns: faMapSigns,
    camera: faCamera,
    image: faImage,
    angleLeft: faAngleLeft,
    angleRight: faAngleRight,
    fullscreen: faArrowUpRightFromSquare,
    reAssign: faArrowUpArrowDown,
    sortAsc: faChevronDown,
    sortDesc: faChevronUp,
    sortable: faAnglesUpDown,
  };

  return (
    <FontAwesomeIcon
      icon={icons[type]}
      onClick={onClick}
      style={{ ...style, ...(onClick ? { cursor: "pointer" } : null) }}
    />
  );
};

Icon.propTypes = {};

export default Icon;
