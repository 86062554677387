import React from "react";

import Forbiden from "layouts/forbiden";

export const RoleGuard = ({ allowedRoles, children, auth, state }) => {
  const userType = auth.credentials.userType?.label;

  if (!userType) {
    return;
  }
  if (!allowedRoles.includes(userType)) {
    return <Forbiden />;
  }

  return children;
};
