const newZoneTemplate = (stopsSetting) => ({
  id: -1,
  color: `${Math.floor(Math.random() * 0xffffff)
    .toString(16)
    .padStart(6, "0")}`, // Generate a random color
  name: "",
  pickupTypeId: 1,
  zipCodes: [],
  defaultBlockStops: stopsSetting?.value?.stops,
});
export default newZoneTemplate;
