import { connect } from "react-redux";
import {
  requestPickupBlocks,
  clearPickupBlocks,
} from "actions/pickup-blocks.actions";
import ZipPickupDateSearch from "./zip-pickup-date-search.component";
import { PickupTypesProvider } from "contexts/pickupTypes";

const mapStateToProps = (state, ownProps) => ({
  pickupBlocks: {
    ...state.pickupBlocks,
  },
  pickupTypes: state.common.data.pickupTypes,
  ownProps: ownProps,
});

const mapDispatchToProps = {
  requestPickupBlocks,
  clearPickupBlocks,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => (
  <PickupTypesProvider>
    <ZipPickupDateSearch {...props} />
  </PickupTypesProvider>
));
