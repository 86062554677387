import React from "react";
import RenderIf from "hoc/render-if";
import BoxTitle from "./box-title.components";

const Box = (props) => (
  <div className={"bg-white p-4 mb-4"}>
    <RenderIf condition={!!props.title}>
      <BoxTitle>{props.title}</BoxTitle>
    </RenderIf>
    {props.children}
  </div>
);

export default Box;
