import { connect } from "react-redux";
import CreateUpdateDonor from "./create-update-donor.component";
import withForm from "forms/form-class";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

const mapStateToProps = (state, ownProps) => {
  return {
    leadSources: [...state.common.data.leadSource],

    languages: [...state.common.data.languages],

    editing: !!ownProps.initialValues?.id,

    onSubmit: (values) => {
      if (values.leadSourceId === "-1") delete values.leadSourceId;

      return ownProps.onSubmit(values);
    },
  };
};

// Connect Redux Form
const FormConnected = withForm(CreateUpdateDonor, "NewDonor");

// Connect Redux Form
export default connect(mapStateToProps, mapDispatchToProps)(FormConnected);
