import { useRef, useState } from "react";
import { Button, Form, ProgressBar, Image } from "react-bootstrap";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { getCroppedImg } from "../../utils/helpers";
import Icon from "components/icon";

const EditUserProfileImage = ({
  user,
  changeProfileImage,
  resetProfileImage,
}) => {
  const [uploading, setUploading] = useState(false);
  const [cropOpts, setCropOpts] = useState(false);
  const cropImg = useRef();

  return uploading ? (
    <ProgressBar now={100} animated />
  ) : (
    <>
      {cropOpts ? (
        <>
          <div id="user-profile-crop">
            <ReactCrop
              crop={cropOpts.crop}
              onChange={(crop) => {
                if (crop.width === 0 || crop.height === 0) return;

                setCropOpts({
                  ...cropOpts,
                  crop,
                });
              }}
              src={cropOpts.src}
              onImageLoaded={(img) => {
                cropImg.current = img;

                const defaultSelectionSize = Math.min(img.width, img.height);

                setCropOpts({
                  ...cropOpts,
                  crop: {
                    ...cropOpts.crop,
                    width: defaultSelectionSize,
                    height: defaultSelectionSize,
                    x: (img.width - defaultSelectionSize) / 2,
                    y: (img.height - defaultSelectionSize) / 2,
                  },
                });
              }}
            />
          </div>
          <div style={{ textAlign: "center", paddingTop: 10 }}>
            <Button
              onClick={async () => {
                setUploading(true);

                await changeProfileImage(
                  await getCroppedImg(cropImg.current, cropOpts.crop)
                );

                URL.revokeObjectURL(cropOpts.src);
                setCropOpts(false);
                setUploading(false);
              }}
            >
              Save
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className={"d-flex justify-content-center"}>
            <label style={{ cursor: "pointer" }}>
              <div id="user-profile-image-container">
                <Image
                  style={{ width: 128, height: 128, gridRow: 1, gridColumn: 1 }}
                  src={user.profileImage}
                  roundedCircle
                />
                <div>
                  <Icon type="camera" />
                </div>
              </div>
            </label>

            {user?.profileImageId && (
              <Icon type={"close"} onClick={resetProfileImage}>
                Reset Profile Image
              </Icon>
            )}
          </div>
          <Form.Group>
            <Form.Label>Select a Image</Form.Label>
            <Form.Control
              type="file"
              id="profile-image"
              accept="image/png, image/jpeg"
              onChange={async (e) => {
                setCropOpts({
                  crop: {
                    aspect: 1,
                  },
                  src: URL.createObjectURL(e.target.files[0]),
                });
              }}
            />
          </Form.Group>
        </>
      )}
    </>
  );
};

export default EditUserProfileImage;
