import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import "chartjs-adapter-moment";
import PropTypes from "prop-types";

const DateRangeChart = (props) => {
  DateRangeChart.propTypes = {
    dataset: PropTypes.array.isRequired,
    labels: PropTypes.array.isRequired,
  };

  const { dataset, labels } = props;

  return (
    <Bar
      data={{
        datasets: dataset,
        labels,
      }}
      options={{
        scales: {
          x: {
            type: "time",
            time: {
              unit: "day",
            },
          },

          y: {
            beginAtZero: true,
            suggestedMax: 10,
            ticks: {
              callback: function (value) {
                if (value % 1 === 0) {
                  return value;
                }
              },
            },
          },
        },
      }}
    />
  );
};

export default DateRangeChart;
