import React, { Component } from "react";
import CreateUpdateRegionForm from "forms/create-update-region";
import {
  updateRegion,
  createRegion,
  requestRegions,
} from "actions/regions.actions";
import { connect } from "react-redux";
// import { withRouter } from "react-router";
import { showToast } from "actions/toast.actions";
import Box from "components/box";
import { useNavigate } from "react-router";

class RegionForm extends Component {
  handleRegionSubmit = async (data) => {
    const isUpdating = Boolean(this.props.region);

    if (data.separateFurniture) {
      delete data.numberOfVehicles;
      delete data.defaultServiceLength;
    } else {
      delete data.furnitureVehicleCount;
      delete data.standardVehicleCount;
      delete data.defaultFurnitureServiceLength;
      delete data.defaultStandardServiceLength;
    }

    try {
      if (isUpdating) {
        if (data.driverPriority) {
          // Find drivers with modified priorities and then update them
          await Promise.all(
            data.driverPriority
              .filter(
                (driver) =>
                  driver.priority !== this.props.users[driver.id].priority
              )
              .map(this.props.updateUser)
          );
        }

        data.driverPriority = undefined;

        await this.props.updateRegion({ payload: data });
      } else {
        await this.props.createRegion({ payload: data });
        this.props.navigate("/dashboard/regions");
      }
    } catch (err) {
      showToast({ message: err.message, type: "error" });
    }
  };
  render() {
    const isUpdating = Boolean(this.props.region);
    return (
      <div className="content-wrapper">
        {!isUpdating && (
          <div>
            <div>New Region</div>
          </div>
        )}
        <Box>
          <CreateUpdateRegionForm
            newRegion={this.props.newRegion}
            onSubmit={this.handleRegionSubmit}
            region={this.props.region}
          />
        </Box>
      </div>
    );
  }
}

const RouterWrapperRegionForm = (props) => {
  const navigate = useNavigate();
  return <RegionForm navigate={navigate} {...props} />;
};

const mapDispatchToProps = {
  updateRegion,
  createRegion,
  requestRegions,
};

export default connect(null, mapDispatchToProps)(RouterWrapperRegionForm);
