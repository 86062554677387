import { Badge } from "react-bootstrap";
import React from "react";

const BoxTitle = ({ children, count }) => (
  <h3 className={"d-flex justify-content-center"}>
    <span className={"pr-2"}>{children}</span>{" "}
    <Badge bg={"secondary ms-2"}>{count}</Badge>
  </h3>
);

export default BoxTitle;
