import { Bar } from "react-chartjs-2";
import React from "react";
import "chart.js/auto";
const Graph = (props) => {
  const { allStatuses, statuses } = props;

  const countData = allStatuses.map(({ label }, idx) => {
    const countObj = { label };
    countObj.count = 0;

    if (idx % 2 === 0) {
      countObj.color = "#ddd";
    } else {
      countObj.color = process.env.REACT_APP_MP_PRIMARY_COLOR;
    }

    return countObj;
  });

  statuses.forEach(({ count, label }) => {
    const countObj = countData.filter((obj) => obj.label === label)[0];

    if (countObj) {
      countObj.count += count;
    }
  });

  return (
    <Bar
      data={{
        labels: countData.map(({ label, count }) => [label, `(${count})`]),
        datasets: [
          {
            data: countData.map(({ count }) => count),
            backgroundColor: countData.map(({ color }) => color),
            hoverBackgroundColor: [
              "#ddd",
              process.env.REACT_APP_MP_PRIMARY_COLOR,
            ],
          },
        ],
      }}
      options={{
        legend: { display: false },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              // beginAtZero: true,
              callback: function (value) {
                if (value % 1 === 0) {
                  return value;
                }
              },
            },
          },
        },
      }}
    />
  );
};
export default Graph;
