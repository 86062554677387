import { connect } from "react-redux";
import { change, formValueSelector } from "redux-form";
import { createRoute } from "actions/routes.actions";
import createForm from "forms/form-class";

import CreateRouteForm from "./create-route.component";

// Connect Redux Form
const RouteFormConnected = createForm(
  CreateRouteForm,
  "CreateRoute",
  null,
  null,
  true,
  [],
  "Save",
  true
);

const mapDispatchToProps = (dispatch, ownProps) => {
  const handleSelectStart = (address) => {
    dispatch(
      change(
        "CreateRoute",
        "start_coordinates",
        JSON.stringify(address.coordinates)
      )
    );
    dispatch(change("CreateRoute", "start_location", address.mapAddress));
  };

  const handleSelectEnd = (address) => {
    dispatch(
      change(
        "CreateRoute",
        "end_coordinates",
        JSON.stringify(address.coordinates)
      )
    );
    dispatch(change("CreateRoute", "end_location", address.mapAddress));
  };

  return {
    onSubmit: async (data) => {
      if (data.combine_pickups) {
        data.pickup_types = {
          [`id${data.selected_combine}`]:
            data.pickup_types[`id${data.selected_combine}`],
        };
      }
      delete data.selected_combine;

      if (data.pickupTypeId) {
        data.pickup_types = {
          [`id${data.pickupTypeId}`]: { stop_time: 1, vehicle_count: 1 },
        };
        delete data.pickupTypeId;
      }

      if (data.separate_furniture) {
        delete data.vehicle_count;
        delete data.stop_time;
      } else {
        delete data.furniture_vehicle_count;
        delete data.standard_vehicle_count;
        delete data.furniture_stop_time;
        delete data.standard_stop_time;
      }
      const res = await dispatch(createRoute(data));
      ownProps.onCreate(res);
      return res;
    },
    handleSelectStart,
    handleSelectEnd,
  };
};

const formSelector = formValueSelector("CreateRoute");
const mapStateToProps = (state, ownProps) => {
  const pickupTypes = state.pickupRoutes.unnassignedPickups.map(
    (pickup) => pickup.pickupType
  );
  const pickupTypesIdSet = new Set(
    pickupTypes.map((pickupType) => pickupType.id)
  );
  const pickupTypesUnique = [...pickupTypesIdSet].map((id) =>
    pickupTypes.find((pickupType) => pickupType.id === id)
  );
  // Pulls in defaults from the region to the form
  const {
    routeEndLocation,
    routeStartLocation,
    routeStartCoordinates,
    routeEndCoordinates,
    routeStartTime,
    routeEndTime,
    pickupTypeDefaults,
  } = ownProps.region.region;

  return {
    initialValues: {
      position: ownProps.routesCount || undefined,
      date: ownProps.date,
      region_id: Number(ownProps.region.region.id),
      start_location: routeStartLocation,
      end_location: routeEndLocation,
      start_coordinates: routeStartCoordinates,
      end_coordinates: routeEndCoordinates,
      start_time: routeStartTime,
      end_time: routeEndTime,
      routing: ownProps.newEmptyRoute ? false : true,
      pickup_types: Object.fromEntries(
        pickupTypeDefaults.reduce((acc, pickupTypeDefault) => {
          if (
            pickupTypesUnique.find(
              (pickupType) => pickupType.id === pickupTypeDefault.pickupTypeId
            )
          ) {
            acc.push([
              `id${pickupTypeDefault.pickupTypeId}`,
              {
                stop_time: pickupTypeDefault.stopTime,
                vehicle_count: pickupTypeDefault.vehicleCount,
              },
            ]);
          }
          return acc;
        }, [])
      ),
      selected_combine: pickupTypesUnique[0]?.id.toString(),
    },
    separateFurniture: formSelector(state, "separate_furniture"),
    pickupTypesForRoute: pickupTypesUnique,
    pickupTypes: state.common.data.pickupTypes,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RouteFormConnected);
