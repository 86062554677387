import React, { useContext, useEffect, useState } from "react";
import { Button, Badge } from "react-bootstrap";

import { Table, RowActions, checkComponentOriginProp } from "components/table";
import Modal from "components/modal";
import Tabs from "components/tabs";
import ConfirmModal from "components/confirm-modal";
import PageLayout from "layouts/pages/page-default";
import { LeadSourcesContext, LeadSourcesProvider } from "contexts/lead-sources";
import CreateUpdateLeadSource from "forms/create-update-lead-source";
import { numberWithCommas } from "utils/helpers";
import Loader from "components/loader";

const LeadSourcesTable = ({
  leadSource: leadSourcesCommon,
  requestCommon,
  leadSourceLoading: leadSourceCommonLoading,
}) => {
  const {
    leadSources,
    leadSourcesLoaded,
    getLeadSources,
    createLeadSource,
    updateLeadSource,
    leadSourcesMap,
  } = useContext(LeadSourcesContext);

  const [showEnabled, setShowEnabled] = useState(true);

  useEffect(() => {
    getLeadSources({ includePickupCount: true, disabled: !showEnabled });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEnabled]);

  const [create, setCreate] = useState(false);
  const [edit, setEdit] = useState(false);
  const [archive, setArchive] = useState(false);
  const [tableData, setTableData] = useState([]);

  const hideModal = () => {
    setCreate(false);
    setEdit(false);
  };

  useEffect(() => {
    const selectedArray = leadSourcesLoaded
      ? leadSources
      : leadSourcesCommon || [];
    const filtered = selectedArray.reduce((acc, source) => {
      if (source.disabled !== showEnabled) {
        acc.push({
          ...source,
          pickupCount: leadSourcesMap[source.id]?.pickupCount,
        });
      }
      return acc;
    }, []);
    setTableData(filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadSourcesCommon, leadSourcesMap, showEnabled]);

  return (
    <PageLayout
      title="Lead Sources"
      actionComponents={[
        () => <Button onClick={() => setCreate(true)}>Add New</Button>,
      ]}
    >
      <Tabs active={showEnabled} onChange={setShowEnabled}>
        <Tabs.Tab value={true}>Enabled</Tabs.Tab>
        <Tabs.Tab value={false}>Disabled</Tabs.Tab>
      </Tabs>
      <Table
        columns={[
          {
            Header: "Name",
            accessor: "label",
          },
          {
            Header: "Total Donations",
            accessor: "pickupCount",
            Cell: (props) =>
              checkComponentOriginProp(props, (original) => {
                if (
                  typeof original.pickupCount !== "number" &&
                  !original.pickupCount
                ) {
                  return <Loader width={"1.75rem"} color={"#000"} />;
                }

                return (
                  <Badge bg={"secondary"}>
                    {numberWithCommas(original.pickupCount)}
                  </Badge>
                );
              }),
          },
        ]}
        actions={[
          {
            Header: "Actions",
            Component: (props) =>
              checkComponentOriginProp(props, (original) => (
                <RowActions
                  id={original.id}
                  actions={[
                    {
                      text: "Edit",
                      icon: "edit",
                      onClick: () =>
                        setEdit({ id: original.id, label: original.label }),
                    },
                    {
                      text: original.disabled ? "Unarchive" : "Archive",
                      icon: "archive",
                      onClick: () => setArchive(original),
                    },
                  ]}
                />
              )),
          },
        ]}
        data={tableData}
        pageSize={tableData.length}
        loading={leadSourceCommonLoading}
        hidePagination
      />
      <Modal
        show={!!(create || edit)}
        onHide={hideModal}
        title={`${create ? "Create" : "Edit"} Lead Source`}
      >
        <CreateUpdateLeadSource
          initialValues={edit || {}}
          onSubmit={
            create
              ? async (source) => {
                  await createLeadSource(source);
                  await requestCommon();
                  await getLeadSources({
                    includePickupCount: true,
                    disabled: !showEnabled,
                  });
                }
              : updateLeadSource
          }
          onSubmitSuccess={hideModal}
          onCancel={hideModal}
        />
      </Modal>
      <ConfirmModal
        title="Archive Lead Source"
        confirmText="Archive"
        confirmSubText="Are you sure you'd like to archive this lead source?"
        onConfirm={() =>
          updateLeadSource({ ...archive, disabled: !archive.disabled })
        }
        close={() => setArchive(false)}
        show={archive}
        hideButton
      />
    </PageLayout>
  );
};

const LeadSources = (props) => {
  return (
    <LeadSourcesProvider>
      <LeadSourcesTable {...props} />
    </LeadSourcesProvider>
  );
};

export default LeadSources;
