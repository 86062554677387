import "react-vertical-timeline-component/style.min.css";
import { formatLongDateParenWeekday } from "utils/dates";
import Box from "components/box";
import Modal from "components/modal";
import Loader from "components/loader";
import { Badge, Button, ButtonGroup } from "react-bootstrap";
import Icon from "components/icon";
import React from "react";
import ComponentsTimeline from "./components-customer-timeline-new";

const TitleComponent = ({ userName, entityType, actionType }) => {
  const actionTypeFormatted =
    actionType === "destroy" ? "destroyed" : `${actionType}d`;
  return (
    <>
      <strong>{userName}</strong>&nbsp;
      {actionTypeFormatted} a {entityType.slice(0, -1)}
      &nbsp;
    </>
  );
};

const TimelineDay = ({
  date,
  actions,
  handleSeeChangesClick,
  collapsed,
  setCollapsed,
}) => (
  <div className={"user-actions-timeline"}>
    <div
      className={"user-actions-timeline-date-row"}
      onClick={() => setCollapsed(!collapsed)}
    >
      <div className={"user-actions-timeline-date-icon"}>
        <Icon type={"calendar"} />
      </div>
      <div
        className={"user-actions-timeline-date"}
        style={{ cursor: "pointer" }}
      >
        {date}
        {collapsed && (
          <Badge variant="primary" style={{ marginLeft: ".5rem" }}>
            {actions.length}
          </Badge>
        )}
      </div>
    </div>

    {collapsed ||
      actions.map((action, i) => {
        const user = action.user;
        const userName = `${user.fname} ${user.lname}`;

        return (
          <div
            className={"py-2"}
            onClick={handleSeeChangesClick.bind(null, action)}
            key={i}
          >
            <TitleComponent
              userName={userName}
              actionType={action.action}
              entityType={action.entityType}
            />
            <Button
              size={"sm"}
              variant={"link"}
              onClick={handleSeeChangesClick.bind(null, action)}
            >
              View Changes
            </Button>
          </div>
        );
      })}
  </div>
);

class DonorTimeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changesModalData: null,
      showChangesModal: false,
      collapsed: {},
    };
  }

  _handleSeeChangesClick = async (action) => {
    const { data } = await this.props.requestCustomerAction(
      action.userId,
      action.id
    );
    this.setState({
      changesModalData: data,
      showChangesModal: true,
    });
  };

  _handleCalendarArrowClick = (currentAction, actionsAll, direction) => {
    const index = actionsAll.findIndex((item) => item.id === currentAction.id);
    const changer = direction === "right" ? 1 : -1;
    this._handleSeeChangesClick(actionsAll[index + changer]);
  };

  _checkIfCalendarBtnDisabled = (currentAction, actionsAll, direction) => {
    if (currentAction) {
      const index =
        actionsAll.findIndex((item) => item.id === currentAction.id) + 1;
      const length = actionsAll.length;
      if (direction === "right") {
        if (index < length) {
          return false;
        }
        return true;
      }
      if (direction === "left") {
        if (index > 1) {
          return false;
        }
        return true;
      }
    }
    return true;
  };

  _closeModal = () => {
    this.setState({
      showChangesModal: false,
    });
  };

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate(prevProps) {
    const {
      clearCustomerActions,
      requestCustomerActions,
      requestPickupsActions,
      donorId,
      pikupId,
      isFetching,
      pagination,
    } = this.props;

    if (!isFetching && donorId && donorId !== prevProps?.donorId) {
      const { page, limit } = pagination;

      clearCustomerActions();
      requestCustomerActions(donorId, limit, page);
    }
    if (!isFetching && pikupId && pikupId !== prevProps?.pikupId) {
      requestPickupsActions(pikupId);
    }
  }

  componentWillUnmount() {
    if (this.props.pickupMode) {
      this.props.clearPickupActions();
    } else {
      this.props.clearCustomerActions();
    }
  }

  render() {
    const {
      timelineActions,
      pickupActions,
      pickupStatuses,
      pickupStatusesMap,
      common,
      locations,
      regions,
      isFetching,
      isLoaded,
      isFetchingOpenedAction,
      pagination,
      donorId,
      pickupMode,
    } = this.props;
    const { actions } = timelineActions;
    const { page, totalPages, limit } = pagination;

    if (!isLoaded) {
      return (
        <Box>
          <Loader />
        </Box>
      );
    }

    // Need to group changes by date so we can create a Timeline.
    // Will group in an object like { '<date>': [action, action] }
    // Which we can iterate through with Object.keys() later
    const actionsByDate = {};
    actions.forEach((action, i) => {
      const actionDate = formatLongDateParenWeekday(action.createdAt);

      if (actionsByDate[actionDate]) {
        actionsByDate[actionDate].push(action);
      } else {
        actionsByDate[actionDate] = [action];
      }
    });

    const changesModalData = this.state.changesModalData;

    const defaultCalendarFnProps = [changesModalData, actions];

    return (
      <React.Fragment>
        <Box>
          <>
            <ButtonGroup>
              <Button
                variant={"link"}
                onClick={() =>
                  this.setState({
                    collapsed: Object.fromEntries(
                      Object.keys(actionsByDate).map((date) => [date, true])
                    ),
                  })
                }
              >
                Collapse All
              </Button>
              <Button
                variant={"link"}
                onClick={() =>
                  this.setState({
                    collapsed: Object.fromEntries(
                      Object.keys(actionsByDate).map((date) => [date, false])
                    ),
                  })
                }
              >
                Expand All
              </Button>
            </ButtonGroup>
            {Object.keys(actionsByDate).map((date, idx) => {
              const actions = actionsByDate[date];
              return (
                <TimelineDay
                  key={idx}
                  date={date}
                  actions={actions}
                  handleSeeChangesClick={this._handleSeeChangesClick}
                  collapsed={!!this.state.collapsed[date]}
                  setCollapsed={() =>
                    this.setState({
                      collapsed: {
                        ...this.state.collapsed,
                        [date]: !this.state.collapsed[date],
                      },
                    })
                  }
                />
              );
            })}
            {!pickupMode && (
              <Button
                disabled={totalPages <= page || isFetching}
                onClick={() => {
                  this.props.requestCustomerActions(donorId, limit, page + 1);
                }}
              >
                {isFetching ? "Loading..." : "Show More"}
              </Button>
            )}
          </>
        </Box>
        {this.state.showChangesModal ? (
          <Modal
            show={this.state.showChangesModal}
            onHide={this._closeModal}
            title={this.state.changesModalData.action}
          >
            <div className="user-action-modal">
              <div
                className="loader-container"
                style={{
                  display: isFetchingOpenedAction ? "block" : "none",
                }}
              >
                <Loader />
              </div>
              <div style={{ opacity: isFetchingOpenedAction ? 0 : 1 }}>
                <ComponentsTimeline
                  entityType={changesModalData.entityType}
                  createdAt={changesModalData.createdAt}
                  before={changesModalData.beforeAction}
                  after={changesModalData.afterAction}
                  actionInitiator={changesModalData.user}
                  action={changesModalData.action}
                  additionalData={{
                    common,
                    locations,
                    regions,
                    pickupStatuses,
                    pickupStatusesMap,
                    TitleComponent,
                  }}
                  calendarHandlers={{
                    onClickRight: () => {
                      this._handleCalendarArrowClick(
                        ...defaultCalendarFnProps,
                        "right"
                      );
                    },
                    onClickLeft: () => {
                      this._handleCalendarArrowClick(
                        ...defaultCalendarFnProps,
                        "left"
                      );
                    },
                    disabledRight: this._checkIfCalendarBtnDisabled(
                      ...defaultCalendarFnProps,
                      "right"
                    ),
                    disabledLeft: this._checkIfCalendarBtnDisabled(
                      ...defaultCalendarFnProps,
                      "left"
                    ),
                  }}
                />
              </div>
            </div>
          </Modal>
        ) : null}
      </React.Fragment>
    );
  }
}

export default DonorTimeline;
