import { useCallback, useEffect, useRef, useState } from "react";

import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, ScaleControl } from "react-leaflet";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-draw";
import protobuf from "protobufjs";

import Loader from "components/loader";
import config from "config";
import { showToast } from "actions/toast.actions";

import LogicControl from "./LogicControl.component";
import EditControl from "./EditControl";
import ZonesDisplay from "./ZonesDisplay.container";
import ChunksLayer from "./ChunksLayer.component";
import ZoneLayer from "./ZoneLayer.component";
import SearchControl from "./SearchControl.component";
import { computeChunkGridCoords } from "./chunkGridUtils";
import newZoneTemplate from "./newZoneTemplate";
import "./style.scss";

window.L = L;

const ZipMap = ({
  region,
  zones,
  zonesLoaded,
  zonesFetching,
  stopsSetting,
  requestZones,
  createZone,
  updateZone,
  deleteZone,
}) => {
  const { current: loadingAttemptedChunks } = useRef(new Set()); // A ref because changing it doesn't affect the UI
  const [loadedChunks, setLoadedChunks] = useState([]);
  const [refMap, setRefMap] = useState(null);
  const [geoJSON, setGeoJSON] = useState(null);
  const loadChunk = (position) => {
    const [x, y] = computeChunkGridCoords(position);
    const filename = `${x}x${y}.json`;

    if (loadingAttemptedChunks.has(filename)) return;
    loadingAttemptedChunks.add(filename);

    fetch(`${config.REACT_APP_MP_ZIP_CODE_GEO_JSON_FOLDER_URL}/${filename}`)
      .then((res) => res.json())
      .then((data) => {
        setLoadedChunks((loadedChunks) => [
          ...loadedChunks,
          { filename, coords: [x, y], data },
        ]);
      });
  };

  // let startCoords = { lat: "29.7604", lng: "-95.3698" };
  let startCoords = { lat: "0", lng: "0" };
  const { routeStartCoordinates } = region;
  if (routeStartCoordinates) {
    startCoords = routeStartCoordinates;
  }
  const [viewport, setViewport] = useState({
    center: [startCoords.lat, startCoords.lng],
    zoom: 12,
  });

  const [zipList, setZipList] = useState([]);
  useEffect(() => {
    // Get the zones from the API
    requestZones({ regionId: region.id });

    // Get first zip code chunk
    loadChunk(viewport.center);

    fetch(config.REACT_APP_MP_ZIP_CODE_LIST_BUF)
      .then((res) => res.arrayBuffer())
      .then((data) =>
        Promise.all([
          new Uint8Array(data),
          fetch(config.REACT_APP_MP_ZIP_CODE_LIST_PROTO),
        ])
      )
      .then(([data, res]) => Promise.all([data, res.text()]))
      .then(([data, proto]) => {
        const root = new protobuf.Root();
        protobuf.parse(proto, root);
        const ZipCodeList = root.lookupType("ZipCodeList");
        setZipList(ZipCodeList.toObject(ZipCodeList.decode(data)).zipCodes);
      });
    setGeoJSON(L.geoJSON());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showZips, setShowZips] = useState(true);
  const updateShowZips = (zoom) => {
    setShowZips(
      (zoom || refMap?.getZoom?.()) <
        Number(config.REACT_APP_MP_ZIP_CODE_SHOW_MAX_ZOOM) &&
        (zoom || refMap?.getZoom?.()) >
          Number(config.REACT_APP_MP_ZIP_CODE_SHOW_MIN_ZOOM)
    );
  };

  const loadChunksInView = (viewport) => {
    if (refMap || viewport) {
      const mapBounds = viewport || refMap?.getBounds();
      for (
        let lon = mapBounds.getSouth();
        lon <
        mapBounds.getNorth() +
          Number(config.REACT_APP_MP_ZIP_CODE_GEO_JSON_GRID_SIZE);
        lon += Number(config.REACT_APP_MP_ZIP_CODE_GEO_JSON_GRID_SIZE)
      )
        for (
          let lat = mapBounds.getWest();
          lat <
          mapBounds.getEast() +
            Number(config.REACT_APP_MP_ZIP_CODE_GEO_JSON_GRID_SIZE);
          lat += Number(config.REACT_APP_MP_ZIP_CODE_GEO_JSON_GRID_SIZE)
        ) {
          loadChunk([lon, lat]);
        }
    }
  };

  const [activeZoneId, setActiveZoneId] = useState(null);

  const [scratchZones, setScratchZonesUnwrapped] = useState([
    ...zones,
    newZoneTemplate(stopsSetting),
  ]);
  const [modifiedZones, setModifiedZones] = useState(new Set());
  // This automatically sets modified zones to modified when they are modified
  // and removes them from modifiedZones when they are restored
  const setScratchZones = useCallback(
    (scratchZones) => {
      for (const scratchZone of scratchZones) {
        if (scratchZone.id === -1) {
          let isModified = false;
          const template = newZoneTemplate(stopsSetting);
          for (const key in template) {
            if (
              template.hasOwnProperty(key) &&
              key !== "color" &&
              key !== "zipCodes" &&
              scratchZone[key] !== template[key]
            ) {
              isModified = true;
              break;
            }
          }
          if (scratchZone.zipCodes.length !== 0) {
            isModified = true;
          }

          if (isModified) setModifiedZones(modifiedZones.add(-1));
          else setModifiedZones((modifiedZones.delete(-1), modifiedZones));

          continue;
        }
        const zone = zones.find(({ id }) => id === scratchZone.id);
        if (scratchZone !== zone) setModifiedZones(modifiedZones.add(zone.id));
        // Deep equals using JSON.stringify
        if (JSON.stringify(scratchZone) === JSON.stringify(zone))
          setModifiedZones((modifiedZones.delete(zone.id), modifiedZones));
      }

      setScratchZonesUnwrapped(scratchZones);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [zones, modifiedZones]
  );

  useEffect(() => {
    const scratchZoneIds = new Set(scratchZones.map(({ id }) => id));
    const zoneIds = new Set(zones.map(({ id }) => id));
    const newZones = zones.filter(({ id }) => !scratchZoneIds.has(id));
    setScratchZones([
      ...scratchZones.filter(({ id }) => zoneIds.has(id) || id === -1),
      ...newZones,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zones]);

  const [highlightedZip, setHighlightedZip] = useState(null);

  const validZipCode = (zip) => {
    return !!zipList.find(({ zipCode }) => zipCode === parseInt(zip));
  };

  const goToZip = (zip) => {
    const { lat, lng } = zipList.find(
      ({ zipCode }) => zipCode === parseInt(zip)
    );

    refMap.panTo([lat, lng]);
  };

  const onViewportChange = (viewport, zoom) => {
    setViewport(viewport);
    updateShowZips(zoom);
    loadChunksInView(viewport);
  };

  // Handle clicking on a unselected zip code
  const onUnselectedClick = useCallback(
    (e) => {
      const zip = e.layer.feature.properties.ZCTA5CE10;
      const activeZone = scratchZones.find(({ id }) => id === activeZoneId);

      if (activeZone)
        setScratchZones([
          ...scratchZones.filter(({ id }) => id !== activeZoneId),
          { ...activeZone, zipCodes: [...activeZone.zipCodes, zip] },
        ]);
    },
    [scratchZones, setScratchZones, activeZoneId]
  );

  // Handle clicking on a selected zip code
  const onSelectedClick = useCallback(
    (e) => {
      if (!activeZoneId) {
        showToast({ message: "You must select a zone first", type: "info" });
        return;
      }
      const zip = e.layer.feature.properties.ZCTA5CE10;
      const activeZone = scratchZones.find(({ id }) => id === activeZoneId);

      if (activeZone) {
        let zipCodes = [];
        if (activeZone.zipCodes.includes(zip)) {
          zipCodes = activeZone.zipCodes.filter((zoneZip) => zoneZip !== zip);
        } else {
          zipCodes = [...activeZone.zipCodes, zip];
        }
        setScratchZones([
          ...scratchZones.filter(({ id }) => id !== activeZoneId),
          {
            ...activeZone,
            zipCodes: [...zipCodes],
          },
        ]);
      }
    },
    [scratchZones, setScratchZones, activeZoneId]
  );

  // Set leaflet-draw options
  const drawOptions = {
    polyline: false,
    polygon: false,
    circle: false,
    marker: false,
    circlemarker: false,
  };
  L.drawLocal.draw.handlers.rectangle.tooltip.start =
    "Click and drag to select zip codes.";
  L.drawLocal.draw.handlers.simpleshape.tooltip.end = "Release to select";
  L.drawLocal.draw.toolbar.actions.title = "Cancel selection";
  L.drawLocal.draw.toolbar.buttons.rectangle = "Select zip codes";

  const editOptions = {
    edit: false,
    remove: false,
    toolbar: {
      cancel: {
        title: "Cancel selection",
      },
    },
  };

  if (zipList.length === 0 || loadedChunks.length === 0)
    return (
      <div id="zipmap-wrapper">
        <div id="zipmap-loader">
          <Loader />
        </div>
      </div>
    );

  return (
    <div id="zipmap-wrapper">
      <ZonesDisplay
        zones={zones}
        activeZoneId={activeZoneId}
        setActiveZoneId={setActiveZoneId}
        scratchZones={scratchZones}
        setScratchZones={setScratchZones}
        modifiedZones={modifiedZones}
        setHighlightedZip={setHighlightedZip}
        validZipCode={validZipCode}
        goToZip={goToZip}
      />
      <MapContainer
        maxZoom="18"
        minZoom="8"
        zoomControl={true}
        zoomAnimationThreshold="5"
        id="map"
        attributionControl={false}
        center={{ lat: "29.7604", lng: "-95.3698" }}
        zoom={12}
      >
        <TileLayer
          zIndex={0}
          url="https://api.maptiler.com/maps/voyager/{z}/{x}/{y}.png?key=oc0kqgi4KpEJGWbrQpc5"
          zoomOffset={-1}
          tileSize={512}
        />
        {showZips && (
          <ChunksLayer chunks={loadedChunks} onClick={onUnselectedClick} />
        )}
        {scratchZones.map((zone) => (
          <ZoneLayer
            color={zone.color}
            key={zone.id}
            chunks={loadedChunks}
            zone={zone}
            highlightedZip={highlightedZip}
            onClick={onSelectedClick}
            isActiveZone={zone.id === activeZoneId}
          />
        ))}
        <EditControl
          scratchZones={scratchZones}
          loadedChunks={loadedChunks}
          activeZoneId={activeZoneId}
          refMap={refMap}
          setScratchZones={setScratchZones}
          drawOptions={drawOptions}
          editOptions={editOptions}
        />
        <ScaleControl metric={false} imperial={true} />
        {refMap && zipList.length && geoJSON && (
          <SearchControl geoJSON={geoJSON} zipList={zipList} />
        )}
        <LogicControl
          setRefMap={setRefMap}
          onMapViewportChange={onViewportChange}
          viewport={viewport}
        />
      </MapContainer>
    </div>
  );
};

export default ZipMap;
