import React, { useEffect } from "react";
import querystring from "query-string";
import DonorForm from "./donor-search-form.component";
import DonorSearchTabs from "./donor-search-tabs.component";
import { Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import PageLayout from "layouts/pages/page-default";

const DonorSearch = (props) => {
  const { removeSearchTab, searchCustomers, searchCustomerResults } = props;
  const location = useLocation();

  const loadTabsFromQuery = () => {
    const {
      searchQuery: { type },
    } = searchCustomerResults;

    const tabQueries = searchCustomerResults.searchTabs.map((tab) => tab.query);
    const query = !location.search
      ? []
      : querystring
          .parse(location.search, {
            arrayFormat: "bracket",
          })
          .query.map((tab) => ({
            query: tab,
            ...JSON.parse(tab),
          }));
    const queryTexts = query.map(({ query }) => query);

    // Remove tabs that are not in the query parameter but are in state
    for (const tabQuery of tabQueries.filter(
      (tabQuery) => !queryTexts.includes(tabQuery)
    )) {
      removeSearchTab({
        tab: searchCustomerResults.searchTabs.find(
          ({ query }) => query === tabQuery
        ),
      });
    }

    // Get the tabs we don't already have in state so we don't recreate existing tabs
    const newQuery = query.filter(
      (search) => !tabQueries.includes(search.query)
    );

    for (const search of newQuery) {
      if (search.address) {
        searchCustomers(search, type, "donor");
      } else {
        searchCustomers(
          { ...search, name: normalizeQuery(search.name) },
          type,
          "donor"
        );
      }
    }
  };

  useEffect(() => {
    loadTabsFromQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const normalizeQuery = (query) => {
    // matches any type of phone number input
    const phoneNumberRegex = RegExp(
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
    );
    const isPhone = phoneNumberRegex.test(query);

    // if the query is a phone number, then remove all special characters from the input
    return isPhone ? query.replace(/\D/g, "") : query;
  };

  const AddNewButton = (props) => (
    <Link to={"/dashboard/donors/new"}>
      <Button>Add New</Button>
    </Link>
  );

  return (
    <PageLayout title={"Donor Search"} actionComponents={[AddNewButton]}>
      <div className="bg-white p-4">
        <DonorForm {...props} normalizeQuery={normalizeQuery} />
        <DonorSearchTabs {...props} />
      </div>
    </PageLayout>
  );
};

export default DonorSearch;
