import React, { Component } from "react";
import { Outlet } from "react-router";
import Footer from "./footer";
import Header from "./header";
import SidebarNavigation from "./sidebar";
import { REACT_APP_MP_LOGO_URL } from "config";
import Offcanvas from "react-bootstrap/Offcanvas";

class DashboardComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileNavOpen: false,
    };
  }

  toggleNav = (value) => {
    if (!value) {
      this.setState({ mobileNavOpen: !this.state.mobileNavOpen });
    } else {
      this.setState({ mobileNavOpen: value });
    }
  };

  render() {
    return (
      <div id="dashboard">
        <div
          className={
            "col-lg-3 col-xxl-2 bg-white min-vh-100 p-0 sidebar d-lg-block "
          }
        >
          <Offcanvas
            show={this.state.mobileNavOpen}
            onHide={() => this.toggleNav(false)}
            responsive="lg"
            className={"d-lg-block"}
          >
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <div className={"sidebar-branding"}>
              <img src={REACT_APP_MP_LOGO_URL} alt="logo" />
            </div>
            <SidebarNavigation toggleNav={this.toggleNav} />
          </Offcanvas>
        </div>
        <div
          className={
            "col-lg-9 col-xxl-10 p-0 ms-lg-auto  min-vh-100 d-flex flex-column"
          }
        >
          <Header toggleNav={this.toggleNav} />
          <div className={"container-fluid p-4 col"}>
            <Outlet />
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default DashboardComponent;
