import React from "react";
import { Link } from "react-router-dom";
import { Field } from "redux-form";
import FormField from "forms/FormField";
import { email, required } from "utils/validators";

const LoginForm = (props) => (
  <React.Fragment>
    <Field
      name="email"
      type="text"
      component={FormField}
      label="Email"
      placeholder=""
      validate={[email, required]}
    />
    <Field
      name="password"
      type="password"
      component={FormField}
      label="Password"
      placeholder=""
      validate={[required]}
    />
    <div style={{ textAlign: "right" }}>
      <Link to="/forgotpassword">Forgot your password?</Link>
    </div>
  </React.Fragment>
);
// export default reduxForm({ form: "Login" })(LoginForm);
export default LoginForm;
