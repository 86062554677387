import { connect } from "react-redux";
// import { getFormValues } from 'redux-form';
import {
  requestRoutes,
  receiveRoutes,
  requestRoutePickups,
  receivePickupRoutes,
  updateRoutePickup,
  requestUsers,
  updateRoute,
  updateRouteCompleted,
  updateRouteDriver,
  // updatePickupRoute,
  uploadCSV,
  requestRoutePickupBlocks,
  deleteRoute,
  updatePickupOrder,
  updateRouteFurniture,
} from "actions/routes.actions";
import { getRegion } from "actions/regions.actions";

import { requestLocations } from "actions/locations.actions";

import SingleRoute from "./route-assign-driver.component";

const mapStateToProps = (state) => {
  return {
    auth: {
      ...state.auth,
    },

    pickupRoutes: {
      ...state.pickupRoutes,
    },
    pickupStatuses: [...state.common.data.pickupStatuses],

    users: {
      ...state.users,
    },
  };
};

const mapDispatchToProps = {
  requestRoutes,

  requestRoutePickups,

  receivePickupRoutes,

  updateRoutePickup,

  requestUsers,

  requestLocations,

  updateRoute,

  updateRouteCompleted,

  updateRouteDriver,

  updateRouteFurniture,

  uploadCSV,

  requestRoutePickupBlocks,

  deleteRoute,

  updatePickupOrder,

  receiveRoutes,

  getRegion,

  // updatePickupRoute
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleRoute);
