import React, { useContext, useEffect } from "react";
import { Field } from "redux-form";
import { Row, Col, Button } from "react-bootstrap";
import TimePicker from "forms/time-picker";
import { FormGroup } from "react-bootstrap";
import DriverPriorityField from "./driver-priority-field";
import PlacesAutocompleteField from "forms/PlacesAutocompleteField";
import FormField from "forms/FormField";
import { required, requiredTime } from "utils/validators";
import { PickupTypesContext } from "contexts/pickupTypes";

const RegionForm = (props) => {
  const {
    pristine,
    submitting,
    handleSubmit,
    handleSelectStart,
    handleSelectEnd,
    newRegion = false,
    initialValues,
    initialize,
    pickupTypes,
  } = props;
  const isEdit = !!initialValues?.id;

  useEffect(() => {
    if (initialValues) {
      initialize(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col xs={12}>
          <FormGroup>
            <Field
              name={`label`}
              className={"form-control"}
              component={FormField}
              label="Name"
              placeholder={"Name"}
              required={true}
              validate={required}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <FormGroup>
            <Field
              name={`routeStartLocation`}
              component={PlacesAutocompleteField}
              label="Starting Location"
              onPlaceSelected={handleSelectStart}
              placeholder={"Starting Address"}
              required={true}
              validate={required}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <Field
              name={`routeEndLocation`}
              component={PlacesAutocompleteField}
              label="Ending Location"
              onPlaceSelected={handleSelectEnd}
              placeholder={"Ending Address"}
              required={true}
              validate={required}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <FormGroup>
            <Field
              name={`routeStartTime`}
              component={TimePicker}
              step={15} // 15 min increments in dropdown
              // max={8am-8pm} TODO: Add Range of times that are actually used
              label="Start Time"
              date={false}
              calendar={false}
              placeholder={"Start Time"}
              required={true}
              validate={requiredTime}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup>
            <Field
              name={`routeEndTime`}
              component={TimePicker}
              step={15} // 15 min increments in dropdown
              // max={8am-8pm} TODO: Add Range of times that are actually used
              label="End Time"
              date={false}
              calendar={false}
              placeholder={"Start Time"}
              required={true}
              validate={requiredTime}
            />
          </FormGroup>
        </Col>
      </Row>

      {pickupTypes.map((pickupType) => (
        <Row key={pickupType.id}>
          <Col sm={12} md={6}>
            <FormGroup>
              <Field
                label={`${pickupType.label}: Stop Time`}
                name={`pickupTypeDefaults.id${pickupType.id}.stopTime`}
                component={FormField}
                className="form-control"
                type="number"
                required={true}
                validate={required}
              />
            </FormGroup>
          </Col>
          <Col sm={12} md={6}>
            <FormGroup>
              <Field
                label={`${pickupType.label}: Number of Trucks`}
                name={`pickupTypeDefaults.id${pickupType.id}.vehicleCount`}
                component={FormField}
                className="form-control"
                type="number"
                required={true}
                validate={required}
              />
            </FormGroup>
          </Col>
        </Row>
      ))}

      <Row>
        <Col xs={12}>
          <FormGroup>
            <Field
              name={`pickupCloseTime`}
              className={"form-control"}
              component={FormField}
              label="Mark as disabled"
              type="number"
              default={false}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <FormGroup>
            <Field
              renderCondition={isEdit}
              name={`disabled`}
              className={"form-control"}
              component={FormField}
              label="Mark as disabled"
              type="checkbox"
              default={false}
            />
          </FormGroup>
        </Col>
      </Row>

      <Field
        name="routeStartCoordinates"
        default={undefined}
        component="input"
        className="form-control"
        type="hidden"
      />
      <Field
        name="routeEndCoordinates"
        default={undefined}
        component="input"
        className="form-control"
        type="hidden"
      />

      {!newRegion && (
        <Field
          name="driverPriority"
          default={undefined}
          component={DriverPriorityField}
        />
      )}

      <div style={{ width: "100%", textAlign: "right" }}>
        <Button
          type="submit"
          disabled={pristine || submitting}
          variant="primary"
        >
          Save
        </Button>
      </div>
    </form>
  );
};

export default RegionForm;
