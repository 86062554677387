import request from "utils/request";
import { REQUEST_USERS, RECEIVE_USERS } from "../actionsConstants";
import {
  REQUEST_ROUTE,
  RECEIVE_ROUTE,
  REQUEST_ROUTES,
  RECEIVE_ROUTES,
  UPDATE_ROUTE,
  UPDATE_ROUTE_COMPLETED,
  DELETE_ROUTE,
  BULK_DELETE_ROUTE,
  BULK_DELETE_ROUTE_COMPLETED,
  DELETE_ROUTE_COMPLETED,
  UPLOAD_ROUTE_CSV,
  UPLOAD_ROUTE_CSV_COMPLETED,
} from "../actionsConstants";
// Pickups
import {
  UPDATE_ROUTES_PICKUPS,
  UPDATE_ROUTES_PICKUPS_COMPLETED,
  UPDATE_PICKUP_DATE,
  REQUEST_ROUTE_PICKUPS,
  RECEIVE_ROUTE_PICKUPS,
  REQUEST_ROUTE_PICKUPS_MINIMAL,
  RECEIVE_ROUTE_PICKUPS_MINIMAL,
  REQUEST_ROUTE_PICKUPS_BY_ROUTEID,
  RECEIVE_ROUTE_PICKUPS_BY_ROUTEID,
  UPDATE_ROUTE_PICKUP,
  UPDATE_ROUTE_DRIVER,
  UPDATE_ROUTE_FURNITURE,
  UPDATE_ROUTE_PICKUP_COMPLETED,
  REQUEST_UPDATE_PICKUP_ORDER,
  REFRESH_ROUTE_PICKUP,
  CHANGE_NOTE_COUNT_PICKUP,
  CHANGE_IMAGE_COUNT_PICKUP,
} from "../actionsConstants";

import {
  REQUEST_ROUTE_PICKUP_BLOCKS,
  RECEIVE_ROUTE_PICKUP_BLOCKS,
} from "../actionsConstants";

import colors from "utils/generateColors";

export function setDateDriver(date, regionId) {
  return (dispatch, state) => {
    dispatch({ type: UPDATE_PICKUP_DATE, pickupDate: date });

    dispatch(
      requestRoutePickups({
        pickupDate: date,
        orderBy: "stopNumber",
        count: "1000",
        regionId,
        searchType: "forDriver",
      })
    );
  };
}

export function setDate(date, regionId, getPickupsAfterRoute) {
  return (dispatch, state) => {
    dispatch({ type: UPDATE_PICKUP_DATE, pickupDate: date });

    dispatch(
      requestRoutes(
        { routeDate: date, regionId: regionId },
        getPickupsAfterRoute
      )
    );
  };
}

//setDriverDate is not necessary
export function setDriverDate(date, regionId) {
  return (dispatch, state) => {
    dispatch({ type: UPDATE_PICKUP_DATE, pickupDate: date });
    request({
      url: "/routes",
      params: { routeDate: date, regionId: regionId, searchType: "forDriver" },
    }).then((res) => {
      dispatch(receiveRoutes(res.data));
    });
  };
}

export function requestRoute(query = {}, routeId) {
  return (dispatch) => {
    dispatch({ type: REQUEST_ROUTE, query });
    request(`/routes/${routeId}`, { params: query }).then((res) =>
      dispatch(reciveRoute(res.data))
    );
  };
}
export function reciveRoute(data) {
  return {
    type: RECEIVE_ROUTE,
    data: data,
    receivedAt: Date.now(),
  };
}
export function requestRoutePickupsByRouteId(
  query = {
    pickupDate: new Date(),
    orderBy: "stopNumber",
    count: "1000",
    regionId: null,
    routeId: null,
    withNotes: true,
  }
) {
  return (dispatch) => {
    query.withNotes = true;
    dispatch({ type: REQUEST_ROUTE_PICKUPS_BY_ROUTEID, query });
    request(`/pickups`, { params: query }).then((res) =>
      dispatch(receivePickupRoutesByRouteId(res.data, query.routeId))
    );
  };
}

export function receivePickupRoutesByRouteId(data, routeId) {
  return {
    type: RECEIVE_ROUTE_PICKUPS_BY_ROUTEID,
    data: data.data,
    routeId,
    pickupStatuses:
      data.meta && data.meta.pickupStatuses.filter((m) => !m.disabled),
    pickupStatusDescriptions: data.meta && data.meta.pickupStatusDescriptions,
    pickupSources: data.meta && data.meta.pickupSources,
    receivedAt: Date.now(),
  };
}

export function requestRoutePickupsMinimal(
  query = {
    pickupDate: new Date(),
    orderBy: "stopNumber",
    count: "1000",
    regionId: null,
    routeId: null,
  }
) {
  return (dispatch) => {
    dispatch({ type: REQUEST_ROUTE_PICKUPS_MINIMAL, query });
    request(`/pickups/route-pickups`, { params: query }).then((res) =>
      dispatch(receiveRoutePickupsMinimal(res.data))
    );
  };
}

export function receiveRoutePickupsMinimal(data) {
  return {
    type: RECEIVE_ROUTE_PICKUPS_MINIMAL,
    data: data.data,
    pickupStatuses:
      data.meta && data.meta.pickupStatuses.filter((m) => !m.disabled),
    pickupStatusDescriptions: data.meta && data.meta.pickupStatusDescriptions,
    pickupSources: data.meta && data.meta.pickupSources,
    receivedAt: Date.now(),
  };
}

export function requestRoutePickups(
  query = {
    pickupDate: new Date(),
    orderBy: "stopNumber",
    count: "1000",
    regionId: null,
    routeId: null,
  }
) {
  return (dispatch) => {
    dispatch({ type: REQUEST_ROUTE_PICKUPS, query });
    request(`/pickups`, { params: query }).then((res) =>
      dispatch(receivePickupRoutes(res.data))
    );
  };
}

export function receivePickupRoutes(data) {
  return {
    type: RECEIVE_ROUTE_PICKUPS,
    data: data.data,
    pickupStatuses:
      data.meta && data.meta.pickupStatuses.filter((m) => !m.disabled),
    pickupStatusDescriptions: data.meta && data.meta.pickupStatusDescriptions,
    pickupSources: data.meta && data.meta.pickupSources,
    receivedAt: Date.now(),
  };
}

export function refreshRoutePickup(pickupId) {
  return (dispatch) => {
    dispatch({ type: REFRESH_ROUTE_PICKUP });
    return request({
      url: `/pickups/${pickupId}`,
      method: "get",
      params: {
        type: "form",
      },
    }).then((res) => dispatch(refreshRoutePickupCompleted(res.data.data[0])));
  };
}

function refreshRoutePickupCompleted(pickup) {
  return {
    type: UPDATE_ROUTE_PICKUP_COMPLETED,
    data: pickup,
    receivedAt: Date.now(),
  };
}

export function updateRoutePickup(data = {}) {
  let pickup;
  if (data.pickupBlockId === "null") {
    pickup = {
      ...data,
      pickupBlockId: null,
    };
  } else {
    pickup = { ...data };
  }

  return (dispatch) => {
    dispatch({ type: UPDATE_ROUTE_PICKUP });
    // dispatch(updateRoutePickupCompleted(pickup));

    return request({
      url: `/pickups/${data.id}`,
      method: "put",
      data: pickup,
    }).then((res) => dispatch(updateRoutePickupCompleted(res.data.data[0])));
  };
}

export function updatePickup(data = {}) {
  let pickup;
  if (data.pickupBlockId === "null") {
    pickup = {
      ...data,
      pickupBlockId: null,
    };
  } else {
    pickup = { ...data };
  }

  return (dispatch) => {
    return request({
      url: `/pickups/${data.id}`,
      method: "put",
      data: pickup,
    }).then((res) => {
      // dispatch(rehydrateRoutes(pickup.pickupDate));
      dispatch(updateRoutePickupCompleted(res.data.data[0]));
      return res.data.data[0];
    });
  };
}
export function updatePickups(data = []) {
  return (dispatch) => {
    dispatch({ type: UPDATE_ROUTES_PICKUPS });
    return request({
      url: `/pickups/updateMany`,
      method: "post",
      data,
    }).then((res) => {
      dispatch(updateRoutePickupsCompleted(res.data.data));
    });
  };
}

function updateRoutePickupCompleted(pickup) {
  return {
    type: UPDATE_ROUTE_PICKUP_COMPLETED,
    data: pickup,
    receivedAt: Date.now(),
  };
}
function updateRoutePickupsCompleted(pickups) {
  return {
    type: UPDATE_ROUTES_PICKUPS_COMPLETED,
    data: pickups,
    receivedAt: Date.now(),
  };
}

// Users

export function requestUsers(query = {}) {
  return (dispatch) => {
    dispatch({ type: REQUEST_USERS, query });
    request(`/v2/users`, { params: query }).then((res) =>
      dispatch(receiveUsers(res.data))
    );
  };
}
export function receiveUsers(data = {}) {
  return {
    type: RECEIVE_USERS,
    data: data.data,
    receivedAt: Date.now(),
  };
}

// Routes

export function requestRoutes(query, getPickupsAfterRoute) {
  return (dispatch) => {
    dispatch({ type: REQUEST_ROUTES, query });
    request({ url: "/routes", params: query }).then((res) => {
      if (getPickupsAfterRoute) {
        dispatch(
          requestRoutePickupsMinimal({
            pickupDate: query.routeDate,
            regionId: query.regionId,
            orderBy: "stopNumber",
            count: "1000",
            withNotes: true,
          })
        );
      }

      dispatch(receiveRoutes(res.data));
    });
  };
}

export function receiveRoutes(data) {
  // Add values to routes, here I'm adding a color to each route
  const pickupRoutes = data.data.map((route, index) => {
    route.color = colors[route.position];
    return route;
  });

  return {
    type: RECEIVE_ROUTES,
    data: pickupRoutes,
    receivedAt: Date.now(),
  };
}

export function startRoute(id) {
  return (dispatch) => {
    return request({
      url: `/routes/${id}/start_route`,
      method: "post",
    }).then((res) => dispatch(updateRouteCompleted(res.data)));
  };
}

export function startPickup(id) {
  return (dispatch) => {
    return request({
      url: `/pickups/${id}/start`,
      method: "post",
    }).then((res) => dispatch(updateRoutePickupCompleted(res.data.data[0])));
  };
}
export function delayPickup(id) {
  return (dispatch) => {
    return request({
      url: `/pickups/${id}/delay`,
      method: "post",
    }).then((res) => dispatch(updateRoutePickupCompleted(res.data.data[0])));
  };
}

export function updateRoute(data = {}) {
  return (dispatch) => {
    // This doesn't work because it causes a re-render, but it hasn't updated the route object
    // The response from the API is also insufficient to rehydrate the route record, so assuming the action was successful
    // and leaving the field without a re-render is the best option for now
    // TODO: Rehydrate this record properly and have it give an indication of success
    // dispatch({type: UPDATE_ROUTE, data: data})
    dispatch({ type: UPDATE_ROUTE, data: data });
    return request({
      url: `/routes/${data.id}`,
      method: "put",
      data: data,
    }).then(async (res) => {
      dispatch(
        updateRouteCompleted({
          data: [{ color: data.color, ...res.data.data[0] }],
        })
      );
    });
  };
}

export function updateRouteCompleted(data) {
  return {
    type: UPDATE_ROUTE_COMPLETED,
    route: data.data[0],
    receivedAt: Date.now(),
  };
}

export function updateRouteDriver(data) {
  return {
    type: UPDATE_ROUTE_DRIVER,
    route: data.route,
    driver: data.driver,
    receivedAt: Date.now(),
  };
}

export function updateRouteFurniture(data) {
  return {
    type: UPDATE_ROUTE_FURNITURE,
    route: data.route,
    receivedAt: Date.now(),
  };
}

export function deleteRoute(data = {}) {
  return (dispatch) => {
    dispatch({ type: DELETE_ROUTE, data: data });

    return request({
      url: `/routes/${data.id}`,
      method: "delete",
    }).then((res) => dispatch(deleteRouteCompleted(res.data)));
  };
}

export function deleteRouteCompleted(c) {
  return {
    type: DELETE_ROUTE_COMPLETED,
    receivedAt: Date.now(),
  };
}

export function bulkDeleteRoutes(data = {}) {
  return (dispatch) => {
    dispatch({ type: BULK_DELETE_ROUTE, data });

    return request({
      url: `/routes`,
      method: "delete",
      params: data,
      noTimeout: true,
    }).then(() => dispatch(bulkDeleteRouteCompleted(data)));
  };
}

export function bulkDeleteRouteCompleted(c) {
  return {
    type: BULK_DELETE_ROUTE_COMPLETED,
    receivedAt: Date.now(),
  };
}

export function uploadCSV({ date, regionId, file }) {
  return (dispatch) => {
    dispatch({ type: UPLOAD_ROUTE_CSV, data: { date, regionId, file } });

    const formData = new FormData();
    formData.append("date", date);
    formData.append("pickups", file);
    formData.append("regionId", regionId);

    return request({
      url: `/pickups/csv`,
      method: "post",
      data: formData,
      noTimeout: true,
    }).then((res) => dispatch(uploadCSVCompleted(res.data)));
  };
}

export function createRoute(data) {
  return async (dispatch) => {
    const res = await request({
      url: `/routes/`,
      method: "post",
      data: data,
      noTimeout: true,
    });
    dispatch(rehydrateRoutes(data.date, data.region_id));
    return res.data.data[0];
  };
}

export function rehydrateRoutes(date, regionId) {
  return (dispatch) => {
    dispatch(
      requestRoutes({
        routeDate: new Date(date),
        regionId: regionId,
      })
    );
    dispatch(
      requestRoutePickups({
        pickupDate: new Date(date),
        orderBy: "stopNumber",
        count: 1000,
        regionId: regionId,
      })
    );
  };
}
export function uploadCSVCompleted(data = {}) {
  return {
    type: UPLOAD_ROUTE_CSV_COMPLETED,
    receivedAt: Date.now(),
  };
}

// Pickup Blocks

export function requestRoutePickupBlocks(data = {}) {
  return (dispatch) => {
    dispatch({ type: REQUEST_ROUTE_PICKUP_BLOCKS, data });

    return request({ url: `/pickupBlocks`, params: data })
      .then((res) => dispatch(receiveRoutePickupBlocks(res.data)))
      .catch((res) => console.error(res));
  };
}

export function receiveRoutePickupBlocks(data = {}) {
  return {
    type: RECEIVE_ROUTE_PICKUP_BLOCKS,
    routePickupBlocks: data.data,
    count: data.count,
    pagination: data.pagination,
    receivedAt: Date.now(),
  };
}

// no redux actions required, will refresh data after this is called.
export function updatePickupOrder(routeId, data = { pickupIds: [] }) {
  return (dispatch) => {
    return request({
      url: `/routes/${routeId}/order`,
      method: "put",
      data: data,
      noTimeout: true,
    }).then(() => {
      dispatch({ type: REQUEST_UPDATE_PICKUP_ORDER, data });
    });
  };
}

export function sendReminderNotifications(region_id, date) {
  return (dispatch) =>
    request({
      url: `/routes/send_donation_reminder`,
      method: "post",
      data: { region_id: region_id, date },
    }).then((res) => dispatch(rehydrateRoutes(date)));
}

export function addNoteCountPickup(pickup_id, count) {
  return (dispatch) =>
    dispatch({
      type: CHANGE_NOTE_COUNT_PICKUP,
      data: { id: pickup_id, count },
    });
}
export function addImageCountPickup(pickup_id, count) {
  return (dispatch) =>
    dispatch({
      type: CHANGE_IMAGE_COUNT_PICKUP,
      data: { id: pickup_id, count },
    });
}
