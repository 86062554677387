import React from "react";
import { Provider } from "react-redux";
import { Routes, Route } from "react-router";
import { Navigate, BrowserRouter } from "react-router-dom";
import { REACT_APP_MP_HTML_TITLE, REACT_APP_MP_FAVICON_URL } from "config";
import { Helmet } from "react-helmet";
import WithDashboardData from "hoc/with-dashboard-data";
import WithAuth from "hoc/with-auth";
import RoleGuardDashboard from "hoc/role-guard-dashboard";
import RoleGuard from "hoc/role-guard";

// Routes
import Dashboard from "layouts/dashboard";
import Login from "pages/login";
import ForgotPassword from "pages/forgot-password";
import DriverRoute from "pages/driver-route";
import OldDriverRoute from "pages/driver-route/old-driver-route";

import "styles/main.scss";
import "styles/fonts/lato.scss";

import GlobalSettings from "hoc/global-settings";
import ToastWrapper from "components/toasts";
import store from "./store";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

// nested routes it dashboard component
import Home from "pages/home";
import Customers from "pages/donors";
import Users from "pages/user-manager";
import ExportData from "pages/export-data-new";
import MarketingReports from "pages/campaigns";
import TimeWindows from "pages/time-windows";
import Analytics from "pages/analytics";
import NotificationTemplates from "pages/notification-templates";
import Regions from "pages/regions";
import SMSPrivacyPolicy from "pages/sms-privacy-policy";
import LeadSources from "pages/lead-sources";
import LocationTypes from "pages/location-types";

// nested routes in donor component
import NewCustomer from "pages/donors/donor-new";
import SingleCustomer from "pages/donors/donor-single";
import DonorSearch from "pages/donors/donor-search";

// nested routes in route component
import RegionList from "pages/regions/list";
import RegionPickupRoutes from "pages/regions/routes";
import RegionZones from "pages/regions/calendar";
import RegionMap from "pages/regions/map";
import RegionPickupsBlocks from "pages/regions/pickup-blocks";
import CreateUpdateRegion from "pages/regions/create-update";
import { RegionRoutes } from "pages/regions";

// nested routes in user component
// import Users from "./users.container";

import { REACT_APP_MP_SINGLE_REGION, REACT_APP_MP_OLD_ROUTING } from "config";

const App = () => {
  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Helmet>
          <title>{REACT_APP_MP_HTML_TITLE}</title>
          <link
            rel="shortcut icon"
            href={REACT_APP_MP_FAVICON_URL}
            type="image/x-icon"
          />
        </Helmet>
        <ToastWrapper />
        <GlobalSettings>
          <BrowserRouter>
            <Routes>
              <Route element={<>Your Request Timed Out</>} path="/timeout" />
              <Route
                path="/login"
                element={
                  <WithAuth>
                    <Login />
                  </WithAuth>
                }
              />
              <Route
                path="/forgotPassword"
                element={
                  <WithAuth>
                    <ForgotPassword />
                  </WithAuth>
                }
              />
              <Route
                path={`route-v2`}
                element={
                  <WithDashboardData>
                    <RoleGuard allowedRoles={["driver"]}>
                      <DriverRoute />
                    </RoleGuard>
                  </WithDashboardData>
                }
              />

              <Route
                path="/dashboard"
                element={
                  <WithDashboardData>
                    <RoleGuardDashboard allowedRoles={["admin", "office"]}>
                      <Dashboard />
                    </RoleGuardDashboard>
                  </WithDashboardData>
                }
              >
                <Route path={``} element={<Home />} />
                <Route path={`route`} element={<OldDriverRoute />} />
                <Route path={`donors`} element={<Customers />}>
                  <Route path={``} element={<DonorSearch />} />
                  <Route path={`new`} element={<NewCustomer />} />
                  <Route path={`:customerId`} element={<SingleCustomer />} />
                </Route>
                <Route path={`users/*`} element={<Users />}>
                  <Route
                    path={``}
                    element={<Navigate to={`driver`} replace />}
                  />
                  <Route path={`:userType`} element={<Users />} />
                  {/* <Route path={`:userType`} element={<>123</>} /> */}
                </Route>
                <Route path={`exportData`} element={<ExportData />} />
                <Route path={`campaigns`} element={<MarketingReports />} />
                <Route path={`policy`} element={<SMSPrivacyPolicy />} />
                <Route path={`lead-sources`} element={<LeadSources />} />
                <Route path={`location-types`} element={<LocationTypes />} />
                <Route
                  path={`notification-templates`}
                  element={<NotificationTemplates />}
                />
                {REACT_APP_MP_OLD_ROUTING === "true" && (
                  <Route path={`time-windows`} element={<TimeWindows />} />
                )}
                <Route path={`analytics`} element={<Analytics />} />
                {REACT_APP_MP_SINGLE_REGION !== "true" ? (
                  <Route path={`regions/*`} element={<Regions />}>
                    <Route exact path={``} element={<RegionList />} />
                    <Route
                      path={`new`}
                      element={<CreateUpdateRegion newRegion={true} />}
                    />
                    <Route path={`:regionId/*`} element={<RegionRoutes />}>
                      <Route exact path={``} element={<RegionZones />} />
                      <Route path={`zones`} element={<RegionZones />} />
                      <Route path={`map`} element={<RegionMap />} />
                      <Route path={`routes`} element={<RegionPickupRoutes />} />
                      <Route
                        path={`pickup-blocks`}
                        element={<RegionPickupsBlocks />}
                      />
                      <Route
                        path={`settings`}
                        element={<CreateUpdateRegion />}
                      />
                    </Route>
                  </Route>
                ) : (
                  <Route path={`regions/*`} element={<Regions />}>
                    <Route path={`zones`} element={<RegionZones />} />
                    <Route path={`routes`} element={<RegionPickupRoutes />} />
                    <Route
                      path={`pickup-blocks`}
                      element={<RegionPickupsBlocks />}
                    />
                    <Route path={`settings`} element={<CreateUpdateRegion />} />
                  </Route>
                )}
                <Route
                  path="*"
                  element={<Navigate to="/dashboard" replace />}
                />
              </Route>
              <Route path="*" element={<Navigate to="/dashboard" replace />} />
            </Routes>
          </BrowserRouter>
        </GlobalSettings>
      </LocalizationProvider>
    </Provider>
  );
};

export default App;
