import { useMap, useMapEvent } from "react-leaflet";
import { useEffect } from "react";

const LogicControl = ({ onMapViewportChange, setRefMap, viewport }) => {
  const map = useMap();

  useMapEvent("moveend", () => {
    onMapViewportChange(map.getBounds(), map.getZoom());
  });

  useEffect(() => {
    setRefMap(map);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (viewport && viewport.center && viewport.zoom) {
      const { center } = viewport;
      map.panTo(center);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewport]);

  return null;
};

export default LogicControl;
