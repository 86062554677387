import request from "utils/request";
import { formatWeekdayHyphenDate } from "utils/dates";

export const REQUEST_PICKUP_BLOCKS = "REQUEST_PICKUP_BLOCKS";
export const RECEIVE_PICKUP_BLOCKS = "RECEIVE_PICKUP_BLOCKS";

export const CLEAR_PICKUP_BLOCKS = "CLEAR_PICKUP_BLOCKS";

export function requestPickupBlocks(data = {}) {
  return (dispatch) => {
    dispatch({ type: REQUEST_PICKUP_BLOCKS, data });

    return request({ url: `/pickupBlocks`, params: data })
      .then((res) => dispatch(receivePickupBlocks(res.data)))
      .catch((res) => console.error(res));
  };
}

export function clearPickupBlocks() {
  return { type: CLEAR_PICKUP_BLOCKS };
}

export function receivePickupBlocks(data = {}) {
  const pickupBlocks = data.data.map((pickupBlock) => {
    pickupBlock.label = `${formatWeekdayHyphenDate(pickupBlock.date)}  -  ${
      pickupBlock.zone.name
    }  - ${pickupBlock.zone.pickupType.label}`;
    return pickupBlock;
  });

  return {
    type: RECEIVE_PICKUP_BLOCKS,
    pickupBlocks: pickupBlocks,
    zipCodeFound: data.zipCodeFound,
    count: data.count,
    pagination: data.pagination,
    receivedAt: Date.now(),
  };
}
