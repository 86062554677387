import Form from "forms/context-form";

import { Button } from "react-bootstrap";
import React, { useState } from "react";

const RouteAssignPickup = ({
  pickup,
  stopNumber,
  pickupRoutes,
  routeId,
  onCancel,
  reassignIn,
  reassignFromTo,
  assignTo,
}) => {
  const [selectedRouteId, setSelectedRouteId] = useState(routeId);
  const [selectedStopNumber, setSelectedStopNumber] = useState(stopNumber);
  const assignStopOptions = () => {
    const currentRoutId = selectedRouteId || routeId;
    const pickupStops = pickupRoutes.routesMap[currentRoutId]
      ? [...pickupRoutes.routesMap[currentRoutId].pickups]
      : [];

    if (
      (pickup && !pickup.routeId) ||
      (selectedRouteId && parseInt(selectedRouteId) !== routeId)
    ) {
      pickupStops.push(1);
    }

    if (pickupStops.length === 0) {
      pickupStops.push(1);
    }

    return pickupStops.map((pickupId, index) => ({
      id: index + 1,
      label: `Stop ${index + 1}`,
    }));
  };

  return (
    <div>
      <div className={"p-3"}>
        <h3>{pickup && pickup.user ? pickup.user.fullName : null}</h3>
        <h4 className="m-0">
          {pickup && pickup.location ? pickup.location.mapAddress : null}
        </h4>
      </div>
      <div style={{ padding: "2rem" }}>
        <React.Fragment>
          <div className="form-style">
            <Form.Field
              value={selectedRouteId}
              name="routeId"
              selectOptions={pickupRoutes.pickupRoutes.map((route) => ({
                id: route.id,
                label: `Route ${route.position + 1}`,
              }))}
              optionField="label"
              type="select"
              onChange={(e) => {
                setSelectedRouteId(e.target.value);
              }}
            />

            <Form.Field
              value={selectedStopNumber}
              name="stopNumber"
              selectOptions={assignStopOptions()}
              optionField="label"
              type="select"
              onChange={(e) => {
                setSelectedStopNumber(e.target.value);
              }}
            />
          </div>
          <div className={"form-footer"} style={{ justifyContent: "flex-end" }}>
            <div>
              <Button variant="link" onClick={onCancel}>
                Cancel
              </Button>

              <Button
                type="submit"
                disabled={!(selectedRouteId && selectedStopNumber)}
                variant="primary"
                onClick={() => {
                  const selectedRouteIdInt = parseInt(selectedRouteId);
                  if (routeId === selectedRouteIdInt) {
                    reassignIn(selectedStopNumber - 1);
                  } else if (!routeId) {
                    assignTo(selectedRouteIdInt, selectedStopNumber - 1);
                  } else {
                    reassignFromTo(selectedRouteIdInt, selectedStopNumber - 1);
                  }
                  onCancel();
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </React.Fragment>
      </div>
    </div>
  );
};

export default RouteAssignPickup;
