import config from "config";
// Given a [lon, lat], return the [x, y] coords of the chunk containing it.
export const computeChunkGridCoords = ([lon, lat]) => [
  Math.floor(
    (lon - Number(config.REACT_APP_MP_ZIP_CODE_GEO_JSON_MIN_LON)) /
      Number(config.REACT_APP_MP_ZIP_CODE_GEO_JSON_GRID_SIZE)
  ),
  Math.floor(
    (lat - Number(config.REACT_APP_MP_ZIP_CODE_GEO_JSON_MIN_LAT)) /
      Number(config.REACT_APP_MP_ZIP_CODE_GEO_JSON_GRID_SIZE)
  ),
];
// Given the x, y coords of the grid chunk and a lat lng bounds, determine if they intersect
export const chunkIntersect = ([x, y], bounds) => {
  const [xMin, yMin] = computeChunkGridCoords([
    bounds.getSouth(),
    bounds.getWest(),
  ]);
  const [xMax, yMax] = computeChunkGridCoords([
    bounds.getNorth(),
    bounds.getEast(),
  ]);
  return xMin <= x && yMin <= y && x <= xMax && y <= yMax;
};
