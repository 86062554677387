import request from "utils/request";
import { determineAccountSearchType } from "../utils/helpers";

export const SEARCH_CUSTOMERS = "SEARCH_CUSTOMERS";
export const SEARCH_CUSTOMERS_ERROR = "SEARCH_CUSTOMERS_ERROR";
export const RECEIVE_CUSTOMERS = "RECEIVE_CUSTOMERS";
export const CLEAR_SEARCH_CUSTOMERS = "CLEAR_SEARCH_CUSTOMERS";
export const ADD_SEARCH_TAB = "ADD_SEARCH_TAB";
export const UPDATE_SEARCH_TAB = "UPDATE_SEARCH_TAB";
export const UPDATE_SELECTED_TAB = "UPDATE_SELECTED_TAB";
export const REMOVE_SEARCH_TAB = "REMOVE_SEARCH_TAB";
export const SET_ACTIVE_SEARCH_TAB = "SET_ACTIVE_SEARCH_TAB";
export const SELECT_USER = "SELECT_USER";
export const SET_SEARCH_QUERY = "SET_SEARCH_QUERY";
export const HANDLE_TOGGLE_ALL = "HANDLE_TOGGLE_ALL";

export function searchCustomers(query = {}, type, typeSearch) {
  return (dispatch) => {
    let reqData = { typeSearch };
    switch (query.type) {
      case "address":
        reqData = {
          address: query.name,
          type,
          count: 1000,
          typeSearch,
        };
        break;

      case "account":
        reqData = {
          [determineAccountSearchType(query.name)]: query.name,
          type,
          count: 1000,
          typeSearch,
        };
        break;

      case "pickupId":
        reqData = {
          pickupId: query.name,
          type,
          count: 1000,
          typeSearch,
        };
        break;
      default:
        break;
    }

    dispatch({ type: SEARCH_CUSTOMERS, data: { query } });
    dispatch(addSearchTab({ query, loading: true }));
    request({ url: `/v2/users`, params: reqData })
      .then((res) => {
        dispatch(
          addSearchTab({
            query,
            results: res.data,
            loading: false,
            selected: [],
          })
        );
        // resetting the query after search happens
        dispatch(setSearchQuery({ query: "" }));
      })
      .catch((res) => {
        dispatch(searchCustomersError(res.response));
      });
  };
}

export function clearSearchCustomers() {
  return { type: CLEAR_SEARCH_CUSTOMERS };
}

export function updateSelectedTab({ userToDelete, deleteAll }) {
  return {
    type: UPDATE_SELECTED_TAB,
    userToDelete,
    deleteAll,
  };
}
export function updateSearchTab({ query, selected }) {
  return {
    type: UPDATE_SEARCH_TAB,
    selected,
    query,
  };
}

function searchCustomersError(data = {}) {
  return {
    type: SEARCH_CUSTOMERS_ERROR,
    error: data.data,
  };
}

export function addSearchTab({ query, results, loading, selected }) {
  const getLatestPickupDate = (customer) =>
    new Date(
      customer.pickups.reduce(
        (pickupOne, pickupTwo) =>
          pickupOne.pickupDate > pickupTwo.pickupDate ? pickupOne : pickupTwo,
        { pickupDate: 0 }
      ).pickupDate
    );

  // Sort the results by latest pickup date
  if (results?.data?.length > 0) {
    if ("user" in results.data[0]) {
      results.data = results.data.map((item) => {
        item = { ...item, ...item.user };
        delete item.user;
        return item;
      });
    }

    results.data.sort((a, b) => {
      const aDate = getLatestPickupDate(a);
      const bDate = getLatestPickupDate(b);

      return aDate > bDate ? -1 : aDate === bDate ? 0 : 1;
    });
  }

  return {
    type: ADD_SEARCH_TAB,
    tab: query,
    results,
    loading,
    selected,
  };
}

export function removeSearchTab({ tab }) {
  return {
    type: REMOVE_SEARCH_TAB,
    tab,
  };
}

export const changeActiveTab = ({ tab }) => {
  return {
    type: SET_ACTIVE_SEARCH_TAB,
    tab,
  };
};

export const selectUser = ({ user }) => {
  return {
    type: SELECT_USER,
    user,
  };
};

export const handleToggleAll = ({ users, isBeingToggledOn }) => {
  return {
    type: HANDLE_TOGGLE_ALL,
    users,
    isBeingToggledOn,
  };
};

export const setSearchQuery = ({ query }) => {
  return {
    type: SET_SEARCH_QUERY,
    query,
  };
};
