import React from "react";
import PropTypes from "prop-types";

import { utcOffset, addAmountToDate } from "utils/dates";

import { FormLabel, FormGroup, FormControl, Alert } from "react-bootstrap";

import { TimePicker } from "@mui/x-date-pickers";

const FormField = ({
  input,
  label,
  type,
  placeholder,
  meta: { touched, error, valid },
  required,
}) => {
  const validationError = touched && error;

  let errorComponent;

  if (validationError) {
    let errorMessage;
    if (error[0] === '"' && error.slice(1).indexOf('"')) {
      errorMessage = `${label || "This"}${error.substr(
        error.slice(1).indexOf('"') + 2
      )}`;
    }
    errorComponent = <Alert variant="danger">{errorMessage || error}</Alert>;
    // validationState = "error";
  }

  if (valid) {
    // validationState = "success";
  }

  const _onChange = (value) => {
    const offset = utcOffset(value);
    input.onChange(addAmountToDate(value, offset, "m"));
  };

  let value = null;

  // Timepicker uses a local offset by default, here we manually remove that offset so everything stays in UTC.
  // only storing date object to use this component otherwise string for time would have been fine.
  if (input.value) {
    const offset = utcOffset(input.value) * -1; // to reverse
    value = addAmountToDate(input.value, offset, "m");
  }

  return (
    <FormGroup
      // validationState={validationState}
      className={`${type}-form-group`}
    >
      <FormLabel>
        {label}
        {required && <span className="required-asterick">*</span>}
      </FormLabel>
      <TimePicker
        onChange={_onChange}
        defaultValue={value}
        className={"time-picker"}
        format="h:mm A"
        timeSteps={{ minutes: 30 }}
      />
      {errorComponent}
      <FormControl.Feedback />
    </FormGroup>
  );
};

FormField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  touched: PropTypes.bool,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  removeField: PropTypes.func,
  required: PropTypes.bool,
};

export default FormField;
