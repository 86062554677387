import { connect } from "react-redux";
import { compose } from "redux";
// import { getFormValues } from "redux-form";
// import injectReducer from "utils/injectReducer";
// import customersReducer from './customers.reducer'

import {
  requestCustomer,
  updateCustomer,
  clearCustomer,
} from "./customer.actions";
import { createPickup, requestPickups } from "./donor-pickups/pickups.actions";

import { requestCustomerActions } from "./donor-timeline/customer-timeline.actions";

import SingleCustomerComponent from "./single-customer.component";

function mapStateToProps(state) {
  return {
    customer: {
      ...state.customer,
    },
    noteCategories: [...state.common.data.noteCategories],
  };
}

const withConnect = connect(mapStateToProps, {
  requestCustomer,
  requestPickups,
  updateCustomer,
  clearCustomer,
  createPickup,
  requestCustomerActions,
});

// const withReducer = injectReducer({ key: 'customer', reducer: customersReducer });

export default compose(
  // withReducer,
  withConnect
)(SingleCustomerComponent);
