import React from "react";
import { Tab } from "react-bootstrap";

import TabContext from "./tab-context";

const Tabs = ({
  eq = (a, b) => a === b,
  active,
  onChange,
  onRemove = null,
  header = null,
  children,
  fullWidth,
}) => {
  return (
    <TabContext.Provider value={{ eq, active, onChange, onRemove }}>
      <Tab.Container activeKey={active}>
        <div className={`mb-4 ${fullWidth && "full-width"}`}>
          <div className={"d-flex w-100 mb-2 justify-space-between"}>
            {header}
          </div>
          <div>
            <ul role="tablist" className={`nav nav-pills`}>
              {children}
            </ul>
          </div>
        </div>
      </Tab.Container>
    </TabContext.Provider>
  );
};

export default Tabs;
