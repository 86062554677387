import { connect } from "react-redux";

import {
  requestCustomer,
  updateCustomer,
  clearCustomer,
} from "pages/donors/donor-single/customer.actions";

import { requestCustomerActions } from "../donor-timeline/customer-timeline.actions";

import DonorInfo from "./donor-info.component";

function mapStateToProps(state) {
  return {
    customer: state.customer.customer,
    leadSources: state.common.data.leadSource,
    common: state.common.dataMap,
  };
}

export default connect(mapStateToProps, {
  requestCustomer,
  updateCustomer,
  clearCustomer,
  requestCustomerActions,
})(DonorInfo);
