import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import admin from "./admin";
import agent from "./agent";
import driver from "./driver";
import Icons from "components/icon";

import { REACT_APP_MP_PRIMARY_COLOR } from "config";

export const NavHeader = ({ children }) => (
  <div className="sidebar-header">{children}</div>
);
//TODO: Remove inline style
export const NavLink = ({ to, icon, children, toggleNav, ...rest }) => (
  <LinkContainer
    to={to}
    onClick={() => toggleNav()}
    className="sidebar-nav-item"
    {...rest}
  >
    <a href="#!" className="sidebar-nav-item nav-item">
      <div>
        <span
          style={{ marginRight: ".5rem", color: REACT_APP_MP_PRIMARY_COLOR }}
        >
          <Icons type={icon} />
        </span>
        {children}
      </div>
    </a>
  </LinkContainer>
);

const AdminNavigation = ({ userTypeId, toggleNav }) => (
  <div>
    {userTypeId
      ? Object.entries({ 1: admin, 2: agent, 3: driver }[userTypeId]).map(
          ([heading, items], mainIndex) => (
            <div key={mainIndex}>
              <NavHeader>{heading}</NavHeader>
              {items.map((item, index) => (
                <NavLink
                  key={mainIndex.toString() + index}
                  to={item.to}
                  icon={item.icon}
                  toggleNav={toggleNav}
                >
                  {item.text}
                </NavLink>
              ))}
            </div>
          )
        )
      : null}
  </div>
);

export default AdminNavigation;
