import { connect } from "react-redux";
import Reports from "./export-agent-report.component";

import withForm from "forms/form-class";
import { downloadFile } from "utils/helpers";
import { formatDateHyphenatedYearFirst, formatQueryDate } from "utils/dates";

const Connected = withForm(
  Reports,
  "Reports",
  null,
  null,
  true,
  [],
  "Download"
);

function createQuery(data) {
  let exportUrl = "/users/report/agent?";

  if (data.startDate) {
    exportUrl += `&startDate=${formatQueryDate(data.startDate)}`;
  }
  if (data.endDate) {
    exportUrl += `&endDate=${formatQueryDate(data.endDate)}`;
  }
  return exportUrl;
}

const mapStateToProps = (state) => {
  return {
    onSubmit: (data) =>
      downloadFile(
        createQuery(data),
        `agent-report-${formatDateHyphenatedYearFirst(
          data.startDate
        )}-${formatDateHyphenatedYearFirst(data.endDate)}.csv`
      ),
  };
};

export default connect(mapStateToProps)(Connected);
