import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { useNavigate, useLocation } from "react-router-dom";
import { useIsLoggedIn } from "utils/helpers";

const LoginComponent = ({ children, auth }) => {
  const isLoggedIn = useIsLoggedIn();

  const location = useLocation();
  const navigate = useNavigate();
  const [queryParams] = useState(queryString.parse(location.search));

  useEffect(() => {
    if (isLoggedIn && auth.credentials.userTypeId) {
      console.log("login was authenticated");
      //TODO: Extract from here
      let location = "/dashboard";

      // redirect if we have /login?location=/dashboard/routes
      if (location.query && queryParams.location) {
        location = queryParams.location;
      }
      // This handles pushing drivers to their own routes
      if (auth.credentials.userTypeId === 3) {
        navigate("/dashboard/route");
      } else navigate(location);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.credentials.userTypeId, isLoggedIn]);

  return <React.Fragment>{children}</React.Fragment>;
};

export default LoginComponent;
