import { Button } from "react-bootstrap";
import React from "react";
import Modal from "components/modal";
import PickupModal from "components/pickup-modal";
import { findDOMNode } from "react-dom";

class RouteEditPickup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPickupForm: false,
      pikcupToEditLoading: false,
    };
  }

  _closeModal = () =>
    this.setState({
      showPickupForm: false,
    });

  handleEditPickupSubmit = (pickup) => {
    // let { pickupRoutes } = this.props;

    pickup.sortStopNumbers = true;

    this.props.updateRoutePickup(pickup).then(() => {});

    if (findDOMNode(this.refs[`pickupStatus-${pickup.id}`])) {
      findDOMNode(this.refs[`pickupStatus-${pickup.id}`]).value =
        pickup.statusId; // have to update the pickup form field manually
    }
    return this._closeModal();
  };

  _handleEditPickupClick = async () => {
    this.setState({
      pikcupToEditLoading: true,
    });
    const { pickup } = this.props;
    //Refresh pickup before edit
    await this.props.requestLocations({ customerId: pickup.userId });
    await this.props.refreshRoutePickup(pickup.id);
    // Requests the PickupBlocks for this
    await this.props.requestRoutePickupBlocks({
      zipCode: pickup.location.zip,
      includePickupBlockId: pickup.pickupBlockId,
    });
    this.setState({
      showPickupForm: true,
      pikcupToEditLoading: false,
    });
  };

  render() {
    const { routePickupBlocks, pickup, requestLocations } = this.props;

    const { pickupBlocks } = routePickupBlocks;

    return (
      <React.Fragment>
        <Button
          variant="primary"
          onClick={() => {
            this._handleEditPickupClick();
          }}
          disabled={this.state.pikcupToEditLoading}
        >
          {this.state.pikcupToEditLoading ? "Loading..." : " Edit"}
        </Button>
        <Modal
          show={this.state.showPickupForm}
          onHide={this._closeModal}
          title={"Update"}
        >
          <PickupModal
            initialValues={{
              ...pickup,
              pickupBlock:
                pickupBlocks.find(
                  (block) => pickup.pickupBlockId === block.id
                ) || null,
              pickupBlockId: pickup.pickupBlockId
                ? pickup.pickupBlockId
                : "false",
            }}
            locations={[pickup.location]}
            pickupBlocks={routePickupBlocks}
            onCancel={this._closeModal}
            onSubmit={this.handleEditPickupSubmit}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

export default RouteEditPickup;
