import { Field } from "redux-form";
import FormField from "forms/FormField";
import DatePicker from "forms/date-picker";
import { required } from "utils/validators";
import { FormGroup } from "react-bootstrap";
import RenderIf from "hoc/render-if";
import React from "react";
const DonorExport = (props) => (
  <React.Fragment>
    <Field
      component={FormField}
      label={"Download Type"}
      name={"exportType"}
      type="select"
      options={[
        { id: "byCreatedAt", label: "Created Date" },
        { id: "byLastPickupDate", label: "Last Pickup Date" },
        { id: "byFirstPickupDate", label: "First Pickup Date" },
      ]}
      required={true}
      validate={required}
    />
    <Field
      className="report-date-picker*"
      component={DatePicker}
      label="From"
      name="startDate"
      // handleChange={this.startDateChange}
      required={true}
      type="text"
      validate={required}
    />
    <Field
      className="report-date-picker*"
      component={DatePicker}
      label="To"
      name="endDate"
      // handleChange={this.endDateChange}
      required={true}
      type="text"
      validate={required}
      // value={this.state.endDate}
    />

    <FormGroup>
      <label htmlFor="Filters">Filters</label>
      <RenderIf condition={props.exportTypeCreatedAt}>
        <Field
          label={"No pickups"}
          name="withNoPickups"
          component={FormField}
          type="checkbox"
          //   component={"input"}
        />
      </RenderIf>
      <RenderIf condition={props.userFilterType === "usersCreated"}>
        <Field
          label={"No donations"}
          name="withNoDonations"
          component={FormField}
          type="checkbox"
          //   component={"input"}
        />
      </RenderIf>
      <Field
        label={"Registered"}
        name="registered"
        component={FormField}
        type="checkbox"
        //   component={"input"}
      />
      <Field
        label={"Guests"}
        name="guest"
        component={FormField}
        type="checkbox"
        //   component={"input"}
      />
      <Field
        label={"Opted-In to Marketing Calls"}
        name="allowMarketingCalls"
        component={FormField}
        type="checkbox"
        //   component={"input"}
      />
    </FormGroup>
  </React.Fragment>
);

export default DonorExport;
