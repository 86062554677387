import React, { useCallback, useState } from "react";
import { GoogleMap, useJsApiLoader, Marker, adv } from "@react-google-maps/api";
import { REACT_APP_MP_GOOGLE_MAPS_API } from "config";
import { Stack, Row, Col } from "react-bootstrap";

const mapConfig = {
  id: "driver-map",
  googleMapsApiKey: REACT_APP_MP_GOOGLE_MAPS_API,
  libraries: ["drawing", "geometry", "places"],
};

export const RenderDetails = ({ location, pickupType, title }) => {
  const [map, setMap] = useState(null);

  const { isLoaded } = useJsApiLoader(mapConfig);
  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <div className="driver-pickup-details">
      <div className="mb-4 title">
        <strong>{title}</strong>
      </div>
      <Row className="gap-3 wrapper-map-text">
        <Col className="p-0 details-box driver-map">
          {isLoaded && (
            <GoogleMap
              mapContainerStyle={{ height: "16rem" }}
              center={location.coordinates}
              zoom={12}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              <Marker position={location.coordinates} />
            </GoogleMap>
          )}
        </Col>
        <Col className="details-box px-3 py-2">
          <Stack gap={3}>
            <div>
              <strong>{location.fullAddress}</strong>
              {location.unit && <div>Unit: {location.unit}</div>}
              {location.subdivision && (
                <div>Subdivision: {location.subdivision}</div>
              )}
            </div>
            <div>
              <strong>Pickup Type</strong>
              <div>{pickupType} Pickup</div>
            </div>
            {location.notes && (
              <div>
                <strong>Location notes</strong>
                <div>{location.notes}</div>
              </div>
            )}
          </Stack>
        </Col>
      </Row>
    </div>
  );
};
