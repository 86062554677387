import request from "utils/request";

export const REQUEST_COMMON = "REQUEST_COMMON";
export const RECEIVE_COMMON = "RECEIVE_COMMON";
export const FAILED_COMMON = "FAILED_COMMON";

export const getCommon = () => request({ url: `/common` });

export function requestCommon() {
  return (dispatch) => {
    dispatch({ type: REQUEST_COMMON });

    getCommon()
      .then((res) => dispatch({ type: RECEIVE_COMMON, data: res.data.data }))
      .catch((res) => dispatch({ type: FAILED_COMMON, error: res.message }));
  };
}
