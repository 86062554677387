import React, { Component } from "react";
import { Outlet } from "react-router";

class DonorLayoutComponent extends Component {
  render() {
    return (
      <div className="content-wrap">
        <Outlet />
      </div>
    );
  }
}

export default DonorLayoutComponent;
