import React, { useContext, useEffect, useState } from "react";

import {
  Form,
  FormControl,
  Button,
  ListGroup,
  ListGroupItem,
} from "react-bootstrap";

import { displayPickupBlockDate } from "utils/dates";
import Icon from "components/icon";
import config from "config";

const ZipPickupDateSearch = (props) => {
  const [zipFilter, setZipFilter] = useState("");
  const [pickupTypeId, setPickupTypeId] = useState(1);
  const [showResults, setShowResults] = useState(false);

  const { pickupBlocks, loaded, zipCodeFound } = props.pickupBlocks;

  const noPickupsFound = "No pickups available";
  const notServiced = "Region not serviced";

  const renderPickupBlocks = pickupBlocks.map((pickupBlock) => {
    if (pickupBlock.stopsAvailable > 0) {
      return (
        <ListGroupItem
          key={pickupBlock.id}
          onClick={() => props.clickPickupBlock(pickupBlock)}
        >
          <h4 style={{ marginTop: "0px" }}>{pickupBlock.zone.name}</h4>
          <h5>{displayPickupBlockDate(pickupBlock.date)}</h5>
          <div>
            <Icon
              type={pickupBlock.zone.pickupType.label.toLocaleLowerCase()}
            />{" "}
            {pickupBlock.zone.pickupType.label} Pickup
          </div>
        </ListGroupItem>
      );
    } else {
      return null;
    }
  });

  useEffect(() => {
    if (props.pickupTypes.length) {
      const zipSearchType = localStorage.getItem("zipSearchType");
      setPickupTypeId(zipSearchType || props.pickupTypes[0].id);
    }
  }, [props.pickupTypes]);

  const updateZipFilter = (e) => {
    setZipFilter(e.target.value);
    setShowResults(false);
  };

  const updatePickupTypeIdFilter = (e) => {
    const pickupTypeId = e.target.value;
    localStorage.setItem("zipSearchType", `${pickupTypeId}`);
    setPickupTypeId(pickupTypeId);
    setShowResults(false);
  };

  const searchPickupBlocks = (e) => {
    e.preventDefault();

    // Really basic validation, do nothing if blank.
    if (zipFilter === "") {
      return false;
    }

    props.requestPickupBlocks({
      zipCode: zipFilter,
      pickupTypeId: pickupTypeId,
      availableStops: true,
      futureEvents: true,
      enabled: true,
    });
    setShowResults(true);
  };
  return (
    <div className="zip-search">
      <Form onSubmit={searchPickupBlocks}>
        <Form.Group>
          <FormControl
            type="text"
            placeholder={config.REACT_APP_MP_PLACEHOLDER_ZIP}
            onChange={updateZipFilter}
          />
        </Form.Group>
        {props.pickupTypes.map((pickupType) => (
          <Form.Group key={pickupType.id}>
            <Form.Check
              label={`${pickupType.label} Pickup`}
              type="radio"
              value={pickupType.id}
              onChange={updatePickupTypeIdFilter}
              checked={parseInt(pickupTypeId) === parseInt(pickupType.id)}
            />
          </Form.Group>
        ))}

        <Button style={{ width: "fit-content" }} type="submit">
          Search
        </Button>
      </Form>
      <ListGroup>
        {(() => {
          if (showResults && loaded) {
            if (!zipCodeFound) {
              return notServiced;
            } else if (pickupBlocks.length > 0) {
              return renderPickupBlocks;
            } else {
              return noPickupsFound;
            }
          } else {
            return null;
          }
        })()}
      </ListGroup>
    </div>
  );
};

export default ZipPickupDateSearch;
