import React, { Component, useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";
import Modal from "components/modal";

import CreateUpdateInternalUserForm from "forms/create-update-internal-user";
import PageLayout from "layouts/pages/page-default";
import UserTable from "pages/user-manager/internal-user-table";
import Tabs from "components/tabs";
import { UserListContext, UserListProvider } from "../../contexts/user-list";
import Form from "../../forms/context-form";
import { useParams, useNavigate, useLocation } from "react-router";

class UsersComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDisabled: false,
      pageSize: 1000,
      page: 1,
      selectedRegionId: null,
    };
  }

  get userType() {
    return { administrator: 1, agent: 2, driver: 3 }[
      this.props.params.userType
    ];
  }

  getUsers(opts) {
    // Pull the users from the API
    this.props.getUsers({
      typeSearch: "internal",
      type: this.userType,
      count: this.state.pageSize,
      page: this.state.page,
      ...(!this.state.showDisabled && { disabled: this.state.showDisabled }),
      ...opts,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.params.userType !== this.props.params.userType) {
      this.setState({ page: 1 });

      // Reload user data when the selected userType changes
      this.getUsers({ page: 1 });
    }
  }

  componentDidMount() {
    this.getUsers({
      page: 1,
    });
  }

  onPageChange(idx) {
    this.getUsers({ page: idx + 1 });
    this.setState({ page: idx + 1 });
  }

  onPageSizeChange(size, idx) {
    this.getUsers({ page: idx + 1, count: size });
    this.setState({ ...this.state, pageSize: size });
  }

  handleChange = (value) => {
    this.props.navigate(`/dashboard/users/${value}`);
  };

  handleShowDisabledChange = (e) => {
    this.setState({ showDisabled: e.target.checked });
    this.getUsers({ disabled: e.target.checked });
  };

  addNew = () => {
    this.setState({ addNew: true });
  };

  closeModal = () => {
    this.setState({ addNew: false });
  };

  // Get the users to display
  getArrayOfValues() {
    let values = Object.values(this.props.users).filter(
      (user) =>
        user.userTypeId === this.userType &&
        (this.state.showDisabled || !user.disabled)
    );

    // If the userType filter is set to drivers, get the region names
    if (this.userType === 3) {
      values = values.map((user) => ({
        ...user,
        region:
          user.regionId &&
          this.props.allRegions.find((region) => region.id === user.regionId)
            .label,
      }));
      //filter drivers by region id
      if (this.state.selectedRegionId) {
        values = values.filter(
          (user) => user.regionId === this.state.selectedRegionId
        );
      }
    }
    return values;
  }

  render() {
    const HeaderActions = () => (
      <Button
        variant={"primary"}
        onClick={() => {
          this.addNew();
        }}
      >
        Add New
      </Button>
    );
    const idUserDriver = this.userType === 3;

    return (
      <PageLayout title="Users" actionComponents={[HeaderActions]}>
        {/*<Row>*/}
        {/*  <Col sm={4} xsOffset={8}>*/}
        {/*    <input*/}
        {/*      type="checkbox"*/}
        {/*      id="showDisabled"*/}
        {/*      onChange={this.handleShowDisabledChange}*/}
        {/*      checked={this.state.showDisabled}*/}
        {/*    />*/}
        {/*    <label*/}
        {/*      htmlFor="showDisabled"*/}
        {/*      style={{*/}
        {/*        display: 'inline',*/}
        {/*        marginLeft: '10px',*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      Show Disabled Users*/}
        {/*    </label>*/}
        {/*  </Col>*/}
        {/*</Row>*/}
        <Row>
          <Col sm={12}>
            <Row className="align-items-end">
              <Col sm={idUserDriver ? 8 : 12}>
                <Tabs
                  active={this.props.params.userType}
                  onChange={this.handleChange}
                >
                  <Tabs.Tab value="administrator">Administrator</Tabs.Tab>
                  <Tabs.Tab value="agent">Agents</Tabs.Tab>
                  <Tabs.Tab value="driver">Drivers</Tabs.Tab>
                </Tabs>
              </Col>
              {idUserDriver && (
                <Col
                  className="d-flex align-items-center"
                  style={{ marginBottom: "0.5rem" }}
                  sm={4}
                >
                  <strong style={{ marginRight: "0.5rem" }}>Region:</strong>
                  <div className="flex-sm-fill">
                    <Form.Field
                      name="regionId"
                      selectOptions={this.props.activeRegions}
                      optionField="label"
                      type="select"
                      placeholder={"All regions"}
                      onChange={(e) => {
                        const selectedRegionId =
                          e.target.value === "-1"
                            ? null
                            : Number.parseInt(e.target.value);
                        this.setState({
                          ...this.state,
                          selectedRegionId,
                        });
                      }}
                    />
                  </div>
                </Col>
              )}
            </Row>
            <UserTable
              data={this.getArrayOfValues()}
              loading={this.props.usersLoading}
              columns={[
                { field: "profileImage", label: " " },
                { field: "fname", label: "First Name", filter: true },
                { field: "lname", label: "Last Name", filter: true },
                // {field: "fullName", label: "Full Name", search: true},
                { field: "email", label: "Email", filter: true },
                idUserDriver
                  ? { field: "region", label: "Region", filter: true }
                  : undefined,
                { field: "createdAt", label: "Created" },
              ]}
              pages={this.props.usersPagination.totalPages}
              pageSize={this.state.pageSize}
              page={this.props.usersPagination.page - 1}
              onPageChange={this.onPageChange.bind(this)}
              onPageSizeChange={this.onPageSizeChange.bind(this)}
              getUsers={this.getUsers.bind(this)}
            />
          </Col>
        </Row>

        <Modal
          show={this.state.addNew}
          title={"Add New"}
          onHide={() => {
            this.closeModal();
          }}
        >
          <CreateUpdateInternalUserForm
            selectedRegionId={this.state.selectedRegionId}
            editing={false}
            initialValues={{
              regionId: this.props.regions[0].id,
              userTypeId: this.userType,
            }}
            onCancel={() => {
              this.closeModal();
              this.getUsers();
            }}
            onSubmitSuccess={() => {
              this.closeModal();
              this.getUsers();
            }}
          />
        </Modal>
      </PageLayout>
    );
  }
}

const Wrapped = (props) => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !(
        location.pathname.includes("administrator") ||
        location.pathname.includes("agent") ||
        location.pathname.includes("driver")
      )
    ) {
      navigate("administrator");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <UserListProvider>
      <UserListContext.Consumer>
        {(userListValues) => (
          <UsersComponent
            params={params}
            navigate={navigate}
            {...props}
            {...userListValues}
          />
        )}
      </UserListContext.Consumer>
    </UserListProvider>
  );
};

export default Wrapped;
