import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import Reports from "./export-pickups.component";

import {
  formatDateHyphenatedYearFirst,
  getStartOrEndISOS,
  formatQueryDate,
} from "utils/dates";
import { requestPickups } from "pages/donors/donor-single/donor-pickups/pickups.actions";
import withForm from "forms/form-class";
import { downloadFile } from "utils/helpers";

const Connected = withForm(
  Reports,
  "Reports",
  null,
  null,
  false,
  [],
  "Download"
);

const selectBoxes = [
  "regionId",
  "pickupType",
  "verified",
  "statusId",
  "sourceId",
  "statusDescriptionId",
];

function createQuery(data) {
  let exportUrl = `/pickups/csv?&pickupFilterType=${data.pickupFilterType}`;
  if (data.pickupFilterType === "createdAt") {
    if (data.startDate) {
      exportUrl += `&startDate=${getStartOrEndISOS(data.startDate)}`;
    }
    if (data.endDate) {
      exportUrl += `&endDate=${getStartOrEndISOS(data.endDate, false)}`;
    }
  } else {
    if (data.startDate) {
      exportUrl += `&startDate=${formatQueryDate(data.startDate)}`;
    }
    if (data.endDate) {
      exportUrl += `&endDate=${formatQueryDate(data.endDate, false)}`;
    }
  }
  if (data.zip) {
    exportUrl += `&zip=${data.zip}`;
  }

  for (const name of selectBoxes)
    if (data[name] !== "-1") {
      exportUrl += `&${name}=${data[name]}`;
    }

  return exportUrl;
}

const mapDispatchToProps = {
  requestPickups,
};

const formSelector = formValueSelector("Reports");
const mapStateToProps = (state) => {
  return {
    pickupStatuses: [...state.common.data.pickupStatuses],
    pickupSources: [...state.common.data.pickupSources],
    pickupTypes: state.common.data.pickupTypes,
    pickupStatusDescriptions: state.common.data.pickupStatusDescriptions,
    pickupStatusId: formSelector(state, "statusId"),
    regions: state.regions.regions,
    onSubmit: (data) =>
      downloadFile(
        createQuery(data),
        `pickups-${formatDateHyphenatedYearFirst(
          data.startDate
        )}-${formatDateHyphenatedYearFirst(data.endDate)}.csv`
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Connected);
