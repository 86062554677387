import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { logout } from "actions/login.actions";
import Modal from "components/modal";
import UpdateDonorProfile from "forms/update-donor-profile";
import { UserContext } from "contexts/user";
import { REACT_APP_MP_LOGO_URL } from "config";

import { useLocation, useNavigate } from "react-router";

const CustomToggle = React.forwardRef(({ user, userType, onClick }, ref) => (
  <div
    className={"user-dropdown"}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <div className={"user-dropdown-avatar"}>
      <div className={"user-dropdown-avatar-icon"}>
        <img
          style={{ width: 48, height: 48 }}
          src={user.profileImage}
          alt="user-avatar"
        />
      </div>
    </div>
    <div className={"user-dropdown-info"}>
      <div className={"user-dropdown-name"}>
        {user.fname} {user.lname}
      </div>
      <div className={"user-dropdown-type"}>{userType}</div>
    </div>
  </div>
));
const Header = ({ logout, user, userType, driverMode }) => {
  const [editProfile, setEditProfile] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <div className={"top-nav driver"}>
        <div>
          <div className={"sidebar-branding driver"}>
            <img src={REACT_APP_MP_LOGO_URL} />
          </div>
        </div>

        <Dropdown>
          <Dropdown.Toggle
            as={CustomToggle}
            className="user-dropdown"
            user={user}
            userType={userType}
          />

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setEditProfile(true)}>
              Profile
            </Dropdown.Item>
            {driverMode && (
              <Dropdown.Item
                onClick={() => {
                  const searchParams = location.search;
                  navigate(`/dashboard/route${searchParams}`, {
                    replace: true,
                  });
                }}
              >
                Switch to Driver-V1
              </Dropdown.Item>
            )}
            <Dropdown.Item eventKey="1" onClick={logout}>
              Sign Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Modal
          show={editProfile}
          title="Edit Profile"
          onHide={() => setEditProfile(false)}
        >
          <UpdateDonorProfile
            onCancel={() => setEditProfile(false)}
            onSubmitSuccess={() => setEditProfile(false)}
          />
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: {
    ...state.auth,
  },
  userType: state.auth.credentials.userType.label,
});

const mapDispatchToProps = {
  logout,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => (
  <UserContext.Consumer>
    {({ user }) => <Header {...props} user={user} />}
  </UserContext.Consumer>
));
