import React, { useCallback, useContext } from "react";
import { FormCheck, FormGroup, FormLabel } from "react-bootstrap";
import { UserContext } from "contexts/user";

const NotificationSettings = ({ externalData }) => {
  const { user, updateUser } = useContext(UserContext);

  const setNotificationType = useCallback(
    async (type, value) => {
      await updateUser({
        ...user,
        pickupNotifications: { ...user.pickupNotifications, [type]: value },
      });
    },
    [updateUser, user]
  );

  return (
    <>
      <FormGroup>
        <FormLabel>Pickup Notifications</FormLabel>
        <FormCheck
          label="Email"
          id="allow-email-notifications"
          checked={externalData?.email || user?.pickupNotifications?.email}
          onChange={(e) => {
            !externalData?.email &&
              setNotificationType("email", e.target.checked);
          }}
        />
        <FormCheck
          label="SMS"
          id="allow-sms-notifications"
          checked={externalData?.text || user?.pickupNotifications?.text}
          onChange={(e) => {
            !externalData?.text &&
              setNotificationType("text", e.target.checked);
          }}
        />
      </FormGroup>

      <FormGroup>
        <label htmlFor="allowMarketingCalls">Marketing Calls Allowed</label>
        <FormCheck
          label="Yes"
          id="allow-marketing-calls"
          checked={
            externalData?.allowMarketingCalls || user?.allowMarketingCalls
          }
          onChange={(e) =>
            !externalData?.allowMarketingCalls &&
            updateUser({ ...user, allowMarketingCalls: e.target.checked })
          }
        />
      </FormGroup>
    </>
  );
};

export default NotificationSettings;
