//TODO: Do this differently, this was a band-aid when converting to create-react-app from the previous version

const configs = {
  NODE_ENV: process.env.NODE_ENV,
  REACT_APP_MP_API_URL: process.env.REACT_APP_MP_API_URL,
  REACT_APP_MP_COOKIE_DOMAIN: process.env.REACT_APP_MP_COOKIE_DOMAIN,
  REACT_APP_MP_AUTH_COOKIE_KEY: process.env.REACT_APP_MP_AUTH_COOKIE_KEY,
  REACT_APP_MP_LOGO_URL: process.env.REACT_APP_MP_LOGO_URL,
  REACT_APP_MP_NO_IMAGE_URL: process.env.REACT_APP_MP_NO_IMAGE_URL,
  REACT_APP_MP_LOGIN_BACKGROUND_URL:
    process.env.REACT_APP_MP_LOGIN_BACKGROUND_URL,
  REACT_APP_MP_FAVICON_URL: process.env.REACT_APP_MP_FAVICON_URL,
  REACT_APP_MP_ABBREVIATION: process.env.REACT_APP_MP_ABBREVIATION,
  REACT_APP_MP_WEBSITE_URL: process.env.REACT_APP_MP_WEBSITE_URL,
  REACT_APP_MP_PLACEHOLDER_STATE: process.env.REACT_APP_MP_PLACEHOLDER_STATE,
  REACT_APP_MP_PLACEHOLDER_ZIP: process.env.REACT_APP_MP_PLACEHOLDER_ZIP,
  REACT_APP_MP_EMAIL_FROM_NAME: process.env.REACT_APP_MP_EMAIL_FROM_NAME,
  REACT_APP_MP_EMAIL_FROM_ADDRESS: process.env.REACT_APP_MP_EMAIL_FROM_ADDRESS,
  REACT_APP_MP_PRIMARY_COLOR: process.env.REACT_APP_MP_PRIMARY_COLOR,
  REACT_APP_MP_BG_COLOR: process.env.REACT_APP_MP_BG_COLOR,
  REACT_APP_MP_COPYRIGHT_TEXT: process.env.REACT_APP_MP_COPYRIGHT_TEXT,
  REACT_APP_MP_HTML_TITLE: process.env.REACT_APP_MP_HTML_TITLE,
  REACT_APP_MP_SINGLE_REGION: process.env.REACT_APP_MP_SINGLE_REGION,
  REACT_APP_MP_OLD_ROUTING: process.env.REACT_APP_MP_OLD_ROUTING,
  REACT_APP_MP_MARKETING_REPORTS: process.env.REACT_APP_MP_MARKETING_REPORTS,
  REACT_APP_MP_ZIP_CODE_GEO_JSON_URL:
    process.env.REACT_APP_MP_ZIP_CODE_GEO_JSON_URL,
  REACT_APP_MP_GOOGLE_MAPS_API: process.env.REACT_APP_MP_GOOGLE_MAPS_API,
  REACT_APP_MP_TINY_MCE_KEY: process.env.REACT_APP_MP_TINY_MCE_KEY,
  REACT_APP_MP_COMPANY_NAME: process.env.REACT_APP_MP_COMPANY_NAME,
  REACT_APP_MP_LOGIN_LOGO_URL: process.env.REACT_APP_MP_LOGIN_LOGO_URL,
  REACT_APP_MP_REQUEST_TIMEOUT: process.env.REACT_APP_MP_REQUEST_TIMEOUT,
  REACT_APP_MP_ZIP_CODE_GEO_JSON_FOLDER_URL:
    process.env.REACT_APP_MP_ZIP_CODE_GEO_JSON_FOLDER_URL,
  REACT_APP_MP_ZIP_CODE_GEO_JSON_MIN_LON:
    process.env.REACT_APP_MP_ZIP_CODE_GEO_JSON_MIN_LON,
  REACT_APP_MP_ZIP_CODE_GEO_JSON_MIN_LAT:
    process.env.REACT_APP_MP_ZIP_CODE_GEO_JSON_MIN_LAT,
  REACT_APP_MP_ZIP_CODE_GEO_JSON_GRID_SIZE:
    process.env.REACT_APP_MP_ZIP_CODE_GEO_JSON_GRID_SIZE,
  REACT_APP_MP_ZIP_CODE_SHOW_MIN_ZOOM:
    process.env.REACT_APP_MP_ZIP_CODE_SHOW_MIN_ZOOM || 9,
  REACT_APP_MP_ZIP_CODE_SHOW_MAX_ZOOM:
    process.env.REACT_APP_MP_ZIP_CODE_SHOW_MAX_ZOOM || 14,
  REACT_APP_MP_ZIP_CODE_LIST_PROTO:
    process.env.REACT_APP_MP_ZIP_CODE_LIST_PROTO,
  REACT_APP_MP_ZIP_CODE_LIST_BUF: process.env.REACT_APP_MP_ZIP_CODE_LIST_BUF,
  REACT_APP_MP_DRIVER_PICKUP_STATUSES:
    process.env.REACT_APP_MP_DRIVER_PICKUP_STATUSES,
  //override settings here, potentially can come from the API
  // example:
  //REACT_APP_MP_API_URL : 'https://app.morepickups.com';
};

export default configs;

export const REACT_APP_MP_API_URL = process.env.REACT_APP_MP_API_URL;
export const REACT_APP_MP_COOKIE_DOMAIN =
  process.env.REACT_APP_MP_COOKIE_DOMAIN;
export const REACT_APP_MP_AUTH_COOKIE_KEY =
  process.env.REACT_APP_MP_AUTH_COOKIE_KEY;
export const REACT_APP_MP_LOGO_URL = process.env.REACT_APP_MP_LOGO_URL;
export const REACT_APP_MP_NO_IMAGE_URL = process.env.REACT_APP_MP_NO_IMAGE_URL;
export const REACT_APP_MP_LOGIN_BACKGROUND_URL =
  process.env.REACT_APP_MP_LOGIN_BACKGROUND_URL;
export const REACT_APP_MP_LOGIN_BACKGROUND_COLOR =
  process.env.REACT_APP_MP_LOGIN_BACKGROUND_COLOR;
export const REACT_APP_MP_FAVICON_URL = process.env.REACT_APP_MP_FAVICON_URL;
export const REACT_APP_MP_ABBREVIATION = process.env.REACT_APP_MP_ABBREVIATION;
export const REACT_APP_MP_WEBSITE_URL = process.env.REACT_APP_MP_WEBSITE_URL;
export const REACT_APP_MP_PLACEHOLDER_STATE =
  process.env.REACT_APP_MP_PLACEHOLDER_STATE;
export const REACT_APP_MP_PLACEHOLDER_ZIP =
  process.env.REACT_APP_MP_PLACEHOLDER_ZIP;
export const REACT_APP_MP_EMAIL_FROM_NAME =
  process.env.REACT_APP_MP_EMAIL_FROM_NAME;
export const REACT_APP_MP_EMAIL_FROM_ADDRESS =
  process.env.REACT_APP_MP_EMAIL_FROM_ADDRESS;
export const REACT_APP_MP_PRIMARY_COLOR =
  process.env.REACT_APP_MP_PRIMARY_COLOR;
export const REACT_APP_MP_BG_COLOR = process.env.REACT_APP_MP_BG_COLOR;
export const REACT_APP_MP_COPYRIGHT_TEXT =
  process.env.REACT_APP_MP_COPYRIGHT_TEXT;
export const REACT_APP_MP_HTML_TITLE = process.env.REACT_APP_MP_HTML_TITLE;
export const REACT_APP_MP_SINGLE_REGION =
  process.env.REACT_APP_MP_SINGLE_REGION;
export const REACT_APP_MP_OLD_ROUTING = process.env.REACT_APP_MP_OLD_ROUTING;
export const REACT_APP_MP_MARKETING_REPORTS =
  process.env.REACT_APP_MP_MARKETING_REPORTS;
export const REACT_APP_MP_ZIP_CODE_GEO_JSON_URL =
  process.env.REACT_APP_MP_ZIP_CODE_GEO_JSON_URL;
export const REACT_APP_MP_GOOGLE_MAPS_API =
  process.env.REACT_APP_MP_GOOGLE_MAPS_API;
export const REACT_APP_MP_TINY_MCE_KEY = process.env.REACT_APP_MP_TINY_MCE_KEY;
export const REACT_APP_MP_COMPANY_NAME = process.env.REACT_APP_MP_COMPANY_NAME;
export const REACT_APP_MP_LOGIN_LOGO_URL =
  process.env.REACT_APP_MP_LOGIN_LOGO_URL;
export const REACT_APP_MP_REQUEST_TIMEOUT =
  process.env.REACT_APP_MP_REQUEST_TIMEOUT;
export const REACT_APP_MP_DRIVER_PICKUP_STATUSES =
  process.env.REACT_APP_MP_DRIVER_PICKUP_STATUSES;
