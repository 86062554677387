import {
  getCustomerActions,
  getCustomerAction,
  getPickupsActions,
} from "api/users";

export const REQUEST_CUSTOMER_ACTIONS = "REQUEST_CUSTOMER_ACTIONS";
export const RECEIVE_CUSTOMER_ACTIONS = "RECEIVE_CUSTOMER_ACTIONS";
export const REQUEST_PICKUP_ACTIONS = "REQUEST_PICKUP_ACTIONS";
export const RECEIVE_PICKUP_ACTIONS = "RECEIVE_PICKUP_ACTIONS";
export const CLEAR_PICKUP_ACTIONS = "CLEAR_PICKUP_ACTIONS";
export const REQUEST_CUSTOMER_ACTION = "REQUEST_CUSTOMER_ACTION";
export const RECEIVE_CUSTOMER_ACTION = "RECEIVE_CUSTOMER_ACTION";
export const CLEAR_CUSTOMER_ACTIONS = "CLEAR_CUSTOMER_ACTIONS";

// User Actions
export function requestCustomerActions(userId, limit, page) {
  return (dispatch) => {
    dispatch({ type: REQUEST_CUSTOMER_ACTIONS, data: { customerId: userId } });
    getCustomerActions(userId, limit, page)
      .then((res) => dispatch(receiveCustomerActions(res.data)))
      .catch((err) => alert(JSON.stringify(err.response.data)));
  };
}
export function requestPickupsActions(pickupId) {
  return (dispatch) => {
    dispatch({
      type: REQUEST_PICKUP_ACTIONS,
      data: { customerId: pickupId },
    });
    getPickupsActions(pickupId)
      .then((res) => dispatch(receivePickupsActions(res.data)))
      .catch((err) => alert(JSON.stringify(err.response.data)));
  };
}

function receivePickupsActions(data = {}) {
  return {
    type: RECEIVE_PICKUP_ACTIONS,
    actions: data.data,
    receivedAt: Date.now(),
  };
}
function receiveCustomerActions(data = {}) {
  return {
    type: RECEIVE_CUSTOMER_ACTIONS,
    actions: data.data,
    count: data.count,
    pagination: data.pagination,
    receivedAt: Date.now(),
  };
}
export function requestCustomerAction(userId, id) {
  return (dispatch) => {
    dispatch({ type: REQUEST_CUSTOMER_ACTION });
    return getCustomerAction(userId, id)
      .then((res) => dispatch(receiveCustomerAction(res.data)))
      .catch((err) => alert(JSON.stringify(err.response.data)));
  };
}

function receiveCustomerAction(data = {}) {
  return {
    type: RECEIVE_CUSTOMER_ACTION,
    data: data.data,
    receivedAt: Date.now(),
  };
}

export function clearCustomerActions() {
  return {
    type: CLEAR_CUSTOMER_ACTIONS,
  };
}

export function clearPickupActions() {
  return {
    type: CLEAR_PICKUP_ACTIONS,
  };
}
