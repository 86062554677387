import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";

const TableShowMoreCell = ({
  data,
  labelName = "label",
  idOfItemToShowFirst,
  indexOfItemToShowFirst,
}) => {
  const [showAll, setShowAll] = useState(false);

  if (!data || data.length === 0) return <div>-</div>;

  const setFirstItem = () => {
    let firstItem = data[0];
    if (idOfItemToShowFirst) {
      firstItem = data.find((item) => item.id === idOfItemToShowFirst);
    } else if (indexOfItemToShowFirst) {
      firstItem = data[indexOfItemToShowFirst];
    }
    return firstItem[labelName];
  };

  const firstItem = setFirstItem();
  const showMoreIndex = data.length - 1;
  const allItems = data.map((item, index) => {
    if (index === 0) {
      return false;
    } else {
      return <div key={item.id || item[labelName]}>{item[labelName]}</div>;
    }
  });
  const textDefaultStyle = { textAlign: "start" };
  const buttonDefaultProps = {
    style: { padding: "0", border: "0", ...textDefaultStyle, width: "100%" },
    variant: "link",
  };

  return (
    <div>
      <div>{firstItem}</div>
      <Collapse in={showAll}>
        <div>{allItems}</div>
      </Collapse>

      {showAll ? (
        <Button
          {...buttonDefaultProps}
          onClick={() => {
            setShowAll(false);
          }}
        >
          Hide...
        </Button>
      ) : (
        <div>
          {!!showMoreIndex && (
            <Button
              {...buttonDefaultProps}
              onClick={() => {
                setShowAll(true);
              }}
            >
              Show {showMoreIndex} More...
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
export default TableShowMoreCell;
