import React from "react";
import { Link } from "react-router-dom";
import { Badge } from "react-bootstrap";
import { connect } from "react-redux";

import { formatPhone } from "utils/helpers";
import { dateOnly } from "utils/dates";

import {
  updateSearchTab,
  updateSelectedTab,
} from "actions/donor-search.actions";
import Table, {
  checkComponentOriginProp,
  TableShowMoreCell,
} from "components/table";

import PickupStatusBadge from "components/pickup-status-badge";

class UserTable extends React.Component {
  constructor(props) {
    super();
    const columns = this.makeColumns();
    this.state = {
      data: props.data,
      columns,
      selection: props.selected,
      selectAll: false,
      loading: false,
      isSelectedDonor: props.mode === "selected-donor",
    };
  }

  // Manually output the data you want for the table

  makeColumns() {
    const columns = [
      {
        accessor: "fullName",
        Header: "Name",
        // className: "left",
        Cell: (props) =>
          checkComponentOriginProp(props, (original) => (
            <Link to={`/dashboard/donors/${original.id}`}>
              {original.fname} {original.lname}
            </Link>
          )),
      },
      {
        accessor: "phoneNumbers",
        Header: "Phone Numbers",
        Cell: (row) => (
          <div>
            {row.value.map((v, index) => (
              <div key={index}>{formatPhone(v.number)}</div>
            ))}
          </div>
        ),
      },
      {
        accessor: "locations",
        Header: "Addresses",
        Cell: (row) =>
          checkComponentOriginProp(row, (original) => {
            const { pickups } = original;
            const latestPickup = pickups
              ? pickups.reduce(
                  (pickupOne, pickupTwo) =>
                    pickupOne.pickupDate > pickupTwo.pickupDate
                      ? pickupOne
                      : pickupTwo,
                  {}
                )
              : { id: undefined };
            return (
              <TableShowMoreCell
                data={original.locations}
                idOfItemToShowFirst={latestPickup.locationId}
                labelName={"fullAddress"}
              />
            );
          }),
      },
      {
        accessor: "phoneNumber",
        Header: "Login",
        Cell: (row) => <div>{row.value ? row.value : "Guest"}</div>,
      },
      {
        accessor: "email",
        Header: "Email",
        minWidth: "150px",

        Cell: (row) => <div>{row.value}</div>,
      },
      {
        accessor: "updated_at",
        Header: "Latest Pickup",
        Cell: (props) =>
          checkComponentOriginProp(props, (original) => {
            const { pickups } = original;

            const latestPickup = pickups
              ? pickups.reduce(
                  (pickupOne, pickupTwo) =>
                    pickupOne.pickupDate > pickupTwo.pickupDate
                      ? pickupOne
                      : pickupTwo,
                  {}
                )
              : {};
            const pickupStatus =
              this.props.pickupStatuses[latestPickup.statusId];
            const doesUserHaveLatestPickup =
              Object.entries(latestPickup).length > 0;
            const latestPickupDisplay = doesUserHaveLatestPickup ? (
              <React.Fragment>
                {dateOnly(latestPickup.pickupDate)}
                <br />
                <PickupStatusBadge pickupStatus={pickupStatus} />
              </React.Fragment>
            ) : (
              "-"
            );
            return <div>{latestPickupDisplay}</div>;
          }),
      },
    ];
    return columns;
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedUsers.length > 0 &&
      this.props.selectedUsers.length === 0 &&
      this.state.isSelectedDonor
    ) {
      this.props.activateFirstTab();
    }
  }

  handleSelectUsers = (rows) => {
    if (
      rows.length !== this.state.selection.length &&
      !this.state.isSelectedDonor
    ) {
      const selectedUsers = rows.map((row) => {
        return { rowId: row.id, ...row.original };
      });
      this.setState({ selection: selectedUsers });
      // this.handleSelectUsers(selectedUsers);
      this.props.updateSearchTab({
        query: this.props.tab?.query,
        selected: selectedUsers,
      });
    }
  };

  handleOnSelect = (rowSelect) => {
    if (this.state.isSelectedDonor) {
      this.props.updateSelectedTab({
        userToDelete: rowSelect.original,
      });
    }
  };

  handleOnSelectAll = () => {
    if (this.state.isSelectedDonor) {
      this.props.updateSelectedTab({ deleteAll: true });
      this.props.activateFirstTab();
    }
  };

  handleSelectedUsers = () => {
    if (this.state.isSelectedDonor) {
      const updatedSelcted = this.props.selected.map((item, idx) => {
        const updatedItem = { ...item, rowId: idx };
        return updatedItem;
      });
      return updatedSelcted;
    } else {
      return this.props.selected;
    }
  };

  render() {
    const { columns } = this.state;
    const { data } = this.props;
    const selected = this.handleSelectedUsers();

    return (
      <Table
        data={data}
        columns={columns}
        getSelectedFullRow={this.handleSelectUsers}
        selected={selected}
        onSelect={this.handleOnSelect}
        onSelectAll={this.handleOnSelectAll}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  pickupStatuses: state.common.dataMap.pickupStatuses,
  selectedUsers: state.searchCustomers.selectedUsers,
});

const mapDispatchToProps = {
  updateSearchTab,
  updateSelectedTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTable);
