import React, { useState, useEffect } from "react";
import DateDayNav from "components/date-by-day-navigation/date-by-day-navigation.component";
import Tabs from "components/tabs";
import Loader from "components/loader";
import Table, { checkComponentOriginProp } from "components/table";
import PickupStatusBadge from "components/pickup-status-badge";

const Pickups = ({
  date,
  onChange,
  loadingStatus,
  pickups,
  onSelectPickup,
  selectedPickup,
  pickupsDone,
  pickupsUpcoming,
}) => {
  const [showDone, setShowDone] = useState(false);

  const columns = [
    {
      Header: "Pickup #",
      id: "index",
      accessor: (row, i) => i + 1,
    },
    {
      Header: "Pickup Info",
      accessor: "pickupInfo",
      Cell: (props) =>
        checkComponentOriginProp(props, (original) => (
          <div>
            <div>
              <strong>{original.user.fullName}</strong>
            </div>
            <div>{original.location.fullAddress}</div>
            {original.location.unit && <div>{original.location.unit}</div>}
            {original.location.subdivision && (
              <div>{original.location.subdivision}</div>
            )}
          </div>
        )),
    },
    {
      Header: "Status",
      accessor: "pickupStatus",
      Cell: (row) => {
        return (
          <PickupStatusBadge color={row.value.color} label={row.value.label} />
        );
      },
    },
  ];

  return (
    <div
      className={`p-3 pt-4 h-100 driver-pickups-wrapper ${
        !!selectedPickup && "selected"
      }`}
    >
      <DateDayNav
        date={date}
        onChange={onChange}
        containerClassName="routes-date-picker-container"
      />
      <div className="py-3">
        <Tabs fullWidth active={showDone} onChange={setShowDone}>
          <Tabs.Tab badge={pickupsUpcoming.length} value={false}>
            Upcoming
          </Tabs.Tab>
          <Tabs.Tab badge={pickupsDone.length} value={true}>
            Done
          </Tabs.Tab>
        </Tabs>
      </div>
      {loadingStatus ? (
        <Loader />
      ) : pickups.length ? (
        <>
          <Table
            listSelectedId={selectedPickup?.id}
            listSelectMode
            mobileFormat
            onClickRow={({ original }) => {
              onSelectPickup(original);
            }}
            hidePagination
            data={showDone ? pickupsDone : pickupsUpcoming}
            columns={columns}
          />
        </>
      ) : (
        <>No Routes</>
      )}
    </div>
  );
};

export default Pickups;
