import React, { Component } from "react";
import Header from "./header";
// import Footer from "./footer";

const DriverComponent = ({ children }) => {
  return (
    <div id={"driver-page-container"} className="d-flex">
      <Header driverMode />
      <div id={"driver-page-body"} className="flex-fill">
        {children}
      </div>
    </div>
  );
};

export default DriverComponent;
