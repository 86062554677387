import { connect } from "react-redux";
import LeadSourceComponent from "./lead-sources.component";
import { requestCommon } from "actions/common.actions";

const mapStateToProps = (state) => {
  return {
    leadSource: state.common.data.leadSource,
    leadSourceLoading: state.common.isFetching,
  };
};

const mapDispatchToProps = {
  requestCommon,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadSourceComponent);
