import { useRef, useState, useEffect, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import "react-tagsinput/react-tagsinput.css";
import TagsInput from "react-tagsinput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import ConfirmModal from "components/confirm-modal";
import newZoneTemplate from "./newZoneTemplate";
const ZoneDisplay = ({
  zone,
  scratchZone,
  setScratchZone,
  setHighlightedZip,
  modified,
  active,
  setActive,
  validZipCode,
  goToZip,
  createZone,
  updateZone,
  deleteZone,
  newZone,
  region,
  stopsSetting,
  pickupTypes,
}) => {
  // Split a comma separated list of zip codes when it is pasted in the zip codes input
  const pasteSplit = (text) =>
    text
      .split(",")
      .map((zipCode) => zipCode.trim())
      .filter(validZipCode);
  // Render zip codes in the tag input
  // Modified from the default in react-tagsinput
  const renderTag = (props) => {
    const {
      tag,
      key,
      disabled,
      onRemove,
      classNameRemove,
      getTagDisplayValue,
      ...other
    } = props;
    return (
      <span
        key={key}
        onClick={() => goToZip(tag)}
        onMouseEnter={() => setHighlightedZip(tag)}
        onMouseLeave={() => setHighlightedZip(null)}
        style={{ cursor: "pointer" }}
        {...other}
      >
        {getTagDisplayValue(tag)}
        {!disabled && (
          <a
            href={() => false}
            className={classNameRemove}
            onClick={(e) => {
              e.stopPropagation();
              onRemove(key);
            }}
          />
        )}
      </span>
    );
  };
  const [editing, setEditing] = useState(false);
  const nameRef = useRef();
  const handleZipsChange = (zipCodes, changed) => {
    const addedZips = changed.filter(
      (zip) => !scratchZone.zipCodes.includes(zip)
    );
    // All added zips must be valid
    if (addedZips.some((zip) => !validZipCode(zip))) return;
    // None of the added zips can already be in thescratchZone
    if (addedZips.some((zip) => scratchZone.zipCodes.includes(zip))) return;
    // If only one zip code is added, go to it
    if (addedZips.length === 1) goToZip(addedZips[0]);
    setScratchZone({ ...scratchZone, zipCodes });
  };

  return (
    <div
      className={`zip-code-zone ${active ? "active" : ""}`}
      onClick={setActive}
    >
      <div className="zip-code-zone-header">
        <div
          className="zip-code-zone-color-swatch"
          style={{ backgroundColor: `#${scratchZone.color}` }}
        ></div>
        <Form.Control
          type="text"
          value={scratchZone.name}
          className="zip-code-zone-name"
          onChange={(e) =>
            setScratchZone({ ...scratchZone, name: e.target.value })
          }
          onBlur={() => setEditing(false)}
          placeholder={newZone ? "New Zone" : ""}
          readOnly={!newZone && !editing}
          plaintext={!newZone && !editing}
          ref={nameRef}
        />
        {!newZone && (
          <div
            className="zip-code-zone-edit-name-button"
            onClick={() => {
              nameRef.current.focus();
              setEditing(true);
            }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </div>
        )}
      </div>
      <div className="zip-code-zone-accordian-body">
        <TagsInput
          value={scratchZone.zipCodes}
          onChange={handleZipsChange}
          addOnPaste={true}
          pasteSplit={pasteSplit}
          renderTag={renderTag}
          inputProps={{
            placeholder: "Add a zipcode",
          }}
        />
        <Form.Group style={{ marginBottom: "10px" }}>
          <Form.Label style={{ fontWeight: "initial" }}>
            Default Stops
          </Form.Label>
          <Form.Control
            type="number"
            value={scratchZone.defaultBlockStops}
            className="zip-code-zone-stops"
            onChange={(e) =>
              setScratchZone({
                ...scratchZone,
                defaultBlockStops: parseInt(e.target.value),
              })
            }
          />
        </Form.Group>
        <Form.Group style={{ marginBottom: "10px" }}>
          <Form.Label style={{ fontWeight: "initial" }}>Pickup type</Form.Label>
          <Form.Control
            as="select"
            value={scratchZone.pickupTypeId}
            onChange={(e) =>
              setScratchZone({ ...scratchZone, pickupTypeId: e.target.value })
            }
            custom
          >
            <option value={0} key={0}>
              Select
            </option>

            {pickupTypes.map((category) => (
              <option value={category.id} key={category.id}>
                {category.label}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <div className="zip-code-zone-buttons">
          <Button
            onClick={() => {
              if (newZone) {
                createZone({
                  ...scratchZone,
                  id: undefined,
                  regionId: region.id,
                  zipCodes: scratchZone.zipCodes.map((zip) => ({ zip })),
                });
                setScratchZone(newZoneTemplate(stopsSetting));
              } else
                updateZone(zone.id, {
                  ...scratchZone,
                  zipCodes: scratchZone.zipCodes.map((zip) => ({ zip })),
                });
            }}
            disabled={!modified || scratchZone.name === ""}
          >
            Save
          </Button>
          <Button
            onClick={() =>
              setScratchZone(newZone ? newZoneTemplate(stopsSetting) : zone)
            }
            disabled={!modified}
          >
            Cancel
          </Button>
          {newZone ? (
            <Button disabled>Delete</Button>
          ) : (
            <ConfirmModal
              onConfirm={() => deleteZone(zone.id)}
              confirmText="Are you sure you want to permanently delete this zone?"
              buttonText="Delete"
            />
          )}
        </div>
      </div>
    </div>
  );
};
const ZonesDisplay = ({
  zones,
  activeZoneId,
  setActiveZoneId,
  scratchZones,
  setScratchZones,
  modifiedZones,
  ...rest
}) => {
  const newZone = scratchZones.find(({ id }) => id === -1);
  return (
    <div id="zip-code-zones">
      {zones.map((zone, idx) => {
        const scratchZone = scratchZones.find(({ id }) => id === zone.id);
        if (!scratchZone) return null;
        return (
          <ZoneDisplay
            key={idx}
            zone={zone}
            scratchZone={scratchZone}
            setScratchZone={(newScratchZone) => {
              setScratchZones([
                ...scratchZones.filter(({ id }) => newScratchZone.id !== id),
                newScratchZone,
              ]);
            }}
            modified={modifiedZones.has(zone.id)}
            active={activeZoneId === zone.id}
            setActive={() => setActiveZoneId(zone.id)}
            newZone={false}
            {...rest}
          />
        );
      })}
      <ZoneDisplay
        zone={{}}
        scratchZone={newZone}
        setScratchZone={(scratchZone) => {
          setScratchZones([
            ...scratchZones.filter(({ id }) => scratchZone.id !== id),
            scratchZone,
          ]);
        }}
        modified={modifiedZones.has(-1)}
        active={activeZoneId === -1}
        setActive={() => setActiveZoneId(-1)}
        newZone={true}
        {...rest}
      />
    </div>
  );
};
export default ZonesDisplay;
