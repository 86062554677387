import React from "react";
import { Badge } from "react-bootstrap";
import { getTextColor } from "utils/helpers";

const PickupStatusBadge = ({
  color: colorProp,
  label: labelProp,
  pickupStatus,
}) => {
  const color = pickupStatus?.color || colorProp;
  const label = pickupStatus?.label || labelProp;
  return (
    <Badge
      ref={(ref) => {
        ref && ref.style.setProperty("background-color", color, "important");
      }}
      bg={"primary"}
      style={{
        backgroundColor: color,
        borderColor: color,
        color: getTextColor(color),
      }}
    >
      <span style={{ fontSize: "1rem" }}>{label}</span>
    </Badge>
  );
};

export default PickupStatusBadge;
