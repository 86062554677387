import React from "react";
import Modal from "components/modal";
import List from "components/list";

class EditableRecords extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // The actively selected record if any
      editRecord: null,
      displayForm: false,
      isEdit: false,
      showArchived: false,
    };
  }
  editRecord = (record) => {
    this.setState({ displayForm: true, isEdit: true, editRecord: record });
  };
  createNew = () => {
    this.setState({ displayForm: true, isEdit: false, editRecord: null });
  };
  closeForm = () => {
    this.setState({ displayForm: false });
  };

  componentDidMount() {
    if (this.props.initialOpenNew)
      this.setState({
        displayForm: true,
      });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.initialOpenNew && this.props.initialOpenNew)
      this.setState({
        displayForm: true,
      });
  }

  render() {
    const {
      name,
      objectArray,
      DisplayComponent,
      displayFunction,
      EditComponent,
      NewSubmit,
      NewComponent,
      ownerId,
      formProps = {},
      actions,
      setRecord,
    } = this.props;

    const hasArchived = objectArray.some((x) => x.disabled);

    return (
      <List className={"list-group-with-edit"}>
        {NewSubmit ? (
          <List.New name={name} onClick={() => this.createNew()} />
        ) : null}

        <Modal
          show={this.state.displayForm}
          onHide={this.closeForm}
          title={`${this.state.isEdit ? "Edit" : "New"} ${name}`}
        >
          {this.state.isEdit ? (
            <EditComponent
              initialValues={this.state.editRecord}
              onCancel={this.closeForm}
              onSubmit={this.props.EditSubmit}
              onSubmitSuccess={this.closeForm}
              {...formProps}
            />
          ) : (
            NewComponent && (
              <NewComponent
                userId={ownerId}
                customerId={ownerId}
                onCancel={this.closeForm}
                onSubmit={this.props.NewSubmit}
                onSubmitSuccess={this.closeForm}
                {...formProps}
              />
            )
          )}
        </Modal>

        {objectArray
          .sort((a, b) => {
            if (a.createdAt > b.createdAt) {
              return -1;
            }

            return 1;
          })
          .sort((x, y) => {
            return x.disabled - y.disabled;
          })
          .filter((x) => this.state.showArchived || !x.disabled)
          .map((record, i) => {
            return (
              <List.Item
                key={i}
                onClick={() => {
                  if (setRecord) {
                    setRecord(this.editRecord, record.id);
                  } else {
                    this.editRecord(record);
                  }
                }}
                {...(displayFunction ? displayFunction(record) : {})}
              >
                {DisplayComponent && <DisplayComponent record={record} />}
              </List.Item>
            );
          })}

        {actions &&
          actions.map(({ label, badge, handler }, idx) => (
            <List.Item
              key={idx}
              title={label}
              badge={badge}
              onClick={handler}
            />
          ))}

        {hasArchived && (
          <List.Item
            title={`${this.state.showArchived ? "Hide" : "View"} Archived`}
            badge={
              this.state.showArchived
                ? null
                : `+${objectArray.filter((obj) => obj.disabled).length}`
            }
            onClick={() =>
              this.setState({ showArchived: !this.state.showArchived })
            }
          />
        )}
      </List>
    );
  }
}

export default EditableRecords;
