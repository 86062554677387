import moment from "moment";

/*
 *
 * Date Helpers
 *
 *
 * */

export const getStartOrEndISOS = (newDate, isMorning = true) => {
  return moment(newDate)[isMorning ? "startOf" : "endOf"]("day").toISOString();
};

/*
 Specifically used for formatting dates to send in requests
 example: 
 from Fri Mar 03 2023 00:00:00 GMT+0200 to 2023-03-03T00:00:00.000Z 
 sets timezone to 0 (GMT+0000)
*/
export const getDateWithoutTimeZone = (newDate, isMorning = true) => {
  const date = new Date(newDate);
  return moment(newDate)
    [isMorning ? "startOf" : "endOf"]("day")
    .add(-date.getTimezoneOffset(), "m")
    .toISOString();
};

/*
 types
 options: ['isDate', 'isIso', 'isShortFormat']
 handlers: {onFail: (errors)=>{}, onSuccess: (date)=>{}}
*/
export const checkDate = (date, options, handlers) => {
  const d = moment(date);
  const errors = [];

  const selectCheckFunc = (key) => {
    switch (key) {
      case "isDate":
        if (!d.isValid()) errors.push(key);
        break;
      case "isIso":
        if (!moment(date, "YYYY-MM-DDTHH:mm:ss.sssZ", true).isValid())
          errors.push(key);
        break;
      case "isShortFormat":
        // xx-xx-xxxx || xxxx-xx-xx
        const datePattern =
          /^((\d{2}[\s\S]\d{2}[\s\S]\d{4})|((\d{4}[\s\S])|(\d{2}[\s\S])\d{2}[\s\S]\d{2}))$/;
        if (!datePattern.test(date)) {
          errors.push(key);
        }
        break;
      default:
        break;
    }
  };

  options.map(selectCheckFunc);

  if (errors.length > 0) {
    handlers.onFail && handlers.onFail(errors);
    return errors;
  }
  handlers.onSuccess && handlers.onSuccess(date);
  return date;
};

export const getDateWithoutTimeConvertUtc = (newDate) => {
  return moment.utc(newDate).startOf("day").toDate();
  // return moment().utc(newDate).startOf("day").toISOString();
};

export const getDate = (newDate) => {
  const date = new Date(newDate);
  let setTimeToZero = false;
  checkDate(newDate, ["isShortFormat"], {
    onSuccess: () => {
      //disabled for testing
      // setTimeToZero = true;
    },
  });
  return moment(date)
    .add(setTimeToZero ? 0 : -date.getTimezoneOffset(), "m")
    .toISOString();
};

export const getDateUtcOffset = (newDate, offset) => {
  return moment(newDate).startOf("day").utcOffset(offset).toISOString();
};
export const getDateUtcOffsetEndOfDay = (newDate, offset) => {
  return moment(newDate).endOf("day").utcOffset(offset).toISOString();
};

export const dateOnly = (date) => {
  return moment(date).utc().format("l");
};

// Used for formatting the dates for use in queryStrings
export const formatQueryDate = (date, isMorning) =>
  getDateWithoutTimeZone(date, isMorning);

export const formatDateHyphenatedYearFirst = (date, utc = false) =>
  (utc ? moment.utc : moment)(date).format("YYYY-MM-DD");

export const formatDateHyphenatedYearFirstUtc = (date) =>
  moment.utc(date).format("YYYY-MM-DD");

export const formatWeekdayHyphenDate = (date) =>
  moment(date).utc().format("dddd - MM/DD/YY");

export const formatDateParenWeekday = (date) =>
  moment(date).utc().format("MM/DD/YY (dddd)");

export const formatLongDateParenWeekday = (date) =>
  moment(date).format("MMM D, YYYY (dddd)");

// Used in the filenames of exported csv files
export const csvFileDate = (date) => {
  return moment(date).format("MM-DD-YY");
};

// example: 12:22 PM
export const getFormatedTime = (time) => {
  return moment(time).utc().format("h:mm A");
};

// Used in userActions to format the createdAt/updatedAt in the json
// example: Thursday - 02/03/2022, 01:41 pm
export const formatTime = (time) => {
  return moment(time).format("dddd - MM/DD/YYYY, hh:mm a");
};

export const customFormat = (date, format) => {
  return moment(date).format(format);
};

export const getTomorrow = (date) => {
  return moment.utc(date).add(1, "days").toDate();
};

export const getToday = () => {};

export const getYesterday = (date) => {
  return moment.utc(date).subtract(1, "days").toDate();
};

export const checkForValidDate = ({ date }) => {
  return moment(date).isValid();
};

export const lastWeek = (time) => {
  return moment().subtract(7, "days").toDate();
};

export const displayPickupBlockDate = (time) => {
  return moment.utc(time).format("dddd, MMMM Do, YYYY");
};

export const toDate = (date, fromISO) => {
  // set fromISO true if date is ISO type  and it should be converted
  // to default type without offset in time

  // FOR EXAMPLE: date is 2023-03-11T00:00:00.000Z
  // RESULTS:
  // fromISO false: Fri Mar 10 2023 18:00:00 GMT-0600 (Central Standard Time)
  // fromISO true: Sat Mar 11 2023 00:00:00 GMT-0600 (Central Standard Time)

  const d = new Date(date);
  if (fromISO) {
    return moment(date).add(+d.getTimezoneOffset(), "m").toDate();
  }
  return moment(date).toDate();
};

export const utcOffset = (date) => {
  return moment(date).utcOffset();
};

export const addAmountToDate = (date, offset, unit) => {
  return moment(date).add(offset, unit);
};

export const startOf = (date, startOf = "day") => {
  return moment(date).startOf(startOf);
};

export const isSame = (date, compareDate, startOf = "day") => {
  return moment(date).isSame(compareDate, startOf);
};
export const isBefore = (currentDate) => {
  return moment(currentDate).isBefore(new Date());
};

// Converts 24 hour time from Routific to a 12 hours readable time
// Used
export const toMeridiemTime = (time) => {
  const splitTime = time.split(":");
  const hour = splitTime[0];
  const minute = splitTime[1];
  let timeOfDay = "";
  let displayHour = splitTime[0];

  if (hour > 11) {
    timeOfDay = "pm";
    displayHour = hour - 11;
  } else {
    timeOfDay = "am";
  }

  return `${displayHour}:${minute} ${timeOfDay}`;
};

export const iso8601Pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
