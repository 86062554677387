import React, { useEffect, useContext, useState } from "react";
import RouteTimesEstimateForm from "forms/create-update-time-windows";
import { showToast } from "actions/toast.actions";
import Loader from "components/loader";
import PageLayout from "../../layouts/pages/page-default";
import { PickupTypesContext } from "contexts/pickupTypes";
import Tabs from "components/tabs";

const TimeWindows = ({
  isLoading,
  updateSetting,
  globalSettings,
  pickupTypes,
}) => {
  const [currentTab, setCurrentTab] = useState(null);

  useEffect(() => {
    if (pickupTypes.length > 0 && !currentTab) {
      setCurrentTab(pickupTypes[0].label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickupTypes, currentTab]);

  const handleSubmit =
    (type) =>
    async ({ pickupWindows }) => {
      try {
        await updateSetting({
          label: `${type}-time-window`,
          setting: pickupWindows,
        });
        showToast({ message: "Update Successful" });
      } catch (err) {
        showToast({ message: `Update Failed: ${err}`, type: "error" });
      }
    };

  const getTimeWindows = (type) => {
    const foundFurnitureTimeWindows =
      globalSettings &&
      globalSettings.find((setting) => setting.label === `${type}-time-window`);
    return foundFurnitureTimeWindows ? foundFurnitureTimeWindows.value : [];
  };

  return (
    <PageLayout title="Time Windows">
      <Tabs active={currentTab} onChange={setCurrentTab}>
        {pickupTypes.map((type) => (
          <Tabs.Tab key={type.label} value={type.label}>
            {type.label}
          </Tabs.Tab>
        ))}
      </Tabs>
      {pickupTypes.map((type) => (
        <div
          className={currentTab !== type.label ? "d-none" : ""}
          key={type.label}
          style={{ marginBottom: "3em" }}
        >
          <div>
            {isLoading ? (
              <Loader width={"100px"} color={"#333333"} />
            ) : (
              <RouteTimesEstimateForm
                initialValues={{
                  pickupWindows: getTimeWindows(type.label.toLocaleLowerCase()),
                }}
                form={`${type.label.toLocaleLowerCase()}PickupWindows`}
                onSubmit={handleSubmit(type.label.toLocaleLowerCase())}
              />
            )}
          </div>
        </div>
      ))}
    </PageLayout>
  );
};

export default TimeWindows;
