import {
  RECEIVE_PICKUP_ACTIONS,
  REQUEST_PICKUP_ACTIONS,
  CLEAR_PICKUP_ACTIONS,
} from "./customer-timeline.actions";

const defaultPickupActionState = {
  isFetching: false,
  loaded: false,
  actions: [],
};
function pickupActions(state = defaultPickupActionState, action) {
  switch (action.type) {
    case REQUEST_PICKUP_ACTIONS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_PICKUP_ACTIONS:
      return {
        ...state,
        loaded: true,
        isFetching: false,
        actions: [...state.actions, ...action.actions],
        lastUpdated: action.receivedAt,
      };
    case CLEAR_PICKUP_ACTIONS:
      return {
        ...defaultPickupActionState,
      };

    default:
      return state;
  }
}

export default pickupActions;
