import { connect } from "react-redux";
import { getFormValues } from "redux-form";

import {
  requestPickupBlocks,
  clearPickupBlocks,
} from "actions/pickup-blocks.actions";

import {
  createPickup,
  requestPickups,
  requestPickupsForDonor,
  updatePickup,
  clearPickups,
  requestPickup,
} from "./pickups.actions";

import { requestCustomerActions } from "../donor-timeline/customer-timeline.actions";

import DonorPickups from "./donor-pickups.component";

function mapStateToProps(state) {
  // getLocationsIndedx() => {
  // 	let obj = {}
  // 	state.locations.forEach((location, index) => {
  // 		obj[location.id] = index;
  // 	})
  // 	return obj
  // },
  const pickupStatusesMap = state.common.dataMap.pickupStatuses;
  const pickups = [...state.pickups.minPickupsDonorPage.pickups].map(
    (pickup) => {
      return { ...pickup, pickupStatus: pickupStatusesMap[pickup.statusId] };
    }
  );
  return {
    pickupForm: getFormValues("NewPickup")(state),
    pickups: pickups,
    currentPickup: state.pickups.currentPickup,
    isFetching: state.pickups.minPickupsDonorPage.isFetching,
    isLoaded: state.pickups.minPickupsDonorPage.loaded,
    pickupBlocks: {
      ...state.pickupBlocks,
    },
    regions: {
      ...state.regions,
    },
    locations: {
      ...state.locations,
    },
    pickupStatusesMap: state.common.dataMap.pickupStatuses,
    customerActionsIsLoaded: state.customerActions.loaded,
  };
}

export default connect(mapStateToProps, {
  updatePickup,
  createPickup,
  requestPickups,
  requestPickupBlocks,
  requestCustomerActions,
  requestPickupsForDonor,
  requestPickup,
  clearPickupBlocks,
  clearPickups,
})(DonorPickups);
