import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Icon from "../icon";
import { Badge, Button } from "react-bootstrap";

const TableAction = ({ id, text, icon, onClick, count }) => (
  <OverlayTrigger
    placement={"top"}
    overlay={<Tooltip id={`tooltip-${id}`}>{text}</Tooltip>}
  >
    <Button onClick={onClick} className={"clickable btn btn-light me-2"}>
      <Icon type={icon} />
      {!!count && (
        <Badge
          style={{
            position: "absolute",
            left: "12px",
            top: "12px",
            borderRadius: "30px",
          }}
          variant="primary"
        >
          {count > 99 ? "+99" : count}
        </Badge>
      )}
    </Button>
  </OverlayTrigger>
);

const RowActions = ({ id, actions }) => (
  <>
    {actions.map((action, idx) => (
      <TableAction
        key={idx}
        id={id}
        text={action.text}
        icon={action.icon}
        onClick={action.onClick}
        count={action.count}
      />
    ))}
  </>
);

export default RowActions;
