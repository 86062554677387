import React, { memo, useEffect, useMemo, useState } from "react";
import { ListGroupItem } from "react-bootstrap";
import {
  useTable,
  useFilters,
  useRowSelect,
  usePagination,
  useSortBy,
} from "react-table";
import Icon from "components/icon";
import { Button } from "react-bootstrap";

// import "react-table/react-table.css";
import Loader from "components/loader";

/* 
 Filter/ Search function
 Currently appears in the header row as a plain input field. It filters with each key stroke
* 
* 
* */
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  return (
    <input
      className="rt-filter form-control form-control-sm"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={``}
    />
  );
}

//
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

/*
 Table Component
 */

const Table = ({
  data: tableData = [],
  columns: tableColumns = [],
  actions = [],
  loading = false,
  getSelected,
  getSelectedFullRow,
  onSelect,
  onSelectAll,
  selected,
  pageSize: pageSizeToSet,
  hidePagination,
  onClickRow,
  listSelectedId,
  listSelectMode,
  ...rest
}) => {
  /*
   *
   * Memoize and assign to "tableData"
   * The data that has been passed to the Table component via the "data" prop
   * useMemo is a React Hook that lets you cache the result of a calculation between re-renders.
   *
   *
   * */
  const data = useMemo(() => tableData, [tableData]);

  /*
   *
   * Define Columns
   *
   * */
  const columns = useMemo(
    () => [
      ...tableColumns,
      ...actions.map(({ Header, Component }) => ({
        accessor: Header.toLowerCase(),
        id: Header.toLowerCase(),
        Header,
        Cell: (row) => <Component {...row} />,
      })),
    ],
    [tableColumns, actions]
  );

  //default options for columns
  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
      disableFilters: true,
      // disableSortBy: true,
    }),
    []
  );

  const selectedItems = useMemo(() => selected, [selected]);
  // const pageSizeToSet = useMemo(() => pageSize, [pageSize]);
  const defaultPageSize = 20;

  /*
   *
   * useTable hook from react-table
   * useTable allows us to fetch data according to the sorter, filter, and pagination states.
   * Fetch in our case mean sorting data that has already been retrieved from the server.
   *   *
   * */
  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetHiddenColumns: false, // are required, without it in some tables selecting check box will make a lot of renders
      autoResetSortBy: false, // are required, without it in some tables selecting check box will make a lot of renders
      initialState: { pageSize: defaultPageSize },
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        const allColumns = [...columns];

        if (getSelected || getSelectedFullRow) {
          allColumns.unshift({
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps, selectedFlatRows }) => {
              const onChange = (e) => {
                getToggleAllRowsSelectedProps().onChange(e);
                onSelectAll && onSelectAll(selectedFlatRows);
              };
              const updatedToggleSelectedProps = {
                ...getToggleAllRowsSelectedProps(),
                onChange,
              };
              return <IndeterminateCheckbox {...updatedToggleSelectedProps} />;
            },
            Cell: ({ row }) => {
              const onChange = (e) => {
                row.getToggleRowSelectedProps().onChange(e);
                onSelect && onSelect(row);
              };
              const updatedToggleRowSelectedProps = {
                ...row.getToggleRowSelectedProps(),
                onChange,
              };
              return (
                <IndeterminateCheckbox {...updatedToggleRowSelectedProps} />
              );
            },
          });
        }

        return allColumns;
      });
    }
  );

  const {
    //---main table
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    //---selecting checkbox
    selectedFlatRows,
    toggleRowSelected,
    //---pagination
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  //return selected rows
  useEffect(() => {
    if (selectedFlatRows.length) {
      if (getSelected) {
        getSelected(selectedFlatRows.map((row) => row.original));
      }
      if (getSelectedFullRow) {
        getSelectedFullRow(selectedFlatRows.map((row) => row));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFlatRows]);

  //setPageSize
  useEffect(() => {
    if (pageSizeToSet > 0) {
      setPageSize(pageSizeToSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSizeToSet]);

  useEffect(() => {
    selectedItems?.length > 0 &&
      selectedItems.map((item) => {
        toggleRowSelected(item.rowId, true);
        return item;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);
  const onChangePagination = (e) => {
    const page = e.target.value ? Number(e.target.value) - 1 : 0;
    gotoPage(page);
  };

  const handleClickRow = (row) => {
    if (onClickRow) {
      onClickRow(row);
    }
  };

  const renderPagination = (pageCount) => {
    return Array.from({ length: pageCount }, (_, index) => (
      <li className="page-item">
        <a
          className={`page-link ${pageIndex === index ? "disabled" : ""}`}
          onClick={() => {
            gotoPage(index + 1);
          }}
          href="#"
        >
          {index + 1}
        </a>
      </li>
    ));
  };

  const Cell = ({ cell, amountTruncatedColumns }) => {
    const [truncate, setTruncate] = useState(null);

    useEffect(() => {
      setTruncate(!!cell.column.truncated);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClickTruncated = () => {
      if (!!cell.column.truncated) {
        setTruncate((prev) => !prev);
      }
    };
    if (!!cell.column.truncated) {
      const widthCell = 30 / (amountTruncatedColumns || 1);
      return (
        <td className="tc text-nowrap align-middle" {...cell.getCellProps()}>
          <div className="truncated-wrapper">
            <div
              onClick={onClickTruncated}
              style={{ width: `${widthCell}vw` }}
              className={`truncated-box ${truncate ? "active" : "unactive"}`}
            >
              <span className={"text"}>{cell.render("Cell")}</span>
            </div>
          </div>
        </td>
      );
    }

    return (
      <td className="tc text-nowrap align-middle" {...cell.getCellProps()}>
        {cell.render("Cell")}
      </td>
    );
  };

  /*
   *
   * Remder Table Component
   * */
  return (
    //pc 1le
    <>
      <div className="table-wrapper table-responsive table-bordered">
        <table className="table  table-hover" {...getTableProps()}>
          {loading ? (
            <Loader />
          ) : (
            <>
              {headerGroups.map((headerGroup) => (
                <tr
                  className="tr bg-white"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <td
                      className="rt-th text-nowrap"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div className="rt-th-div">
                        <div className="rt-th-text">
                          {column.render("Header")}{" "}
                          <span className={"rt-th-sort ms-2"}>
                            {
                              //TODO: update with new icons
                              column.isSorted ? (
                                column.isSortedDesc ? (
                                  <Icon type="sortDesc" />
                                ) : (
                                  <Icon type="sortAsc" />
                                )
                              ) : (
                                <Icon type="sortable" />
                              )
                            }
                          </span>
                        </div>
                        <div>
                          {column.canFilter ? column.render("Filter") : null}
                        </div>
                      </div>
                    </td>
                  ))}
                </tr>
              ))}

              <tbody className="rt-tbody" {...getTableBodyProps()}>
                {page.map((row, idx) => {
                  prepareRow(row);
                  let rowClassName;
                  if (listSelectMode) {
                    rowClassName = "rt-tr list-select-mode";
                    if (listSelectedId === row.original.id) {
                      rowClassName += "selected";
                    }
                  }

                  const amountTruncatedColumns = row.cells.filter((cell) => {
                    if (cell.column.truncated) {
                      return true;
                    }
                    return false;
                  }).length;
                  return (
                    <tr
                      onClick={() => handleClickRow(row)}
                      className={`tr ${rowClassName} ${
                        onClickRow && "clickable"
                      }`}
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell, idx) => (
                        <Cell
                          key={`${cell.row.id}${idx}`}
                          amountTruncatedColumns={amountTruncatedColumns}
                          cell={cell}
                        />
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </>
          )}
        </table>
      </div>
      {!loading && !hidePagination && pageOptions.length > 1 && (
        //pagination
        <div className="Page navigation">
          <ul className="pagination">
            <li className="page-item">
              <a
                className={`page-link ${!canPreviousPage ? "disabled" : ""}`}
                href="#"
                onClick={() => {
                  previousPage();
                }}
              >
                Previous
              </a>
            </li>

            {renderPagination(pageCount)}

            <li className="page-item">
              <a
                className={`page-link ${!canNextPage ? "disabled" : ""}`}
                href="#"
                onClick={() => {
                  nextPage();
                }}
              >
                Next
              </a>
            </li>
          </ul>
          <div className="left"></div>
          <div className="center">
            <div className="pages d-none">
              <span>
                Page {pageIndex + 1} of {pageOptions.length}
                <span>
                  <input
                    className="page-input form-control form-control-sm"
                    type="number"
                    value={pageIndex + 1}
                    onChange={onChangePagination}
                  />{" "}
                </span>
              </span>
            </div>
            <div className="page-size">
              <select
                className="form-control form-control-sm"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}
      <div className="mobile-table d-none">
        {data ? (
          data.map((row, idx) => (
            <ListGroupItem key={idx}>
              {columns.map(({ Header, accessor, Cell }, idx) => (
                <div key={idx}>
                  <strong>{Header}: </strong>
                  <span>
                    {Cell ? (
                      <Cell value={row[accessor]} original={row} />
                    ) : (
                      row[accessor]
                    )}
                  </span>
                </div>
              ))}
            </ListGroupItem>
          ))
        ) : (
          <span>No Results</span>
        )}
      </div>
    </>
  );
};

export default memo(Table);
