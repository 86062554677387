import React, { useContext, useState } from "react";
import { email, passwordValidation, requiredDropdown } from "utils/validators";
import { UserContext } from "contexts/user";
import Form from "../context-form";
import EditUserProfileImage from "../edit-user-profile-image";
import { createUser } from "api/users";

const CreateUpdateUser = ({
  editing,
  initialValues,
  regions,
  disabledRegions,
  onCancel,
  onSubmitSuccess,
  selectedRegionId,
}) => {
  const {
    user,
    userLoading,
    updateUser,
    changeProfileImage,
    resetProfileImage,
  } = useContext(UserContext);

  const [loading, setLoading] = useState(false);

  const changeNullRegionId = (data) => {
    if (data.regionId === null) {
      return { ...data, regionId: 0 };
    }
    return data;
  };
  return (
    <Form
      initialValues={changeNullRegionId(editing ? user : initialValues)}
      onSubmit={
        editing
          ? updateUser
          : async (user) => {
              try {
                setLoading(true);
                if (parseInt(user.userTypeId) !== 3) delete user.regionId;
                await createUser(user);
                setLoading(false);
              } catch (error) {
                setLoading(false);
              }
            }
      }
      submitting={userLoading || loading}
      onSubmitSuccess={onSubmitSuccess}
      onCancel={onCancel}
    >
      {editing && (
        <EditUserProfileImage
          user={user}
          changeProfileImage={changeProfileImage}
          resetProfileImage={resetProfileImage}
        />
      )}

      <Form.Field
        name="fname"
        type="text"
        label="First Name"
        options={{ required: "First name is required" }}
        placeholder="John"
      />
      <Form.Field
        name="lname"
        type="text"
        label="Last Name"
        options={{ required: "Last name is required" }}
        placeholder="Doe"
      />
      <Form.Field
        name="email"
        type="email"
        label="Email"
        placeholder="test@test.com"
        options={{ required: "Email is required", validate: email }}
      />
      <Form.Field
        name="userTypeId"
        label="UserType"
        optionField="label"
        selectOptions={[
          { id: 1, label: "admin" },
          { id: 2, label: "office" },
          { id: 3, label: "driver" },
        ]}
        type="select"
        options={{ required: "User type is required" }}
      />

      {!editing && (
        <Form.Field
          name="password"
          type="password"
          label="Password"
          placeholder="******"
          options={{
            required: "Password is required",
            validate: passwordValidation,
          }}
        />
      )}

      <Form.Watch name="userTypeId">
        {(userTypeId) =>
          parseInt(userTypeId) === 3 && (
            <Form.Field
              name="regionId"
              label="Region"
              selectOptions={[
                ...regions,
                ...disabledRegions.filter(
                  (dRegion) => user?.regionId === dRegion.id
                ),
                { id: 0, label: "For all regions" },
              ]}
              optionField="label"
              type="select"
              defaultValue={selectedRegionId ? selectedRegionId : 0}
              options={{
                validate: requiredDropdown,
              }}
            />
          )
        }
      </Form.Watch>

      {editing && (
        <Form.Field name="disabled" label="Disabled" type="checkbox" />
      )}
    </Form>
  );
};

export default CreateUpdateUser;
