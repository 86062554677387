import React, { Component } from "react";
import moment from "moment";
import { getDate } from "utils/dates";
import DateDayNav from "components/date-by-day-navigation/date-by-day-navigation.component";
import { Form } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import Box from "components/box";
import LabelWithCount from "components/label-with-count-box";
import CountPlusMinusWithProgressBar from "pages/regions/pickup-blocks/count-with-progress";
import Loader from "components/loader";

class PickupBlocks extends Component {
  constructor(props) {
    super(props);

    const date = moment();

    this.state = {
      date: date,
      type: 0,
      pickupBlocksUpdateOffsets: {},
    };
  }

  pickupBlocksUpdateTimeouts = {};
  pickupBlocksUpdateOffsets = {};
  setPickupBlockUpdateTimeout = (pickupBlock) => {
    if (this.pickupBlocksUpdateTimeouts[pickupBlock.id])
      clearTimeout(this.pickupBlocksUpdateTimeouts[pickupBlock.id]);

    this.pickupBlocksUpdateTimeouts[pickupBlock.id] = setTimeout(() => {
      this.props.updatePickupBlock({
        ...pickupBlock,
        stops:
          pickupBlock.stops +
          this.state.pickupBlocksUpdateOffsets[pickupBlock.id],
      });

      this.pickupBlocksUpdateTimeouts[pickupBlock.id] = null;
      this.setState({
        pickupBlocksUpdateOffsets: {
          ...this.state.pickupBlocksUpdateOffsets,
          [pickupBlock.id]: 0,
        },
      });
    }, 1000);
  };

  onDateChange = (newDate) => {
    const { requestPickupBlocks, requestPickups, requestStats, regionId } =
      this.props;

    const formatedDate = getDate(newDate);

    requestPickupBlocks({
      date: formatedDate,
      pickupTypeId: this.state.type ? this.state.type : undefined,
      regionId,
    });
    requestPickups({
      pickupDate: formatedDate,
      regionId,
      pickupBlockId: null,
      searchType: "blocks",
    });
    requestStats(formatedDate, formatedDate, {
      pickupTypeId: this.state.type,
      regionId,
    });

    this.setState({ date: moment(newDate) });
  };

  onTypeChange = (e) => {
    const newType = e.target.value;
    const { requestStats, regionId, requestPickupBlocks } = this.props;

    const formatedDate = getDate(this.state.date);
    requestStats(formatedDate, formatedDate, {
      pickupTypeId: newType,
      regionId,
    });

    requestPickupBlocks({
      date: formatedDate,
      pickupTypeId: newType ? newType : undefined,
      regionId,
    });

    this.setState({ type: newType });
  };

  alphabetize = (a, b) => {
    a = a.zone.name.toLowerCase();
    b = b.zone.name.toLowerCase();

    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };

  render() {
    const { pickupBlocks } = this.props.pickupBlocks;
    const { pickups } = this.props.unnassignedPickups;
    const pickupBlocksAlphabetical = pickupBlocks.sort((a, b) =>
      this.alphabetize(a, b)
    );
    const { stats } = this.props;

    const statusStats = {
      isFetching: this.props.stats.isFetching,
      loaded: this.props.stats.loaded,
    };
    const statusPickupBlock = {
      isFetching: this.props.pickupBlocks.isFetching,
      loaded: this.props.pickupBlocks.loaded,
    };

    const displayNone = <span>No pickups scheduled</span>;

    const displayLoading = <Loader />;

    const displayStats = !(statusStats.isFetching && !statusStats.loaded) ? (
      stats.count > 0 ? (
        <Row>
          <Col sm={2} style={{ textAlign: "center" }}>
            <LabelWithCount label={"Total Pickups"} count={stats.count} />
          </Col>
          {this.props.stats.statuses.map((status, i) => (
            <Col sm={2} style={{ textAlign: "center" }} key={i}>
              <LabelWithCount label={status.label} count={status.count} />
            </Col>
          ))}
        </Row>
      ) : (
        displayNone
      )
    ) : (
      displayLoading
    );

    return (
      <div className="content-wrapper">
        <Row>
          <Col sm={8}>
            <Box>
              <DateDayNav date={this.state.date} onChange={this.onDateChange} />
            </Box>
          </Col>
          <Col sm={4}>
            <Box>
              <Form.Control
                as="select"
                value={this.state.type}
                onChange={this.onTypeChange}
              >
                <option value={0}>All</option>
                {this.props.pickupTypes.map((pickupType) => {
                  return (
                    <option key={pickupType.id} value={pickupType.id}>
                      {pickupType.label}
                    </option>
                  );
                })}
              </Form.Control>
            </Box>
          </Col>
        </Row>
        <Box>{displayStats}</Box>
        {statusPickupBlock.isFetching && !statusPickupBlock.loaded ? (
          <Box>{displayLoading}</Box>
        ) : (
          <>
            {pickupBlocksAlphabetical.length < 1 ? (
              <Box>No pickup blocks created</Box>
            ) : null}

            <Row>
              {pickupBlocksAlphabetical.map((pickupBlock, i) => {
                const { id, stops, pickupCount, zone, enabled } = pickupBlock;
                const stopsOffset =
                  this.state.pickupBlocksUpdateOffsets[id] || 0;
                const offsetedStops = stops + stopsOffset;

                return (
                  <Col sm={3} key={pickupBlock.id}>
                    <Box>
                      <CountPlusMinusWithProgressBar
                        title={zone.name}
                        maximum={offsetedStops}
                        count={pickupCount}
                        enabled={enabled}
                        onMinus={() => {
                          if (offsetedStops > pickupCount)
                            this.setState({
                              pickupBlocksUpdateOffsets: {
                                [id]: stopsOffset - 1,
                              },
                            });

                          this.setPickupBlockUpdateTimeout(pickupBlock);
                        }}
                        onPlus={() => {
                          this.setState({
                            pickupBlocksUpdateOffsets: {
                              [id]: stopsOffset + 1,
                            },
                          });

                          this.setPickupBlockUpdateTimeout(pickupBlock);
                        }}
                        onToggleDisabled={(e) => {
                          this.props.updatePickupBlock({
                            ...pickupBlock,
                            enabled: e.target.checked,
                          });
                        }}
                        id={pickupBlock.id}
                      />
                    </Box>
                  </Col>
                );
              })}

              {pickups.length ? (
                <Col sm={3}>
                  <Box>
                    <div>Unassigned Pickups</div>
                    {pickups.length}
                  </Box>
                </Col>
              ) : null}
            </Row>
          </>
        )}
      </div>
    );
  }
}

export default PickupBlocks;
