import { connect } from "react-redux";
import { RoleGuard } from "./component";

const mapStateToProps = (state) => {
  return {
    auth: {
      ...state.auth,
    },
    state: state,
  };
};

export const RoleGuardWrapper = connect(mapStateToProps, {})(RoleGuard);
