import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Modal from "components/modal";
import { FormCheck, FormGroup } from "react-bootstrap";

export const RenderSelectStatusModal = ({
  pickupStatuses,
  setShow,
  show,
  onConfirm,
}) => {
  const [selectedStatus, setSelectedStatus] = useState(null);

  const availablePickupStatuses = (arr) => {
    const available = arr.filter(
      (item) => item.label === "Complete" || item.label === "Out"
    );
    return available;
  };
  return (
    <Modal
      show={show}
      title={"Confirm Pickup Status"}
      onHide={() => setShow(false)}
      footer={
        <div>
          <Button
            disabled={!selectedStatus}
            variant="default"
            onClick={() => {
              onConfirm(Number.parseInt(selectedStatus));
            }}
          >
            Confirm
          </Button>
        </div>
      }
    >
      <div className="driver-confirm-modal">
        <div className="title">
          <strong>How would you describe this pickup’s status?</strong>
        </div>
        <div className="options-wrapper">
          <FormGroup>
            {availablePickupStatuses(pickupStatuses).map((status) => {
              return (
                <FormCheck
                  label={status.label}
                  id={status.id}
                  name="group1"
                  onChange={(e) => {
                    setSelectedStatus(e.target.id);
                  }}
                  type="radio"
                />
              );
            })}
          </FormGroup>
        </div>
      </div>
    </Modal>
  );
};
