import React from "react";
// import { withRouter } from "react-router";
import { ListGroupItem, Button } from "react-bootstrap";
import querystring from "query-string";
import DonorUserTable from "./donor-user-table";
import { determineAccountSearchType } from "utils/helpers";
import { useNavigate } from "react-router";

const DonorSearchResults = ({
  clearSearchCustomers,
  searchCustomerResults,
  selectedCurrentTab,
  tab,
  mode,
  activateFirstTab,
}) => {
  const navigate = useNavigate();
  const rehydrateSearch = () => {
    clearSearchCustomers();
    // searchSubmit();
  };

  const hasCustomers = searchCustomerResults.length > 0;
  const noCustomers = searchCustomerResults.length === 0;
  const isFetching = searchCustomerResults.isFetching;
  const searchError = searchCustomerResults.isError;
  const errorMessage = searchCustomerResults.isError
    ? `${searchCustomerResults.error.error} : ${searchCustomerResults.error.message}`
    : null;

  if (isFetching) {
    return (
      <ListGroupItem>
        <div>loading results...</div>
      </ListGroupItem>
    );
  }

  if (hasCustomers) {
    return (
      <DonorUserTable
        push={navigate}
        data={searchCustomerResults}
        selected={selectedCurrentTab}
        tab={tab}
        mode={mode}
        onMergeSuccess={() => {
          rehydrateSearch();
        }}
        activateFirstTab={activateFirstTab}
        actions={{}}
      />
    );
  }

  if (noCustomers) {
    let addNewButton = null;
    if (tab.type === "account") {
      const accountSearchType = determineAccountSearchType(tab.name);

      if (accountSearchType !== "id")
        addNewButton = (
          <a
            href={`donors/new?${querystring.stringify({
              [determineAccountSearchType(tab.name)]: tab.name,
            })}`}
          >
            <Button>Add New</Button>
          </a>
        );
    }

    return (
      <ListGroupItem style={{ border: "none", textAlign: "center" }}>
        <div>There are no results for this search.</div>
        {addNewButton}
      </ListGroupItem>
    );
  }

  if (searchError) {
    return (
      <ListGroupItem>
        <div>{errorMessage}</div>
      </ListGroupItem>
    );
  }
};

export default DonorSearchResults;
