import { connect } from "react-redux";
import { addNoteCountPickup } from "actions/routes.actions";

import Notes from "./notes.component";

const mapStateToProps = (state) => {
  return {
    noteCategories: state.common.data.noteCategories,
  };
};

const mapDispatchToProps = {
  addNoteCountPickup,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
