import { connect } from "react-redux";
// import { getFormValues } from 'redux-form';
import {
  requestUsers,
  setDate,
  uploadCSV,
  rehydrateRoutes,
  bulkDeleteRoutes,
  updateRouteFurniture,
  sendReminderNotifications,
  requestRoutePickupsMinimal,
  updatePickups,
} from "actions/routes.actions";
import { getRegion } from "actions/regions.actions";

import PickupRoutesComponent from "./routes.component";

const mapStateToProps = (state) => {
  return {
    auth: {
      ...state.auth,
    },

    activeRegion: state.regions.activeRegion,

    pickupTypes: state.common.data.pickupTypes,

    pickupRoutes: {
      ...state.pickupRoutes,
    },

    pickupsManyFetching: state.pickupRoutes.pickupsManyFetching,

    pickups: [...state.pickupRoutes.pickups],
    pickupsMin: [...state.pickupRoutes.pickupsMin],
  };
};

const mapDispatchToProps = {
  requestUsers,

  updateRouteFurniture,

  uploadCSV,

  setDate,

  rehydrateRoutes,

  requestRoutePickupsMinimal,

  getRegion,

  sendReminderNotifications,

  bulkDeleteRoutes,

  updatePickups,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PickupRoutesComponent);
