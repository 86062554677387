import React, { useState, useEffect, useCallback } from "react";
import { AddNoteForm, Note } from "forms/notes";
import { locationsNotesAPI } from "api/locations";
import { pickupsNotesAPI } from "api/pickups";
import { usersNotesAPI } from "api/users";
import { Form, Stack } from "react-bootstrap";
import Collapsible from "components/collapse-list";

const NotesSection = ({
  notes,
  title,
  updateNote,
  currentUserId,
  deleteNote,
}) => {
  return (
    <Stack gap={3} className="notes-section">
      <div>
        {notes.length} {title} Note{notes.length > 1 ? "s" : ""}
      </div>
      <div className="notes">
        <Collapsible
          numItemsToShow={2}
          DisplayComponent={Note}
          list={notes.map((note) => ({
            note,
            deleteNote,
            updateNote,
            notEditable: currentUserId !== note.posterId,
          }))}
        />
      </div>
    </Stack>
  );
};

const locationEntityType = "location";
const userEntityType = "user";
const pickupEntityType = "pickup";

const Notes = ({
  locationId,
  pickupId,
  userId,
  driverMode,
  setBadge,
  currentUserId,
}) => {
  const [locationNotes, setLocationNotes] = useState([]);
  const [pickupNotes, setPickupNotes] = useState([]);
  const [userNotes, setUserNotes] = useState([]);
  const [noteCategories, setNoteCategories] = useState([]);
  const [note, setNote] = useState("");
  const [category, setCategory] = useState(null);
  const [apiSettings, setApiSettings] = useState({
    api: pickupsNotesAPI,
    entityId: pickupId,
    setNotes: setPickupNotes,
    entityType: pickupEntityType,
  });

  const selectSettingsToUpdate = (entityType) => {
    switch (entityType) {
      case locationEntityType:
        return { api: locationsNotesAPI, setNotes: setLocationNotes };

      case userEntityType:
        return { api: usersNotesAPI, setNotes: setUserNotes };

      case pickupEntityType:
        return { api: pickupsNotesAPI, setNotes: setPickupNotes };

      default:
        break;
    }
  };

  useEffect(() => {
    pickupsNotesAPI
      .getCategories()
      .then((categories) => (setNoteCategories(categories), categories))
      .then(([category]) => (category ? setCategory(category.id) : null));
  }, []);

  useEffect(() => {
    setApiSettings((prev) => {
      const newSettings = { ...prev, entityId: pickupId };
      return newSettings;
    });
  }, [pickupId]);

  const getLocation = async () => {
    const res = await locationsNotesAPI.get(locationId);
    setLocationNotes(res);
  };
  const getPickup = async () => {
    const res = await pickupsNotesAPI.get(pickupId);
    setPickupNotes(res);
  };

  const getUserPickup = async () => {
    const res = await usersNotesAPI.get(userId);
    setUserNotes(res);
  };

  useEffect(() => {
    setBadge(locationNotes.length + pickupNotes.length + userNotes.length);
  }, [locationNotes, pickupNotes, userNotes]);

  const getNotes = useCallback(
    (param) => {
      switch (param) {
        case locationEntityType:
          getLocation();
          break;
        case pickupEntityType:
          getPickup();
          break;
        case userEntityType:
          getUserPickup();
          break;

        default:
          setBadge(-1);
          getLocation();
          getPickup();
          getUserPickup();
          break;
      }
    },
    [apiSettings]
  );

  useEffect(() => {
    getNotes();
  }, [apiSettings.entityId]);

  const createNote = useCallback(() => {
    apiSettings.api
      .create(apiSettings.entityId, note, category)
      .then(async () => {
        setNote("");
        await getNotes(apiSettings.entityType);
      });
  }, [note, apiSettings, getNotes, category]);

  const updateNote = useCallback(
    async (noteToUpdate) => {
      const { api, setNotes } = selectSettingsToUpdate(noteToUpdate.entityType);
      const res = await api.update(pickupId, noteToUpdate);

      const updatedNote = res.data.data[1][0];
      setNotes((prev) => {
        const newArray = [...prev];
        const indexChangedNote = newArray.findIndex(
          (item) => item.id === updatedNote.id
        );
        newArray[indexChangedNote] = {
          ...newArray[indexChangedNote],
          ...updatedNote,
        };
        return newArray;
      });
    },
    [apiSettings, setPickupNotes, pickupId]
  );

  const deleteNote = useCallback(
    async (noteToDelete) => {
      const { api, setNotes } = selectSettingsToUpdate(noteToDelete.entityType);
      try {
        const res = await api.delete(pickupId, noteToDelete);
        setNotes((prev) => {
          const newArray = prev.filter((item) => item.id !== noteToDelete.id);
          return newArray;
        });
      } catch (error) {}
    },
    [apiSettings, setPickupNotes, pickupId]
  );

  const defaultNotesSectionprops = {
    currentUserId,
    updateNote,
    deleteNote,
  };

  return (
    <div
      className={`driver-note-component-wrapper ${driverMode && "driver-mode"}`}
    >
      <Form.Group className="form-group-flex">
        <Form.Label>Add note to</Form.Label>
        <Form.Control
          as="select"
          onChange={(e) => {
            switch (e.target.value) {
              case "1":
                setApiSettings({
                  api: pickupsNotesAPI,
                  entityId: pickupId,
                  setNotes: setPickupNotes,
                  entityType: pickupEntityType,
                });
                break;

              case "2":
                setApiSettings({
                  api: locationsNotesAPI,
                  entityId: locationId,
                  setNotes: setLocationNotes,
                  entityType: locationEntityType,
                });
                break;
              case "3":
                setApiSettings({
                  api: usersNotesAPI,
                  entityId: userId,
                  setNotes: setUserNotes,
                  entityType: userEntityType,
                });
                break;

              default:
                break;
            }
          }}
        >
          <option value={1}>Pickup</option>
          <option value={2}>Location</option>
          <option value={3}>User</option>
        </Form.Control>
      </Form.Group>
      <AddNoteForm
        setNote={setNote}
        note={note}
        category={category}
        setCategory={setCategory}
        noteCategories={noteCategories}
        createNote={createNote}
        driverMode
      />
      <Stack gap={5} className="notes-sections">
        {!!pickupNotes.length && (
          <NotesSection
            {...defaultNotesSectionprops}
            title={"Pickup"}
            notes={pickupNotes}
          />
        )}
        {!!locationNotes.length && (
          <NotesSection
            {...defaultNotesSectionprops}
            title={"Location"}
            notes={locationNotes}
          />
        )}
        {!!userNotes.length && (
          <NotesSection
            {...defaultNotesSectionprops}
            title={"User"}
            notes={userNotes}
          />
        )}
      </Stack>
    </div>
  );
};

export default Notes;
