import React from "react";
import { formatPhone } from "utils/helpers";
import { Badge, Row, Col } from "react-bootstrap";
import Icon from "components/icon";

export const RenderDonor = (props) => {
  const { record: customer, common, additionalData } = props;
  const phoneNumbers = customer.phoneNumbers;
  return (
    <Row>
      {customer.profileImage && (
        <Col xs={4}>
          <img
            className="img-thumbnail w-100 rounded-circle"
            src={customer.profileImage}
            alt=""
          />
        </Col>
      )}
      <Col xs={customer.profileImage ? 8 : 12}>
        <h3 className="list-group-item-heading">
          {customer.fname} {customer.lname}
        </h3>
        <div className="d-flex flex-column" style={{ gap: "0.5rem" }}>
          {customer.email && (
            <div>
              <Badge bg="secondary">
                <Icon type="mail" />
              </Badge>{" "}
              {customer.email}
            </div>
          )}

          {(phoneNumbers || []).map((p, i) => {
            return (
              <div key={i}>
                <Badge bg="secondary">
                  <Icon type="phone" />
                </Badge>{" "}
                {formatPhone(p.number)}
              </div>
            );
          })}
          {customer.userTypeId && (
            <div>
              <Badge bg="secondary">
                <Icon type="user" />
              </Badge>{" "}
              {common.userType[customer.userTypeId].label}
            </div>
          )}
          {customer.leadSourceId && (
            <div>
              <Badge bg="secondary">
                <Icon type="flag" />
              </Badge>{" "}
              {common.leadSource[customer.leadSourceId].label}
            </div>
          )}
          {customer.languageId && (
            <div>
              <Badge bg="secondary">
                <Icon type="language" />
              </Badge>{" "}
              {common.languages[customer.languageId].name}
            </div>
          )}
        </div>
        {additionalData && additionalData.map((string) => <div>{string}</div>)}
      </Col>
    </Row>
  );
};

export default RenderDonor;
