import MapboxPolyline from "@mapbox/polyline";
import { Polyline } from "@react-google-maps/api";
import React from "react";

const RoutesPolylines = ({ polyline, color }) => {
  const decodedPoly = MapboxPolyline.decode(polyline, 6).map((c) => ({
    lat: c[0],
    lng: c[1],
  }));

  return (
    <Polyline
      path={decodedPoly}
      options={{
        strokeColor: color,
        strokeOpacity: 1.0,
        strokeWeight: 5,
      }}
    />
  );
};

export default RoutesPolylines;
