import { storeUser, logout as logoutUser } from "utils/auth";
import { showToast } from "./toast.actions";
import { loginUserCode, loginUserv1 } from "../api/users";

export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const AUTHENTICATE_USER_CODE = "AUTHENTICATE_USER_CODE";
export const AUTHENTICATE_USER_COMPLETE = "AUTHENTICATE_USER_COMPLETE";
export const AUTHENTICATE_USER_FAILED = "AUTHENTICATE_USER_FAILED";

export const LOGOUT_USER = "LOGOUT_USER";

export function authenticateUser(data) {
  return (dispatch) => {
    dispatch({ type: AUTHENTICATE_USER, data });

    return loginUserv1(data)
      .then((res) => dispatch(authenticateUserComplete(res.data)))
      .catch((err) => dispatch(authenticateUserFailed()));
  };
}

export function authenticateUserCode(data) {
  return (dispatch) => {
    dispatch({ type: AUTHENTICATE_USER, data });

    return loginUserCode(data)
      .then((res) => dispatch(authenticateUserComplete(res.data)))
      .catch((err) => dispatch(authenticateUserFailed()));
  };
}

function authenticateUserComplete(data) {
  const user = data.data[0];
  storeUser(user);

  return {
    type: AUTHENTICATE_USER_COMPLETE,
    auth: user,
    receivedAt: new Date(),
  };
}

function authenticateUserFailed() {
  showToast({ message: "Incorrect email or password", type: "error" });

  return {
    type: AUTHENTICATE_USER_FAILED,
    receivedAt: new Date(),
  };
}

export function logout() {
  logoutUser();
  return {
    type: LOGOUT_USER,
    receivedAt: new Date(),
  };
}
