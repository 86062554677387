import { useCallback, useEffect, useState, useRef } from "react";
import { Button, ProgressBar, Stack } from "react-bootstrap";

import {
  deletePickupImage,
  getPickupImages,
  uploadPickupImage,
} from "api/pickups";
import PickupImagesDisplay from "./pickup-images-display";
import Modal from "components/modal";

const AddImageButton = ({ uploadImage, uploading }) => {
  const inputImageUpload = useRef(null);

  return (
    <>
      <input
        accept="image/png, image/jpeg"
        ref={inputImageUpload}
        onChange={(e) => uploadImage(e.target.files[0])}
        type="file"
        style={{ display: "none" }}
      />

      <Button
        disabled={uploading}
        variant="primary"
        onClick={() => inputImageUpload?.current?.click()}
      >
        {uploading ? "Uploading" : "Upload"}
      </Button>
    </>
  );
};

const PickupImageUploadForm = ({
  pickupId,
  setBadge,
  addImageCountPickup,
  mobileMode,
  pickupImageCategories,
  onChange,
}) => {
  const [uploading, setUploading] = useState(false);
  const [images, setImages] = useState([]);
  const [addImageModal, setAddImageModal] = useState(false);

  useEffect(() => {
    if (setBadge) setBadge(images.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images.length]);

  const getImages = useCallback(async () => {
    setBadge(-1);
    const res = await getPickupImages({ id: pickupId });
    setImages(res);
    setBadge(res.length);
  }, [pickupId]);

  useEffect(() => {
    getImages();
  }, [getImages]);

  const uploadImage = useCallback(
    async (image) => {
      setUploading(true);

      await uploadPickupImage({ id: pickupId }, image, 1);
      await getImages();
      addImageCountPickup(pickupId, 1);
      setUploading(false);
      onChange();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getImages, pickupId]
  );

  const deleteImage = useCallback(
    async (image) => {
      await deletePickupImage({ id: pickupId }, image);
      await getImages();
      addImageCountPickup(pickupId, -1);
      onChange();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getImages, pickupId]
  );

  return (
    <Stack className={`mobile-mode`}>
      <PickupImagesDisplay
        images={images}
        deleteImage={deleteImage}
        pickupImageCategories={pickupImageCategories}
      />
      {uploading && <ProgressBar now={100} animated />}
      <div className={"p-4 text-end bg-white"}>
        <Button
          onClick={() => {
            setAddImageModal(true);
          }}
        >
          Add Images
        </Button>
        {/* <AddImageButton uploading={uploading} uploadImage={uploadImage} /> */}
      </div>
      <Modal
        show={addImageModal}
        title={"Add Images"}
        onHide={() => {
          setAddImageModal(false);
        }}
        footer={
          <AddImageButton uploading={uploading} uploadImage={uploadImage} />
        }
      >
        <Stack className="p-4 ">
          <div className="p-3 title">
            <strong>
              {images.length} Image{images.length > 1 ? "s" : ""}
            </strong>
          </div>
          <Stack gap={2} className="images-list">
            {images.map((img) => {
              return (
                <div className="image-container">
                  <img className="image" src={img.url} alt="Cargo" />
                  <Button
                    variant="link"
                    onClick={() => {
                      deleteImage(img);
                    }}
                  >
                    Remove
                  </Button>
                </div>
              );
            })}
          </Stack>
        </Stack>
      </Modal>
    </Stack>
  );
};

export default PickupImageUploadForm;
