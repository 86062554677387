import React, { useState } from "react";
import PropTypes from "prop-types";
import PlacesAutocomplete from "react-google-autocomplete";
import { FormLabel, FormGroup, Alert } from "react-bootstrap";
import { REACT_APP_MP_GOOGLE_MAPS_API } from "config";

const PlacesAutocompleteField = ({
  input,
  label,
  meta: { touched, error, valid },
  onPlaceSelected,
  required,
  type,
  renderCondition = true,
  name,
  fieldOnly = false,
}) => {
  const [lastSelectedValue, setLastSelectedValue] = useState(input.value);

  const validationError = touched && error;

  let errorComponent;
  if (validationError) {
    let errorMessage;
    if (error[0] === '"' && error.slice(1).indexOf('"')) {
      errorMessage = `${label || "This"}${error.substr(
        error.slice(1).indexOf('"') + 2
      )}`;
    }
    errorComponent = <Alert variant="danger">{errorMessage || error}</Alert>;
    // validationState = "error";
  }

  if (valid) {
    // validationState = "success";
  }

  if (!renderCondition) {
    return null;
  }

  function handleSelect(address) {
    if (!address || !address.place_id) {
      return false;
    }

    const getAddressComponentLong = (key) => {
      return address.address_components.find((component) => {
        const keyFound = component.types.find((tag) => {
          return tag === key;
        });

        return keyFound ? true : false;
      }).long_name;
    };

    const getAddressComponent = (key) => {
      const found_component = address.address_components.find((component) => {
        const keyFound = component.types.find((tag) => {
          return tag === key;
        });
        return keyFound ? true : false;
      });

      if (found_component && found_component.short_name) {
        return found_component.short_name;
      } else {
        return false;
      }
    };

    const streetAddress =
      getAddressComponent("street_number") +
      " " +
      getAddressComponentLong("route");

    const addressInfo = {
      zipCode: getAddressComponent("postal_code"),
      address: streetAddress,
      city:
        getAddressComponent("locality") || getAddressComponent("sublocality"),
      state: getAddressComponent("administrative_area_level_1"),
      mapAddress: address.formatted_address,
      lat: address.geometry.location.lat(),
      lng: address.geometry.location.lng(),
      coordinates: address.geometry.location,
    };
    setLastSelectedValue(streetAddress);
    onPlaceSelected(addressInfo);
  }

  const field = (
    <PlacesAutocomplete
      apiKey={REACT_APP_MP_GOOGLE_MAPS_API}
      onPlaceSelected={handleSelect}
      options={{ types: ["address"] }}
      className={`form-control ${validationError ? "form-control-error" : ""}`}
      onBlur={(e) => {
        if (input.onBlur) input.onBlur(e);
        if (input.onChange) input.onChange(lastSelectedValue);
      }}
      {...input}
    />
  );

  if (fieldOnly) return field;

  return (
    <FormGroup
      // validationState={validationState}
      className={`${type}-form-group`}
    >
      <FormLabel>
        {label}
        {required && <span className="required-asterick">*</span>}
      </FormLabel>
      {field}
      {errorComponent}
    </FormGroup>
  );
};

PlacesAutocompleteField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  onPlaceSelected: PropTypes.func,
  required: PropTypes.bool,
  type: PropTypes.string,
};

export default PlacesAutocompleteField;
