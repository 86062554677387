// Pass this component an array of pickup sources
import { Doughnut } from "react-chartjs-2";
import React from "react";
import { option } from "./donughtGraghOptions";
import "chart.js/auto";
const Graph = (props) => {
  return (
    <Doughnut
      options={option}
      data={{
        labels: props.sources?.map((data) => data.label + " " + data.count),
        datasets: [
          {
            data: props.sources?.map((data) => data.count),
            backgroundColor: [
              "#efefef",
              process.env.REACT_APP_MP_PRIMARY_COLOR,
              "#ddd",
            ],
            hoverBackgroundColor: [
              "#efefef",
              process.env.REACT_APP_MP_PRIMARY_COLOR,
              "#333",
            ],
          },
        ],
      }}
    />
  );
};

export default Graph;
