import { useEffect, useState } from "react";
import WithLoader from "../with-loader";
import { UserProvider } from "contexts/user";
import { PickupTypesProvider } from "contexts/pickupTypes";
import { logout } from "utils/auth";
import { authToken } from "api/users";

const WithDashboardData = (props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Promise.all([props.requestRegions(), props.loadUser()])
      .then(() => setLoading(false))
      .catch((err) => {
        // If a request fails with a 4xx error code, check the users token
        if (
          err.isAxiosError &&
          err.response.status >= 400 &&
          err.response.status < 500
        ) {
          // If authenticating token fails logout the user
          authToken().catch(logout);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WithLoader fullScreen={true} isLoading={loading}>
      <UserProvider user={props.user} initialRequest={true}>
        <PickupTypesProvider>{props.children}</PickupTypesProvider>
      </UserProvider>
    </WithLoader>
  );
};

export default WithDashboardData;
