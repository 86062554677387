import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { REACT_APP_MP_COPYRIGHT_TEXT } from "config";

const Footer = () => (
  <div className="text-center mb-2">
    {REACT_APP_MP_COPYRIGHT_TEXT} v{process.env.VERSION}
  </div>
);

export default Footer;
