import React, { useEffect } from "react";
import RegionHeader from "./region-header.component";
import "./styles.scss";
import WithLoader from "hoc/with-loader";
import { useParams, useLocation } from "react-router";

const RegionZones = ({
  activeRegion,
  getRegion,
  clearActiveRegion,
  children,
}) => {
  const params = useParams();
  const location = useLocation();
  const { regionId } = params;
  const loadRegion = () => {
    if (regionId) {
      getRegion({ regionId });
    } else {
      getRegion({ regionId: 1 });
    }
  };

  useEffect(() => {
    if (!activeRegion) loadRegion();

    return () => {
      clearActiveRegion();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      activeRegion &&
      activeRegion.loaded &&
      activeRegion.region.id !== parseInt(regionId || "1")
    ) {
      loadRegion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionId]);

  const isFetchingRegion = !activeRegion || !activeRegion.loaded;

  return (
    <WithLoader isLoading={isFetchingRegion}>
      <RegionHeader
        id={activeRegion?.region?.id}
        label={activeRegion?.region?.label}
        location={location}
      />
      {children}
    </WithLoader>
  );
};

export default RegionZones;
