import React, { useState } from "react";

import { Button } from "react-bootstrap";

import CreateRoute from "forms/create-route";
import Modal from "components/modal";

const CreateEmptyRoute = (props) => {
  const [show, setShow] = useState(false);
  const closeFrom = () => {
    setShow(false);
  };
  return (
    <>
      <Button
        onClick={() => {
          setShow(true);
        }}
      >
        Create Empty Route
      </Button>
      <Modal show={show} onHide={closeFrom} title={`Create Route`}>
        <CreateRoute {...props} newEmptyRoute />
      </Modal>
    </>
  );
};

export default CreateEmptyRoute;
