import { connect } from "react-redux";

import DonorInfo from "./display.component";

function mapStateToProps(state) {
  return {
    common: state.common.dataMap,
  };
}

export default connect(mapStateToProps, {})(DonorInfo);
