import {
  REQUEST_SETTINGS,
  RECEIVE_SETTINGS,
  CLEAR_SETTINGS,
  DELETE_SETTING,
  SETTINGS_FETCH_FAIL,
} from "actions/settings.actions";

const settings = (
  state = { isFetching: false, loaded: false, globalSettings: null },
  action
) => {
  switch (action.type) {
    case REQUEST_SETTINGS:
      return {
        ...state,
        loaded: false,
        isFetching: true,
      };
    case RECEIVE_SETTINGS:
      const { settings } = action;
      const settingsForState = Array.isArray(settings)
        ? settings
        : [settings] || [];
      return {
        ...state,
        loaded: true,
        isFetching: false,
        globalSettings: [...settingsForState],
      };
    case DELETE_SETTING:
    case CLEAR_SETTINGS:
      const { settingId } = action;
      const updatedglobalSettings = state.globalSettings.filter(
        (setting) => setting.id !== settingId
      );
      return {
        ...state,
        globalSettings: updatedglobalSettings,
      };
    case SETTINGS_FETCH_FAIL:
      return {
        ...state,
        failed: true,
      };
    default:
      return state;
  }
};
const settingsReducer = { settings };
export default settingsReducer;
