import { connect } from "react-redux";
// import { getFormValues } from 'redux-form';
import {
  requestRoutes,
  receiveRoutes,
  requestRoutePickups,
  receivePickupRoutes,
  updateRoutePickup,
  requestUsers,
  updateRoute,
  updateRouteCompleted,
  updateRouteDriver,
  uploadCSV,
  requestRoutePickupBlocks,
  deleteRoute,
  updatePickupOrder,
  updateRouteFurniture,
  startRoute,
  startPickup,
  rehydrateRoutes,
  requestRoutePickupsByRouteId,
} from "actions/routes.actions";
import {
  updatePickup,
  receivePickups,
} from "pages/donors/donor-single/donor-pickups/pickups.actions";
import { getRegion } from "actions/regions.actions";

import { requestLocations } from "actions/locations.actions";

import SingleRoute from "./route-single.component";

const mapStateToProps = (state) => {
  const foundTimeWindows =
    state.settings.globalSettings &&
    state.settings.globalSettings.find(
      (setting) => setting.label === "time-window"
    );
  const foundFurnitureTimeWindows =
    state.settings.globalSettings &&
    state.settings.globalSettings.find(
      (setting) => setting.label === "furniture-time-window"
    );

  return {
    auth: {
      ...state.auth,
    },

    pickupStatuses: [...state.common.data.pickupStatuses],
    routesMap: { ...state.pickupRoutes.routesMap },

    timeWindows: foundTimeWindows ? foundTimeWindows.value : [],
    furnitureTimeWindows: foundFurnitureTimeWindows
      ? foundFurnitureTimeWindows.value
      : [],

    users: {
      ...state.users,
    },
  };
};

const mapDispatchToProps = {
  requestRoutes,

  requestRoutePickups,

  startRoute,

  receivePickupRoutes,

  updateRoutePickup,

  requestUsers,

  requestLocations,

  updateRoute,

  updateRouteCompleted,

  updateRouteDriver,

  updateRouteFurniture,

  uploadCSV,

  requestRoutePickupBlocks,

  deleteRoute,

  updatePickupOrder,

  receiveRoutes,

  getRegion,

  startPickup,

  rehydrateRoutes,

  // updatePickupRoute

  updatePickup,

  receivePickups,

  requestRoutePickupsByRouteId,
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleRoute);
