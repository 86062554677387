import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Badge, Alert } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";

import querystring from "query-string";
import ConfirmButton from "components/confirm-button";
import DonorSearchResults from "./donor-search-results.component";
import { changeActiveTab, setSearchQuery } from "actions/donor-search.actions";
import { showToast } from "actions/toast.actions";
import { clearSearchCustomers } from "actions/customers.actions";
import { mergeUsers } from "api/users";
import RenderIf from "hoc/render-if";
import Tabs from "components/tabs";

const DonorSearchTabs = (props) => {
  const [currentActiveTab, setActiveTab] = useState(null);
  const [userSelection, setUserSelection] = useState(null);
  const [isLoading, setLoadingState] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const openTabsFromProps = props.openTabs;
    const selectedUsersFromProps = props.selectedUsers;
    const areSelectedUsersChanging =
      !userSelection || selectedUsersFromProps.length !== userSelection.length;
    const openedTabs = openTabsFromProps.length > 0 ? openTabsFromProps : false;
    const foundActiveTab =
      openedTabs && openTabsFromProps.find((tab) => tab.active);
    const activeTab = foundActiveTab ? foundActiveTab.name : "selected-donor";
    setActiveTab(activeTab);
    if (areSelectedUsersChanging) {
      setUserSelection(selectedUsersFromProps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openTabs, props.selectedUsers, userSelection]);

  const removeSearchTab = ({ tab }) => {
    const isClosingTabActive = tab.name === currentActiveTab;
    const {
      clearSearchCustomers,
      changeActiveTab,
      openTabs: searchTabs,
    } = props;
    const isOneTabOpen = searchTabs && searchTabs.length === 1;
    const areMultipleTabsOpen = searchTabs && searchTabs.length > 1;
    // we only want to automatically change the active tab on close if the tab being closed was/is the active tab
    if (isClosingTabActive) {
      if (isOneTabOpen) {
        clearSearchCustomers();
      } else if (areMultipleTabsOpen) {
        const isClosingFirstTab = searchTabs[0].query === tab.query;
        // if more than one tab is open and they close the active tab, we want to make the previous tab the current active tab
        const indexOfCurrentTab = searchTabs.findIndex(
          (openTab) => openTab.query === tab.query
        );
        const previousTab = searchTabs[indexOfCurrentTab - 1];
        const nextTab = searchTabs[indexOfCurrentTab + 1];
        setActiveTab(isClosingFirstTab ? nextTab.query : previousTab.query);
        changeActiveTab({ tab: isClosingFirstTab ? nextTab : previousTab });
      }
    }

    // Update the query parameter
    navigate(
      location.pathname +
        "?" +
        querystring.stringify(
          {
            query: searchTabs
              .map((tab) => tab.query)
              .filter((t) => t !== tab.query),
          },
          { arrayFormat: "bracket" }
        )
    );
  };

  const handleActiveTabChange = ({ tab }) => {
    const { changeActiveTab } = props;
    changeActiveTab({ tab });
  };

  const logSelection = async () => {
    const {
      selectedUsers,
      searchCustomers,
      setSearchQuery,
      clearSearchCustomers,
    } = props;
    const areMultipleUsersSelected = selectedUsers && selectedUsers.length > 1;
    if (areMultipleUsersSelected) {
      setLoadingState(true);
      const userIdsForMerge = selectedUsers.map(
        (selectedUser) => selectedUser.id
      );
      try {
        const {
          data: { data: mergedUsers },
        } = await mergeUsers(userIdsForMerge);
        // since we are merging users, we know that the returned array will only return one result, the merged user
        const mergedUser = mergedUsers[0];
        // set the search query to to be the value of the newly merged user id
        setSearchQuery({
          query: mergedUser.id,
        });
        // clear existing search tabs
        clearSearchCustomers();
        // create one new search tab with the value of the newly merged user id
        searchCustomers(
          {
            name: mergedUser.id,
            type: "account",
          },
          [4, 5]
        );
      } catch (err) {
        showToast({ message: "Merge Failed", type: "error" });
      } finally {
        setLoadingState(false);
      }
    }
  };
  const { openTabs: searchTabs, selectedUsers } = props;
  const shouldRenderTabs = searchTabs && searchTabs.length > 0;
  const shouldShowSelectedUsersTab = userSelection && userSelection.length > 0;

  const currentTabObj = searchTabs.find((tab) => tab.name === currentActiveTab);

  return shouldRenderTabs ? (
    <div className={"mt-4"}>
      <Tabs
        eq={(a, b) => a.name === b.name}
        active={{ name: currentActiveTab }}
        // active={{ name: currentActiveTab }}

        onChange={(tab) => handleActiveTabChange({ tab })}
        onRemove={(tab) => removeSearchTab({ tab })}
        header={
          userSelection && (
            <>
              <Alert
                show={userSelection.length > 0}
                className={"d-flex justify-content-between w-100 align-center"}
              >
                <div className={"align-items-center d-flex"}>
                  <div class={"h4 mb-0"}>
                    <Badge className={"me-2"} bg={"primary"}>
                      {userSelection.length}
                    </Badge>
                    Donors Selected{" "}
                  </div>
                </div>
                <div classname={"d-flex"}>
                  <Button
                    className={"me-2"}
                    onClick={() => {
                      // Clear the query parameter
                      navigate(location.pathname);
                    }}
                  >
                    Clear
                  </Button>
                  <RenderIf
                    condition={userSelection && userSelection.length >= 2}
                  >
                    <ConfirmButton
                      openConfirmCallback={() =>
                        handleActiveTabChange({
                          tab: { name: "selected-donor" },
                        })
                      }
                      disabled={!userSelection || userSelection.length < 2}
                      onConfirm={logSelection}
                      confirmText={`Merge ${userSelection.length} users?`}
                      buttonText="Merge"
                      loading={isLoading}
                    />
                  </RenderIf>
                </div>
              </Alert>
            </>
          )
        }
      >
        {shouldShowSelectedUsersTab && (
          <Tabs.Tab
            value={{ name: "selected-donor" }}
            special={true}
            removable={false}
          >
            <Badge bg={"secondary"} className={"me-2"}>
              {userSelection.length}
            </Badge>
            Selected
          </Tabs.Tab>
        )}
        {searchTabs.map((tab, i) => (
          <Tabs.Tab value={tab} key={i} loading={tab.loading}>
            {tab.name}
          </Tabs.Tab>
        ))}
      </Tabs>

      {shouldShowSelectedUsersTab && currentActiveTab === "selected-donor" && (
        <DonorSearchResults
          mode={currentActiveTab}
          selectedCurrentTab={selectedUsers}
          searchCustomerResults={userSelection}
          activateFirstTab={() => {
            handleActiveTabChange({ tab: searchTabs[0] });
          }}
        />
      )}
      {currentTabObj && (
        <>
          {!currentTabObj.loading && (
            <DonorSearchResults
              tab={currentTabObj}
              searchCustomerResults={currentTabObj.results}
              selectedCurrentTab={currentTabObj.selected}
            />
          )}
        </>
      )}
    </div>
  ) : null;
};

const mapStateToProps = (state) => ({
  openTabs: state.searchCustomers.searchTabs,
  selectedUsers: state.searchCustomers.selectedUsers,
});

const mapDispatchToProps = {
  changeActiveTab,
  setSearchQuery,
  clearSearchCustomers,
};

export default connect(mapStateToProps, mapDispatchToProps)(DonorSearchTabs);
