import React, { useState } from "react";
import { Field } from "redux-form";
import FormField from "forms/FormField";
import { FormGroup, Row, Col, FormLabel } from "react-bootstrap";
import TimePicker from "forms/time-picker";
import PlacesAutocompleteField from "forms/PlacesAutocompleteField";
import { required, requiredDropdown } from "../../utils/validators";

const RenderCombinePickupsForm = ({ pickupTypes }) => {
  const [selected, setSelected] = useState(0);
  const pickupType = pickupTypes[selected];

  return (
    <>
      <Row>
        <Col sm={12} md={6}>
          <FormGroup>
            <FormLabel>Select Pickup To Combine</FormLabel>
            <Field
              name="selected_combine"
              type="select"
              onChange={(e) => {
                const idx = pickupTypes.findIndex((item) => {
                  return item.id === Number.parseInt(e.target?.value);
                });
                idx >= 0 && setSelected(idx);
              }}
              component={FormField}
              options={pickupTypes.map((type) => ({
                label: type.label,
                id: type.id,
              }))}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>
          <Field
            label={`${pickupType.label}: Stop Time`}
            name={`pickup_types.id${pickupType.id}.stop_time`}
            component={FormField}
            type="number"
            validate={required}
          />
        </Col>
        <Col sm={12} md={6}>
          <Field
            label={`${pickupType.label}:  Number of Trucks`}
            name={`pickup_types.id${pickupType.id}.vehicle_count`}
            component={FormField}
            type="number"
            validate={required}
          />
        </Col>
      </Row>
    </>
  );
};

const CreateRouteForm = ({
  handleSelectStart,
  handleSelectEnd,
  separateFurniture,
  pickupTypesForRoute,
}) => {
  const [combinePickups, setCombinePickups] = useState(false);
  return (
    <React.Fragment>
      <Row>
        <Col sm={"12"} md={6} lg={"6"}>
          <Field
            label={"Starting Location"}
            name={`start_location`}
            component={PlacesAutocompleteField}
            onPlaceSelected={handleSelectStart}
            placeholder={"Starting Address"}
          />
        </Col>
        <Col xs={12} md={6} lg={6}>
          <Field
            label={"Ending Location"}
            name={`end_location`}
            component={PlacesAutocompleteField}
            onPlaceSelected={handleSelectEnd}
            placeholder={"Ending Address"}
          />
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={6}>
          <Field
            name={`start_time`}
            component={TimePicker}
            step={15} // 15 min increments in dropdown
            // max={8am-8pm} TODO: Add Range of times that are actually used
            label="Start Time"
            date={false}
            calendar={false}
            placeholder={"Start Time"}
          />
        </Col>
        <Col sm={12} md={6}>
          <Field
            name={`end_time`}
            component={TimePicker}
            step={15} // 15 min increments in dropdown
            // max={8am-8pm} TODO: Add Range of times that are actually used
            label="End Time"
            date={false}
            calendar={false}
            placeholder={"End Time"}
          />
        </Col>
      </Row>
      {!combinePickups ? (
        pickupTypesForRoute.map((pickupType) => (
          <Row>
            <Col sm={12} md={6}>
              <Field
                label={`${pickupType.label}: Stop Time`}
                name={`pickup_types.id${pickupType.id}.stop_time`}
                component={FormField}
                className="form-control"
                type="number"
                validate={required}
              />
            </Col>
            <Col sm={12} md={6}>
              <Field
                label={`${pickupType.label}: Number of Trucks`}
                name={`pickup_types.id${pickupType.id}.vehicle_count`}
                component={FormField}
                className="form-control"
                type="number"
                validate={required}
              />
            </Col>
          </Row>
        ))
      ) : (
        <RenderCombinePickupsForm pickupTypes={pickupTypesForRoute} />
      )}

      <Field
        name="start_coordinates"
        component="input"
        className="form-control"
        type="hidden"
      />
      <FormGroup>
        <Field
          name="end_coordinates"
          component="input"
          className="form-control"
          type="hidden"
        />
      </FormGroup>
      {pickupTypesForRoute.length > 1 && (
        <Field
          label={"Combine Pickups"}
          value={combinePickups}
          onChange={(e) => {
            setCombinePickups(e.target.checked);
          }}
          name="combine_pickups"
          component={FormField}
          type="checkbox"
        />
      )}
      {process.env.REACT_APP_MP_AUTOMATIC_ROUTING === "true" && (
        <Field
          label={"Manual Routing"}
          name="routing"
          component={FormField}
          type="checkbox"
          invert={true}
        />
      )}
    </React.Fragment>
  );
};

const CreateNewEmptyRouteForm = ({ pickupTypes }) => {
  return (
    <React.Fragment>
      <Row>
        <Col sm={"12"} md={6} lg={"6"}>
          <Field
            label={"Pickup Type"}
            name="pickupTypeId"
            component={FormField}
            type={"select"}
            placeholder="Select..."
            options={pickupTypes}
            validate={requiredDropdown}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

const selectForm = (props) => {
  if (props.newEmptyRoute) {
    return <CreateNewEmptyRouteForm {...props} />;
  }
  return <CreateRouteForm {...props} />;
};

export default selectForm;
