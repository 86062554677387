import React, { createContext, useCallback, useContext, useState } from "react";
import { Form, Button } from "react-bootstrap";
import Loader from "../components/loader";
import RenderIf from "../hoc/render-if";
import { useForm, useWatch } from "react-hook-form";

const FormContext = createContext({
  control: {},
  errors: {},
  register: () => {},
});

const ContextForm = (props) => {
  const {
    initialValues,
    children,
    onSubmit,
    submitting,
    onSubmitSuccess,
    submitText = "Save",
    onCancel,
  } = props;

  const [formError, setFormError] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: initialValues });

  const wrappedSubmit = useCallback(
    async (...params) => {
      try {
        await onSubmit(...params);
        if (onSubmitSuccess) await onSubmitSuccess();
      } catch (e) {
        setFormError(e);
      }
    },
    [onSubmit, onSubmitSuccess]
  );

  return (
    <FormContext.Provider value={{ control, errors, register }}>
      <Form onSubmit={handleSubmit(wrappedSubmit)}>
        {children}

        <div className={"form-footer"}>
          <div className="error">{formError}</div>

          <div>
            <Button type="submit" disabled={submitting} variant="primary">
              {submitting ? <Loader width={30} /> : submitText}
            </Button>
            <RenderIf condition={!!onCancel}>
              <Button variant="primary" onClick={onCancel}>
                Cancel
              </Button>
            </RenderIf>
          </div>
        </div>
      </Form>
    </FormContext.Provider>
  );
};

const Field = ({
  type,
  label,
  name,
  options,
  optionField,
  selectOptions,
  ...inputProps
}) => {
  const { register, errors } = useContext(FormContext);

  return (
    <Form.Group>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        type={type}
        as={type === "select" ? "select" : "input"}
        {...register(name, options)}
        isInvalid={errors[name]}
        {...inputProps}
        {...(type === "checkbox" ? { className: "form-check-input" } : {})}
      >
        {type === "select"
          ? [
              {
                id: -1,
                [optionField]: inputProps.placeholder || "Select an option",
              },
              ...selectOptions,
            ].map((option, i) => {
              return (
                <option disabled={option.disabled} key={i} value={option.id}>
                  {option[optionField]}
                </option>
              );
            })
          : undefined}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {errors[name]?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const Watch = ({ children, ...props }) => {
  const { control } = useContext(FormContext);
  const value = useWatch({ control, ...props });

  return children(value);
};

ContextForm.Field = Field;
ContextForm.Watch = Watch;

export default ContextForm;
