import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";

const DriverPriorityField = (props) => {
  useEffect(() => {
    // Get drivers
    props.getUsers({ type: 3 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [drivers, setDrivers] = useState([]);
  useEffect(() => {
    if (!props.activeRegion || !props.activeRegion.loaded) return;
    setDrivers(
      Object.values(props.user_db.byId)
        .filter(
          (driver) =>
            driver.regionId === props.activeRegion.region.id ||
            driver.regionId === null
        )
        .sort((a, b) => a.priority - b.priority)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user_db.byId, props.activeRegion]);

  return props.user_db.isLoaded
    ? drivers.length > 0 && (
        <React.Fragment>
          <h3>Driver Priority</h3>
          <DragDropContext
            onDragEnd={({ source, destination }) => {
              // Swap the source and destination drivers
              const updatedDrivers = drivers
                .map((driver) =>
                  driver.priority === source.index
                    ? { ...driver, priority: destination.index }
                    : driver.priority === destination.index
                    ? { ...driver, priority: source.index }
                    : driver
                )
                .sort((a, b) => a.priority - b.priority);
              setDrivers(updatedDrivers);

              // Tell redux-form that we've updated the driver priority
              props.input.onChange(updatedDrivers);
            }}
          >
            <Droppable droppableId="drivers">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    marginBottom: "20px",
                    ...provided.droppableProps.style,
                  }}
                >
                  {drivers.map((driver) => (
                    <Draggable
                      key={driver.id}
                      draggableId={`${driver.id}`}
                      index={driver.priority}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            backgroundColor: "#eee",
                            padding: "15px",
                            margin: "8px 0",
                            ...provided.draggableProps.style,
                          }}
                        >
                          {driver.fname} {driver.lname}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </React.Fragment>
      )
    : "Loading";
};

export default DriverPriorityField;
