import React, { useEffect, useState, useRef } from "react";

import { Editor } from "@tinymce/tinymce-react";
import { REACT_APP_MP_TINY_MCE_KEY } from "config";

function EditorComponent({ value, onChange }) {
  const editorRef = useRef(null);
  const [initialValue, setInitialValue] = useState(null);

  useEffect(() => {
    if (value && !initialValue) {
      setInitialValue(value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <Editor
        onEditorChange={onChange}
        apiKey={REACT_APP_MP_TINY_MCE_KEY}
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={initialValue}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | blocks | " +
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          // content_style:
          //   "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
    </>
  );
}

export default EditorComponent;
