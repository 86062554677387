import { connect } from "react-redux";
import CreateUpdateZone from "./create-update-zone.component";
import withForm from "forms/form-class";

const validate = (values) => {
  const errors = {};

  // Validation on field arrays doesn't work properly so this has to be done here
  if (!values.zipCodes || !values.zipCodes.length) {
    errors._error = "You need to specify at least 1 zip code.";
  } else {
    // must have length 1 to be here
    let error;
    values.zipCodes
      .map((zipCodes) => (zipCodes ? zipCodes.zip : zipCodes))
      .some((zip) => {
        if (!zip || zip.length !== 5) {
          error = "Zip codes must be 5 characters long";
        }
        if (isNaN(+zip)) {
          error = "Zip codes must be a number";
        }
        return 1;
      });
    errors._error = error;
  }

  if (!values.name || values.name.length < 1) {
    errors.name = "You must specify a zone name";
  }

  return errors;
};

const mapStateToProps = (state, ownProps) => {
  const stopsSetting = state.settings.globalSettings.find(
    (setting) => setting.label === "pickup-stops"
  );

  return {
    pickupTypes: state.common.data.pickupTypes,
    initialValues: {
      defaultBlockStops: stopsSetting.stops,
      ...ownProps.initialValues,
    },
    stopsSetting: stopsSetting && stopsSetting.value,
    regions: state.regions.regions,
  };
};

// Connect Redux Form
const FormConnected = withForm(CreateUpdateZone, "NewZone", validate);

// Connect Redux Form
export default connect(mapStateToProps)(FormConnected);
