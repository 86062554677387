import { connect } from "react-redux";
import { RoleGuardDashboard } from "./component";

const mapStateToProps = (state) => {
  return {
    auth: {
      ...state.auth,
    },
    state: state,
  };
};

export const RoleGuardDashboardWrapper = connect(
  mapStateToProps,
  {}
)(RoleGuardDashboard);
