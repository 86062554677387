import { createContext } from "react";

export default createContext({
  leadSources: [],
  leadSourcesLoaded: false,
  leadSourcesMap: {},
  leadSourcesLoading: false,
  leadSourcesError: false,

  getLeadSources: () => {},
  createLeadSource: () => {},
  clearLeadSourcesError: () => {},
});
