import React from "react";
import { Badge } from "react-bootstrap";
import PickupStatusBadge from "components/pickup-status-badge";

const CountBox = ({ label, count, color }) => {
  return (
    <span
      // key={key}
      style={{
        border: "1px solid #e4e4e4",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "1rem",
        minWidth: "100px",
      }}
    >
      <div>
        <h3>{count}</h3>
      </div>
      <div>
        {color ? (
          <PickupStatusBadge color={color} label={label} />
        ) : (
          <Badge bg={"primary"}>
            <span style={{ fontSize: "1rem" }}>{label}</span>
          </Badge>
        )}
      </div>
    </span>
  );
};

export default CountBox;
