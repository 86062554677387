export const REQUEST_ROUTE_PICKUPS_BY_ROUTEID =
  "REQUEST_ROUTE_PICKUPS_BY_ROUTEID";
export const RECEIVE_ROUTE_PICKUPS_BY_ROUTEID =
  "RECEIVE_ROUTE_PICKUPS_BY_ROUTEID";

export const REQUEST_ROUTE_PICKUPS_MINIMAL = "REQUEST_ROUTE_PICKUPS_MINIMAL";
export const RECEIVE_ROUTE_PICKUPS_MINIMAL = "RECEIVE_ROUTE_PICKUPS_MINIMAL";

export const REQUEST_ROUTE = "REQUEST_ROUTE";
export const RECEIVE_ROUTE = "RECEIVE_ROUTE";

export const REQUEST_ROUTE_PICKUPS = "REQUEST_ROUTE_PICKUPS";
export const RECEIVE_ROUTE_PICKUPS = "RECEIVE_ROUTE_PICKUPS";

export const REQUEST_ROUTES = "REQUEST_ROUTES";
export const RECEIVE_ROUTES = "RECEIVE_ROUTES";

export const REQUEST_USERS = "REQUEST_USERS";
export const RECEIVE_USERS = "RECEIVE_USERS";

export const UPDATE_PICKUP = "UPDATE_PICKUP";
export const UPDATE_PICKUP_COMPLETED = "UPDATE_PICKUP_COMPLETED";

export const UPDATE_ROUTES_PICKUPS = "UPDATE_ROUTES_PICKUPS";
export const UPDATE_ROUTES_PICKUPS_COMPLETED =
  "UPDATE_ROUTES_PICKUPS_COMPLETED";

export const UPDATE_ROUTE_PICKUP = "UPDATE_ROUTE_PICKUP";
export const UPDATE_ROUTE_PICKUP_COMPLETED = "UPDATE_ROUTE_PICKUP_COMPLETED";

export const REFRESH_ROUTE_PICKUP = "REFRESH_ROUTE_PICKUP";

export const UPDATE_ROUTE = "UPDATE_ROUTE";
export const UPDATE_ROUTE_COMPLETED = "UPDATE_ROUTE_COMPLETED";

export const UPDATE_ROUTE_DRIVER = "UPDATE_ROUTE_DRIVER";
export const UPDATE_ROUTE_FURNITURE = "UPDATE_ROUTE_FURNITURE";

export const UPDATE_PICKUP_DATE = "UPDATE_PICKUP_DATE";

export const UPLOAD_ROUTE_CSV = "UPLOAD_ROUTE_CSV";
export const UPLOAD_ROUTE_CSV_COMPLETED = "UPLOAD_ROUTE_CSV_COMPLETED";

export const REQUEST_ROUTE_PICKUP_BLOCKS = "REQUEST_ROUTE_PICKUP_BLOCKS";
export const RECEIVE_ROUTE_PICKUP_BLOCKS = "RECEIVE_ROUTE_PICKUP_BLOCKS";

export const DELETE_ROUTE = "DELETE_ROUTE";
export const DELETE_ROUTE_COMPLETED = "DELETE_ROUTE_COMPLETED";
export const BULK_DELETE_ROUTE = "BULK_DELETE_ROUTE";
export const BULK_DELETE_ROUTE_COMPLETED = "BULK_DELETE_ROUTE_COMPLETED";

export const REQUEST_PICKUP_SOURCES = "REQUEST_PICKUP_SOURCES";
export const RECEIVE_PICKUP_SOURCES = "RECEIVE_PICKUP_SOURCES";

export const REQUEST_UPDATE_PICKUP_ORDER = "REQUEST_UPDATE_PICKUP_ORDER";

export const CHANGE_NOTE_COUNT_PICKUP = "CHANGE_NOTE_COUNT_PICKUP";
export const CHANGE_IMAGE_COUNT_PICKUP = "CHANGE_IMAGE_COUNT_PICKUP";
