import { connect } from "react-redux";
import { createZone, updateZone, deleteZone } from "actions/zones.actions";
import ZonesDisplay from "./ZonesDisplay.component";
const mapStateToProps = (state) => ({
  pickupTypes: state.common.data.pickupTypes,
  region: state.regions.activeRegion.region,
  stopsSetting: state.settings.globalSettings.find(
    (setting) => setting.label === "pickup-stops"
  ),
});
const mapDispatchToProps = {
  createZone,
  updateZone,
  deleteZone,
};
export default connect(mapStateToProps, mapDispatchToProps)(ZonesDisplay);
