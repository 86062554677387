import React from "react";

import ConfirmModal from "components/confirm-modal";

const PickupBlockDelete = ({ show, onConfirm, close }) => (
  <ConfirmModal
    show={show}
    onConfirm={onConfirm}
    close={() => close()}
    confirmText="Are you sure you want to delete this pickup block?"
    title="Pickup Blocks"
    hideButton
  />
);

export default PickupBlockDelete;
