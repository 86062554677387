import React, { useEffect } from "react";
import { FieldArray, reduxForm, Field } from "redux-form";
import FormField from "forms/FormField";
import {
  Col,
  Button,
  Form,
  ListGroup,
  ListGroupItem,
  Row,
} from "react-bootstrap";

import { toDate } from "utils/dates";
import TimePicker from "forms/time-picker";
import { connect } from "react-redux";
import Loader from "components/loader";
// https://www.npmjs.com/package/react-loading

const validate = (values) => {
  const errors = {};
  return errors;
};

const renderTimeSlot = (props) => {
  const {
    fields,
    meta: { error, submitFailed },
    pristine,
    submitting,
    // onCancel,
  } = props;

  return (
    <React.Fragment>
      <ListGroup>
        {fields.map((slot, index) => (
          <ListGroupItem key={index}>
            <Form.Group>
              <div className={"d-flex"}>
                <h2 style={{ flex: 1 }}>Number {index + 1} </h2>{" "}
                <a href="#!" onClick={() => fields.remove(index)}>
                  Remove
                </a>
              </div>
              <Row>
                <Col className="form-style" sm={"auto"}>
                  <Field
                    label={"First Stop"}
                    name={`${slot}.first`}
                    type="number"
                    component={FormField}
                    placeholder={"First Stop"}
                  />
                  <Field
                    name={`${slot}.start`}
                    component={TimePicker}
                    step={15} // 15 min increments in dropdown
                    // max={8am-8pm} TODO: Add Range of times that are actually used
                    label="Start Time"
                    date={false}
                    calendar={false}
                    placeholder={"Start Time"}
                  />
                </Col>
                <Col className="form-style" sm={"auto"}>
                  <Field
                    label={"Last Stop"}
                    name={`${slot}.last`}
                    type="number"
                    component={FormField}
                    placeholder={"Last Stop"}
                  />
                  <Field
                    name={`${slot}.end`}
                    component={TimePicker}
                    step={15}
                    label="End Time"
                    date={false}
                    calendar={false}
                    placeholder={"End Time"}
                  />
                </Col>
              </Row>
              <Row>{submitFailed && error && <span>{error}</span>}</Row>
            </Form.Group>
          </ListGroupItem>
        ))}
      </ListGroup>
      <Row>
        <Col sm={"auto"}>
          <Button
            type="submit"
            disabled={pristine || submitting}
            variant="primary"
          >
            {" "}
            {submitting ? <Loader /> : "Save"}
          </Button>
        </Col>
        <Col sm={"auto"}>
          <Button onClick={() => fields.push({})}>Add Another</Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const RouteTimes = (props) => {
  const { handleSubmit, initialValues, initialize } = props;
  useEffect(() => {
    if (initialValues && Object.keys(initialValues).length !== 0) {
      initialize(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <form onSubmit={handleSubmit}>
      <FieldArray
        name={"pickupWindows"}
        component={renderTimeSlot}
        // fieldKey="pickupWindows"
        label="Phone Number*"
        placeholder=""
        addButtonLabel="+ Add Another"
        // props={{'format' : formatPhone, 'normalize': phoneNormalize}}
      />
    </form>
  );
};

const mapStateToProps = (state, ownProps) => {
  if (ownProps.initialvalues && ownProps.initialvalues.pickupWindows) {
    ownProps.initialValues.pickupWindows.map((pickupWindow) => {
      pickupWindow.start = toDate(pickupWindow.start);
      pickupWindow.end = toDate(pickupWindow.start);
      return pickupWindow;
    });
  }

  return {
    ...ownProps,
  };
};
const RouteTimesConnected = connect(mapStateToProps, {})(RouteTimes);
export default reduxForm({ validate })(RouteTimesConnected);
