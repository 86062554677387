import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import Table, { RowActions, checkComponentOriginProp } from "components/table";
import Modal from "components/modal";
import ConfirmModal from "components/confirm-modal";
import PageLayout from "layouts/pages/page-default";
import {
  LocationTypesContext,
  LocationTypesProvider,
} from "contexts/location-types";
import CreateUpdateLocationType from "forms/create-update-location-type";
import Tabs from "../../components/tabs";

const LocationTypesTable = () => {
  const {
    locationTypes,
    locationTypesLoading,
    getLocationTypes,
    createLocationType,
    updateLocationType,
  } = useContext(LocationTypesContext);

  const [showEnabled, setShowEnabled] = useState(true);

  useEffect(() => {
    getLocationTypes({ disabled: !showEnabled });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEnabled]);

  const [create, setCreate] = useState(false);
  const [edit, setEdit] = useState(false);
  const [archive, setArchive] = useState(false);

  const hideModal = () => {
    setCreate(false);
    setEdit(false);
  };

  return (
    <PageLayout
      title="Location Types"
      actionComponents={[
        () => <Button onClick={() => setCreate(true)}>Add New</Button>,
      ]}
    >
      <Tabs active={showEnabled} onChange={setShowEnabled}>
        <Tabs.Tab value={true}>Enabled</Tabs.Tab>
        <Tabs.Tab value={false}>Disabled</Tabs.Tab>
      </Tabs>
      <Table
        columns={[
          {
            Header: "Label",
            accessor: "label",
          },
        ]}
        actions={[
          {
            Header: "Actions",
            Component: (props) =>
              checkComponentOriginProp(props, (original) => (
                <RowActions
                  id={original.id}
                  actions={[
                    {
                      text: "Edit",
                      icon: "edit",
                      onClick: () => setEdit(original),
                    },
                    {
                      text: showEnabled ? "Archive" : "Restore",
                      icon: "archive",
                      onClick: () => setArchive(original),
                    },
                  ]}
                />
              )),
          },
        ]}
        data={locationTypes.filter((type) => type.disabled !== showEnabled)}
        loading={locationTypesLoading}
      />
      <Modal
        show={!!(create || edit)}
        onHide={hideModal}
        title={`${create ? "Create" : "Edit"} Location Type`}
      >
        <CreateUpdateLocationType
          initialValues={edit || {}}
          onSubmit={create ? createLocationType : updateLocationType}
          onSubmitSuccess={hideModal}
          onCancel={hideModal}
        />
      </Modal>

      <ConfirmModal
        title="Please Confirm"
        confirmText={`${showEnabled ? "Archive" : "Restore"} ${archive.label}?`}
        confirmSubText={`Are you sure you'd like to ${
          showEnabled ? "archive" : "restore"
        } this location type?`}
        onConfirm={() =>
          updateLocationType({ ...archive, disabled: !archive.disabled })
        }
        close={() => setArchive(false)}
        show={archive}
        hideButton
      />
    </PageLayout>
  );
};

const LocationTypes = () => {
  return (
    <LocationTypesProvider>
      <LocationTypesTable />
    </LocationTypesProvider>
  );
};

export default LocationTypes;
