import React, { useState } from "react";
import { pickupsNotesAPI } from "api/pickups";
import Tabs from "../tabs";
import CreateUpdatePickup from "forms/create-update-pickup";
import PickupImageUploadForm from "forms/pickup-image-upload-form";
import Notes from "forms/notes";
import Timeline from "pages/donors/donor-single/donor-timeline";

const PickupModal = (props) => {
  const [currentTab, setCurrentTab] = useState(
    props.initTab ? props.initTab : "details"
  );
  const [imagesBadge, setImagesBadge] = useState(0);
  const [notesBadge, setNotesBadge] = useState(0);

  if (!props?.initialValues?.id) return <CreateUpdatePickup {...props} />;

  const tabs = {
    details: <CreateUpdatePickup {...props} />,
    images: (
      <PickupImageUploadForm
        pickupId={props.initialValues.id}
        setBadge={setImagesBadge}
        onChange={() => {
          const { requestCustomerActions } = props;
          requestCustomerActions && requestCustomerActions();
        }}
      />
    ),
    notes: (
      <Notes
        entityId={props.initialValues.id}
        notesAPI={pickupsNotesAPI}
        setBadge={setNotesBadge}
        onChange={() => {
          const { requestCustomerActions } = props;
          requestCustomerActions && requestCustomerActions();
        }}
      />
    ),
    timeline: <Timeline pickupMode pikupId={props.initialValues.id} />,
  };

  return (
    <div>
      <div className={"pt-3 px-4"}>
        <Tabs active={currentTab} onChange={setCurrentTab}>
          <Tabs.Tab value="details">Details</Tabs.Tab>
          <Tabs.Tab value="images" badge={imagesBadge}>
            Images
          </Tabs.Tab>
          <Tabs.Tab value="notes" badge={notesBadge}>
            Notes
          </Tabs.Tab>
          <Tabs.Tab value="timeline">Timeline</Tabs.Tab>
        </Tabs>
      </div>
      {Object.entries(tabs).map(([tab, component]) => (
        <div
          className={currentTab !== tab ? "d-none bg-light" : "bg-light"}
          key={tab}
        >
          {component}
        </div>
      ))}
    </div>
  );
};

export default PickupModal;
