import request from "utils/request";

export const REQUEST_SETTINGS = "REQUEST_SETTINGS";
export const RECEIVE_SETTINGS = "RECEIVE_SETTINGS";
export const DELETE_SETTING = "DELETE_SETTING";
export const CLEAR_SETTINGS = "CLEAR_SETTINGS";
export const SETTINGS_FETCH_FAIL = "SETTINGS_FETCH_FAIL";

export const requestSettings = () => {
  return (dispatch) => {
    dispatch({ type: REQUEST_SETTINGS });
    return request(`/v2/public/settings`)
      .then((res) => {
        const { settings } = res.data;
        dispatch(receiveSettings({ settings }));
      })
      .catch(() => {
        dispatch(handleFailedSettingsFetch());
      });
  };
};

export const updateSetting = ({ label, setting }) => {
  return (dispatch) => {
    return request({
      url: `/settings/`,
      method: "put",
      data: {
        label,
        setting,
      },
    }).then((res) => {
      const { settings } = res.data;
      dispatch(receiveSettings({ settings }));
    });
  };
};

export const receiveSettings = ({ settings }) => {
  return {
    type: RECEIVE_SETTINGS,
    settings,
    receivedAt: new Date(),
  };
};

export const handleFailedSettingsFetch = () => {
  return {
    type: SETTINGS_FETCH_FAIL,
  };
};
