import React, { useContext } from "react";

import TabContext from "./tab-context";
import Loader from "../loader";
import { REACT_APP_MP_PRIMARY_COLOR } from "../../config";
import Icon from "../icon";
import { Badge } from "react-bootstrap";

const Tab = ({
  value,
  children,
  badge = null,
  special = false,
  loading = false,
  removable = true,
}) => {
  const { eq, active, onChange, onRemove } = useContext(TabContext);

  return (
    <li
      key="selected-donors"
      role="presentation"
      className={
        "nav-item me-2" +
        (eq(value, active) ? " active " : "") +
        (loading ? "donor-search-tab--loading " : "")
      }
      onClick={() => onChange(value)}
    >
      <a
        className={
          "nav-link d-flex" +
          (eq(value, active) ? " active " : "") +
          (loading ? "" : "")
        }
        {...(special ? { id: "" } : {})}
        role="tab"
        aria-selected={eq(value, active)}
      >
        {loading || badge === -1 ? (
          <>
            {children}
            <span className="ms-1 badge d-flex bg-primary align-items-center">
              <Loader className={"p-0 ms-2"} width="1em" color={"#fff"} />
            </span>
          </>
        ) : (
          children
        )}

        {!loading && onRemove && removable && (
          <span className={"ms-4 d-inline-block"}>
            <Icon
              type="close"
              className={"ms-2"}
              onClick={(e) => {
                e.stopPropagation();
                onRemove(value);
              }}
            />
          </span>
        )}

        {!loading && typeof badge === "number" && badge >= 0 && (
          <span className="ms-1 badge d-flex bg-primary align-items-center">
            {badge}
          </span>
        )}
      </a>
    </li>
  );
};

export default Tab;
