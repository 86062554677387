import React, { useState } from "react";
import { Button, Collapse } from "react-bootstrap";
const Collapsible = ({ list, DisplayComponent, numItemsToShow = 1 }) => {
  const length = list?.length;
  const itemsToShow = [...Array(numItemsToShow).keys()];
  const [showAllNotes, setShowAllNotes] = useState(false);
  if (!(Array.isArray(list) && length)) {
    return null;
  }

  return (
    <>
      <Collapse in={showAllNotes}>
        <div>
          {list.map((item, idx) => {
            //hide some num of items
            if (idx >= length - numItemsToShow) {
              return null;
            }
            return <DisplayComponent {...item} />;
          })}
        </div>
      </Collapse>
      {itemsToShow.map((num) => (
        <DisplayComponent {...list[length - num - 1]} />
      ))}
      {length > numItemsToShow && (
        <div className="d-grid mb-2">
          <Button
            onClick={() => {
              setShowAllNotes(!showAllNotes);
            }}
            variant="outline-primary"
            size="sm"
          >
            {!showAllNotes
              ? `Show +${length - numItemsToShow} More Notes`
              : "Hide"}
          </Button>
        </div>
      )}
    </>
  );
};

export default Collapsible;
