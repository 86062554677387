import React from "react";

const DefaultPage = (props) => (
  <>
    <div
      style={{ minHeight: "2.5rem" }}
      className="d-flex justify-content-between align-items-center mb-3"
    >
      {props.title ? <div class={"h2 mb-0"}>{props.title}</div> : null}
      {props.actionComponents && props.actionComponents.length > 0 ? (
        <div className={"title-action"}>
          {props.actionComponents.map((ActionComponent, idx) => (
            <ActionComponent key={idx} {...props.actionComponentProps?.[idx]} />
          ))}
        </div>
      ) : null}
    </div>
    {props.children}
  </>
);
export default DefaultPage;
