import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Form,
  FormCheck,
  FormControl,
  InputGroup,
  Button,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import querystring from "query-string";
// import history from "../../../history";
import Icon from "components/icon";
import PlacesAutocompleteField from "forms/PlacesAutocompleteField";
import { setSearchQuery } from "actions/donor-search.actions";

const DonorSearchForm = ({
  setSearchQuery,
  normalizeQuery,
  searchCustomerResults,
  openTabs,
}) => {
  const [addressKey, setAddressKey] = useState(0);
  const [searchType, setSearchType] = useState("account");
  const location = useLocation();
  const navigate = useNavigate();

  const clearAddress = () => {
    // This is a bit of a hack to clear the address input
    setAddressKey(addressKey + 1);
  };

  const searchChanged = (e) => {
    clearAddress();

    const query = e.target.value;

    setSearchQuery({ query: normalizeQuery(query) });
  };

  // Todo, move to connect function
  const searchSubmit = (e, searchQueryProp) => {
    if (e) {
      e.preventDefault();
    }
    clearAddress();

    const { searchQuery } = searchCustomerResults;
    const value = searchQueryProp?.value || searchQuery?.value;

    // hard coding the limit of users returned from the server to be 1000
    // we will want to properly handle pagination in the future

    // Don't submit of the field is empty
    if (!value) {
      return;
    }

    // Update the query parameter
    navigate(
      location.pathname +
        "?" +
        querystring.stringify(
          {
            query: [
              ...openTabs.map((tab) => tab.query),
              JSON.stringify({
                type: searchType,
                name: searchType !== "address" ? value : value.mapAddress,
              }),
            ],
          },
          { arrayFormat: "bracket" }
        )
    );
  };

  const { searchQuery } = searchCustomerResults;

  return (
    <Form class={"bg-white p-4 rounded"} onSubmit={searchSubmit}>
      <Form.Group>
        <InputGroup>
          {searchType === "address" ? (
            <PlacesAutocompleteField
              meta={{}}
              onPlaceSelected={async (query) => {
                await setSearchQuery({ query });
                searchSubmit(null, {
                  searchType: "address",
                  value: query,
                  type: [4, 5],
                });
              }}
              input={{
                key: addressKey,
                className: "form-control",
                autoFocus: true,
              }}
              fieldOnly={true}
            />
          ) : (
            <>
              <FormControl
                className=""
                type="text"
                value={
                  typeof searchQuery.value === "string" ? searchQuery.value : ""
                }
                onChange={searchChanged}
                placeholder={
                  {
                    account: "Phone, Last Name, User ID, or Email",
                    pickupId: "Pickup ID",
                  }[searchType]
                }
                autoFocus
              />
              <Button
                className={"border"}
                variant="light"
                onClick={searchSubmit}
              >
                <Icon type="search" />
              </Button>
            </>
          )}
        </InputGroup>
      </Form.Group>
      <Form.Group className={"inline-form-row flex-row pl-2 ms-2"}>
        <FormCheck
          type="radio"
          name="search-type-selector"
          id="account-search-selector"
          label="Account"
          checked={searchType === "account"}
          onChange={(e) => setSearchType("account")}
        />
        <FormCheck
          type="radio"
          name="search-type-selector"
          id="address-search-selector"
          label="Address"
          checked={searchType === "address"}
          onChange={(e) => setSearchType("address")}
        />
        <FormCheck
          type="radio"
          name="search-type-selector"
          id="pickup-id-search-selector"
          label="Pickup ID"
          checked={searchType === "pickupId"}
          onChange={(e) => setSearchType("pickupId")}
        />
      </Form.Group>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  openTabs: state.searchCustomers.searchTabs,
});

const mapDispatchToProps = {
  setSearchQuery,
};

export default connect(mapStateToProps, mapDispatchToProps)(DonorSearchForm);
