import { updateSetting } from "actions/settings.actions";
import { connect } from "react-redux";

import TimeWindows from "./time-windows.component";

const mapStateToProps = (state, ownProps) => {
  return {
    pickupTypes: state.common.data.pickupTypes,
    isLoading: state.settings.isFetching,
    globalSettings: state.settings.globalSettings,
  };
};

const mapDispatchToProps = {
  updateSetting,
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeWindows);
