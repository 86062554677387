import React from "react";

import { dateOnly } from "utils/dates";
import Modal from "components/modal";
import Box from "components/box";
import CreateUpdateInternalUserForm from "forms/create-update-internal-user";
import ChangePassword from "forms/change-password";
import Loader from "components/loader";

// Import React Table
import Table, { RowActions, checkComponentOriginProp } from "components/table";
import { UserListContext } from "../../../contexts/user-list";

class UserTable extends React.Component {
  constructor(props) {
    super(props);

    const data = this.transformDataForTable(props.data, props.columns);
    this.state = {
      data,
      columns: this.getColumns(data),
      editing: false,
      changingPassword: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // If the data was changed
    if (nextProps.data !== prevState.data) {
      return { data: nextProps.data };
    } else return null;
  }

  // Takes in an object and the values that should be shown on the table
  transformDataForTable = (data, columns) => {
    const doesTableHaveData = data && data.length > 0;
    const tableData = [];
    if (doesTableHaveData) {
      data.forEach((d) => {
        const rowData = {};

        // Loop through array of objects to display
        Object.keys(d).forEach((key) => {
          // If the key of one of the fields matches one of the table fields
          const includeColumn = columns.find((item) => item?.field === key);
          if (!!includeColumn) {
            rowData[key] = includeColumn;
          }
        });
        tableData.push(rowData);
      });
    }

    return tableData;
  };

  componentDidUpdate(prevProps, prevState) {
    // If the data passed in was changed, we need to rehydrate the local state
    if (prevProps.data !== this.props.data) {
      // Re-insert data in to local state
      const data = this.transformDataForTable(
        this.props.data,
        this.props.columns
      );
      this.setState({
        data,
        columns: this.getColumns(data),
      });
    }
  }

  editRow(id) {
    this.setState({ editing: id });
  }

  closeEdit() {
    this.setState({ editing: false });
  }

  changePassword(id) {
    this.setState({ changingPassword: id });
  }

  closeChangePassword() {
    this.setState({ changingPassword: false });
  }
  // [0: {key: {}}]
  // Get columns just takes the key values of the first object in the array.
  getColumns(data) {
    const doesTableHaveData = data && data.length > 0;
    const columns = [];
    // const columnsWithFilters = ["email", "fname", "lname", "region"];
    if (doesTableHaveData) {
      // Use the keys from the first data object
      const sample = data[0];
      Object.keys(sample).forEach((key) => {
        let filter = sample[key]?.filter;
        let label = sample[key]?.label || key;
        // Do not include ID column
        if (key !== "id") {
          columns.push({
            disableFilters: !filter,
            accessor: key,
            Header: label,
            Cell: (row) => (
              <>
                {key === "profileImage" ? (
                  <img
                    className={"rounded-circle"}
                    width="50"
                    height="50"
                    src={row.value}
                    alt=""
                  />
                ) : key !== "createdAt" ? (
                  !row.value && key === "region" ? (
                    "All Regions"
                  ) : (
                    row.value
                  )
                ) : (
                  dateOnly(row.value)
                )}
              </>
            ),
          });
        }
      });
    }
    return columns;
  }

  render() {
    const { columns, editing } = this.state;
    const { loading, data } = this.props;

    const actions = [
      {
        disableFilters: true,
        Header: "Actions",
        Component: (props) =>
          checkComponentOriginProp(props, (original) => (
            <RowActions
              id={original.id}
              actions={[
                {
                  text: "Edit",
                  icon: "edit",
                  onClick: () => this.editRow(original.id),
                },
                {
                  text: "Change Password",
                  icon: "password",
                  onClick: () => this.changePassword(original.id),
                },
              ]}
            />
          )),
      },
    ];

    const showTable = loading || (data && data.length > 0);

    if (loading) {
      return (
        <Box>
          <Loader />
        </Box>
      );
    }

    return showTable ? (
      <div>
        <Table
          data={data}
          loading={loading} // Display the loading overlay when we need it
          columns={columns}
          actions={actions}
        />

        <UserListContext.Consumer>
          {({ UserProvider }) => (
            <>
              <Modal
                show={!!this.state.editing}
                title={"Edit Form"}
                onHide={() => {
                  this.closeEdit();
                }}
              >
                <UserProvider id={editing}>
                  <CreateUpdateInternalUserForm
                    editing={true}
                    onCancel={() => {
                      this.closeEdit();
                    }}
                    onSubmitSuccess={() => {
                      this.closeEdit();
                    }}
                  />
                </UserProvider>
              </Modal>

              <Modal
                show={!!this.state.changingPassword}
                title="Change Password"
                onHide={() => {
                  this.closeChangePassword();
                }}
              >
                <UserProvider id={this.state.changingPassword}>
                  <ChangePassword
                    userId={this.state.changingPassword}
                    onCancel={() => {
                      this.closeChangePassword();
                    }}
                    onSubmitSuccess={() => {
                      this.closeChangePassword();
                    }}
                  />
                </UserProvider>
              </Modal>
            </>
          )}
        </UserListContext.Consumer>
      </div>
    ) : (
      <Box>No Users</Box>
    );
  }
}

export default UserTable;
