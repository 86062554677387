import {
  REQUEST_STATS,
  RECEIVE_STATS,
  REQUEST_PICKUPS_SCHEDULED_ON_STATS,
  RECEIVE_PICKUPS_SCHEDULED_ON_STATS,
  CLEAR_PICKUPS_SCHEDULED,
} from "actions/reports.actions";

function stats(
  state = { isFetching: false, loaded: false, stats: [] },
  action
) {
  switch (action.type) {
    case REQUEST_STATS:
      return {
        ...state,
        loaded: false,
        isFetching: true,
      };
    case RECEIVE_STATS:
      return {
        ...state,
        loaded: true,
        isFetching: false,
        count: action.count,
        sources: action.sources,
        statuses: action.statuses,
        pickupTypes: action.pickupTypes,
        days: action.days,
        lastUpdated: action.receivedAt,
      };
    case CLEAR_PICKUPS_SCHEDULED:
      return {
        ...state,
        count: 0,
        furnitureCount: 0,
        standardCount: 0,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
}

function pickupsScheduledOnStats(
  state = { isFetching: false, loaded: false, stats: [] },
  action
) {
  switch (action.type) {
    case REQUEST_PICKUPS_SCHEDULED_ON_STATS:
      return {
        ...state,
        loaded: false,
        isFetching: true,
      };
    case RECEIVE_PICKUPS_SCHEDULED_ON_STATS:
      return {
        ...state,
        loaded: true,
        isFetching: false,
        count: action.count,
        sources: action.sources,
        statuses: action.statuses,
        pickupTypes: action.pickupTypes,
        days: action.days,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
}

const reportReducer = { stats, pickupsScheduledOnStats };
export default reportReducer;
