import React from "react";
import List from "components/list";

const DefaultItemComponent = ({ children, props = {} }) => {
  return (
    <List>
      <List.Item {...props} disabled defaultColor>
        {children}
      </List.Item>
    </List>
  );
};

const RenderUpdateBox = ({ after, before }) => {
  return (
    <>
      <div style={{ opacity: "0.75" }}>{before}</div>
      {after}
    </>
  );
};

const RenderOnCreate = ({ props, children }) => {
  return <DefaultItemComponent props={props}>{children}</DefaultItemComponent>;
};

const RenderOnUpdate = ({
  propsBefore,
  propsAfter,
  childrenBefore,
  childrenAfter,
}) => (
  <RenderUpdateBox
    before={
      <DefaultItemComponent props={propsBefore}>
        {childrenBefore}
      </DefaultItemComponent>
    }
    after={
      <DefaultItemComponent props={{ ...propsAfter, updated: true }}>
        {childrenAfter}
      </DefaultItemComponent>
    }
  />
);

const RenderOnDelete = ({ props, children }) => {
  return (
    <DefaultItemComponent props={{ ...props, deleted: true }}>
      {children}
    </DefaultItemComponent>
  );
};

export const SelectElement = ({
  action,
  propsBefore,
  propsAfter,
  childrenBefore,
  childrenAfter,
}) => {
  if (action === "create") {
    return <RenderOnCreate props={propsAfter}>{childrenAfter}</RenderOnCreate>;
  }
  if (action === "update") {
    return (
      <RenderOnUpdate
        propsAfter={propsAfter}
        propsBefore={propsBefore}
        childrenAfter={childrenAfter}
        childrenBefore={childrenBefore}
      />
    );
  }
  if (action === "delete" || action === "destroy") {
    return (
      <RenderOnDelete props={propsBefore}>{childrenBefore}</RenderOnDelete>
    );
  }
};
