import {
  RECEIVE_COMMON,
  REQUEST_COMMON,
  FAILED_COMMON,
} from "actions/common.actions";

const defaultState = {
  loaded: false,
  isFetching: false,
  failed: false,
  dataMap: {
    languages: {},
    leadSource: {},
    locationType: {},
    noteCategories: {},
    pickupImageCategories: {},
    pickupSources: {},
    pickupStatuses: {},
    pickupStatusDescriptions: {},
    pickupTypes: {},
    userType: {},
  },
  data: {
    languages: [],
    leadSource: [],
    locationType: [],
    noteCategories: [],
    pickupImageCategories: [],
    pickupSources: [],
    pickupStatuses: [],
    pickupStatusDescriptions: [],
    pickupTypes: [],
    userType: [],
  },
};

const common = (state = defaultState, action = {}) => {
  switch (action.type) {
    case REQUEST_COMMON:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_COMMON:
      const map = {};
      Object.keys(action.data).forEach((key) => {
        const mappedItems = {};
        action.data[key].forEach((item) => {
          mappedItems[item.id] = item;
        });
        map[key] = mappedItems;
      });
      return {
        ...state,
        loaded: true,
        isFetching: false,
        data: action.data,
        dataMap: map,
        failed: false,
      };
    case FAILED_COMMON:
      return {
        ...state,
        loaded: false,
        isFetching: false,
        failed: true,
      };

    default:
      return state;
  }
};

export default common;
