import React from "react";
import { formatTime } from "utils/helpers";
import { Button } from "react-bootstrap";
import Icon from "components/icon";

const Calendar = ({
  time,
  onClickRight,
  onClickLeft,
  disabledLeft,
  disabledRight,
}) => {
  const buttonStyleDefault = {
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderColor: "#E5E5E5",
    borderRadius: "3px",
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          maxWidth: "400px",
          width: "100%",
        }}
      >
        <Button
          style={buttonStyleDefault}
          variant="link"
          onClick={onClickLeft}
          disabled={disabledLeft}
        >
          <Icon type="angleLeft" />
        </Button>
        <div style={{ padding: "0 10px" }}>
          <strong> {formatTime(time)}</strong>
        </div>
        <Button
          style={buttonStyleDefault}
          variant="link"
          onClick={onClickRight}
          disabled={disabledRight}
        >
          <Icon type="angleRight" />
        </Button>
      </div>
    </div>
  );
};

export default Calendar;
