import React, { useState, useEffect } from "react";
import querystring from "query-string";
import DateTimePicker from "react-widgets/DatePicker";
import Localization from "react-widgets/Localization";
import { DateLocalizer } from "react-widgets/IntlLocalizer";
import {
  getTomorrow,
  getYesterday,
  formatDateHyphenatedYearFirst,
  toDate,
  checkForValidDate,
  customFormat,
  getDateWithoutTimeZone,
} from "utils/dates";

import { useNavigate, useLocation } from "react-router-dom";

class DateByDayDateLocalizer extends DateLocalizer {
  constructor() {
    super("en-US", 0);

    this.date = (date, format) => customFormat(date, format);
  }
}

const DateDayNav = ({ onChange, date }) => {
  const [error, setError] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const addDateToURL = (date, replace = false) => {
    // Add the passed in date to the URL as a parameter
    navigate(location.pathname + "?" + querystring.stringify({ date }), {
      replace: !!replace,
    });
  };

  useEffect(() => {
    const dateFromURL = querystring.parse(location.search).date;
    if (dateFromURL) {
      onChange(toDate(dateFromURL));
    } else {
      const defaultDate = formatDateHyphenatedYearFirst(new Date());
      addDateToURL(defaultDate, true);
      onChange(defaultDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNavigation = (newDate) => {
    addDateToURL(formatDateHyphenatedYearFirst(newDate));
    onChange(newDate);
  };

  const _clickForward = () => {
    const newDate = getTomorrow(date);
    // this.setState({'date': newDate});
    handleNavigation(newDate);
  };

  const _clickBack = () => {
    const newDate = getYesterday(date);
    // this.setState({'date': newDate});
    handleNavigation(newDate);
  };

  const handleEnterPress = (e) => {
    const newDate = e.target.value;
    const isDateValid = checkForValidDate({ date: newDate });
    const isEnterButton = e.key === "Enter";
    if (isEnterButton) {
      if (isDateValid) {
        handleNavigation(newDate);
        setError(false);
      } else {
        setError(true);
      }
    }
  };

  const handleChange = (date) => {
    const isDateValid = checkForValidDate({ date });
    if (isDateValid) {
      handleNavigation(date);
      setError(false);
    } else {
      setError(true);
    }
  };
  return (
    <div className="date-by-day-container">
      <div className="date-picker-arrow-container pull-left">
        <button
          type="button"
          onClick={_clickBack}
          className="fc-prev-button fc-button fc-state-default fc-corner-left fc-corner-right"
        >
          <span className="fc-icon fc-icon-left-single-arrow"></span>
        </button>
        <button
          type="button"
          onClick={_clickForward}
          className="fc-next-button fc-button fc-state-default fc-corner-left fc-corner-right"
        >
          <span className="fc-icon fc-icon-right-single-arrow"></span>
        </button>
      </div>
      <div className="pull-left" style={{ flex: 1 }}>
        <Localization date={new DateByDayDateLocalizer()}>
          <DateTimePicker
            value={toDate(getDateWithoutTimeZone(date), true)}
            valueDisplayFormat="MM/DD/YYYY - dddd"
            onChange={handleChange}
            // returnValue={"range"}
            valueEditFormat="MM/DD/YYYY"
            onKeyPress={handleEnterPress}
          />
        </Localization>
        {error && <span>Invalid date</span>}
      </div>
    </div>
  );
};

export default DateDayNav;
