import {
  REQUEST_ZONES,
  RECEIVE_ZONES,
  CREATE_ZONE,
  CREATE_ZONE_COMPLETED,
  UPDATE_ZONE,
  UPDATE_ZONE_COMPLETED,
  DELETE_ZONE,
  DELETE_ZONE_COMPLETED,
  REQUEST_PICKUP_BLOCKS,
  RECEIVE_PICKUP_BLOCKS,
  CLEAR_PICKUP_BLOCKS,
  CREATE_PICKUP_BLOCK,
  CREATE_PICKUP_BLOCK_COMPLETED,
  UPDATE_PICKUP_BLOCK,
  UPDATE_PICKUP_BLOCK_COMPLETED,
  DELETE_PICKUP_BLOCK,
  DELETE_PICKUP_BLOCK_COMPLETED,
} from "actions/zones.actions";

function zones(
  state = { isFetching: false, loaded: false, zones: [] },
  action
) {
  switch (action.type) {
    case REQUEST_ZONES:
    case UPDATE_ZONE:
    case CREATE_ZONE:
    case DELETE_ZONE:
      return {
        ...state,
        loaded: false,
        isFetching: true,
      };
    case RECEIVE_ZONES:
      return {
        ...state,
        loaded: true,
        isFetching: false,
        zones: action.zones,
        lastUpdated: action.receivedAt,
      };
    case UPDATE_ZONE_COMPLETED:
      return {
        ...state,
        loaded: true,
        isFetching: false,
        zones: state.zones.map((zone) => {
          if (zone.id === action.zone.id) {
            return action.zone;
          }
          return zone;
        }),
        lastUpdated: action.receivedAt,
      };
    case CREATE_ZONE_COMPLETED:
      return {
        ...state,
        loaded: true,
        isFetching: false,
        zones: state.zones.push(action.zone) && state.zones,
        lastUpdated: action.receivedAt,
      };
    case DELETE_ZONE_COMPLETED:
      return {
        ...state,
        loaded: true,
        isFetching: false,
        lastUpdated: action.receivedAt,
        zones: state.zones.filter((zone) => {
          return zone.id !== action.zoneId;
        }),
      };
    default:
      return state;
  }
}

const pickupBlocksInitialState = {
  isFetching: false,
  loaded: false,
  pickupBlocks: [],
};

function pickupBlocks(state = pickupBlocksInitialState, action) {
  switch (action.type) {
    case REQUEST_PICKUP_BLOCKS:
      return {
        ...state,
        loaded: false,
        isFetching: true,
      };
    case RECEIVE_PICKUP_BLOCKS:
      return {
        ...state,
        loaded: true,
        isFetching: false,
        pickupBlocks: action.pickupBlocks,
        lastUpdated: action.receivedAt,
        zipCodeFound: action.zipCodeFound,
      };

    case CLEAR_PICKUP_BLOCKS:
      return pickupBlocksInitialState;

    case DELETE_PICKUP_BLOCK:
      return {
        ...state,
        isFetching: true,
        pickupBlocks: state.pickupBlocks.filter((pickupBlock) => {
          return pickupBlock.id !== action.pickupBlockId;
        }),
      };
    case DELETE_PICKUP_BLOCK_COMPLETED:
      return {
        ...state,
        loaded: true,
        isFetching: false,
        lastUpdated: action.receivedAt,
      };
    case CREATE_PICKUP_BLOCK:
      return {
        ...state,
        isFetching: true,
      };
    case CREATE_PICKUP_BLOCK_COMPLETED:
      return {
        ...state,
        loaded: true,
        isFetching: false,
        pickupBlocks: [...state.pickupBlocks, ...action.pickupBlock],
        lastUpdated: action.receivedAt,
      };
    case UPDATE_PICKUP_BLOCK:
      return {
        ...state,
        isFetching: true,
        pickupBlocks: state.pickupBlocks.map((pickupBlock) => {
          if (pickupBlock.id === action.pickupBlock.id) {
            return action.pickupBlock;
          }
          return pickupBlock;
        }),
      };
    case UPDATE_PICKUP_BLOCK_COMPLETED:
      return {
        ...state,
        loaded: true,
        isFetching: false,
        pickupBlocks: state.pickupBlocks.map((pickupBlock) => {
          if (pickupBlock.id === action.pickupBlock.id) {
            return action.pickupBlock;
          }
          return pickupBlock;
        }),
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
}
const zoneReducer = { zones, pickupBlocks };
export default zoneReducer;
