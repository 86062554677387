import React from "react";
import PropTypes from "prop-types";
import Box, { BoxTitle } from "components/box";
import WithLoader from "hoc/with-loader";
import { DateRangeChart } from "components/charts";

const NewUsersCount = (props) => {
  NewUsersCount.propTypes = {
    loading: PropTypes.bool.isRequired,
    newUsersDataset: PropTypes.array.isRequired,
    newUsersLabels: PropTypes.array.isRequired,
  };

  const { loading, newUsersDataset, newUsersLabels } = props;

  return (
    <Box>
      <BoxTitle>New User Registrations</BoxTitle>
      <WithLoader isLoading={loading}>
        <DateRangeChart dataset={newUsersDataset} labels={newUsersLabels} />
      </WithLoader>
    </Box>
  );
};

export default NewUsersCount;
