import { useEffect, useState } from "react";
import { getNotificationTemplatePreview } from "api/notification-templates";
import WithLoader from "hoc/with-loader";
import { REACT_APP_MP_API_URL } from "config";

const EmailPreview = ({ emailPreview }) => {
  const [loadingSubject, setLoadingSubject] = useState(false);
  const [loadingBody, setLoadingBody] = useState(false);
  const [subject, setSubject] = useState("");

  // Get the preview email subject when preview changes
  useEffect(() => {
    if (emailPreview) {
      setLoadingSubject(true);
      setLoadingBody(true);
      getNotificationTemplatePreview(emailPreview, "emailSubject")
        .then(setSubject)
        .then(() => setLoadingSubject(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailPreview]);

  return (
    <WithLoader isLoading={loadingSubject || loadingBody} cssHide={true}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        <div style={{ width: "90%" }}>
          <div style={{ paddingBottom: 20 }}>
            <strong>Subject:</strong> {subject}
          </div>
          <iframe
            title="Notification Template Email Preview"
            src={`${REACT_APP_MP_API_URL}/notificationTemplates/${emailPreview.slug}/render/emailHtml?json=false`}
            style={{
              border: "3px solid #eee",
              width: "100%",
              minHeight: "80vh",
            }}
            onLoad={() => setLoadingBody(false)}
          />
        </div>
      </div>
    </WithLoader>
  );
};

export default EmailPreview;
