import { connect } from "react-redux";
import { addImageCountPickup } from "actions/routes.actions";

import PickupImageForm from "./pickup-image-upload-form.component";

const mapStateToProps = (state) => {
  return {
    pickupImageCategories: state.common.data.pickupImageCategories,
  };
};

const mapDispatchToProps = {
  addImageCountPickup,
};

export default connect(mapStateToProps, mapDispatchToProps)(PickupImageForm);
