import {
  REQUEST_CUSTOMER_ACTIONS,
  RECEIVE_CUSTOMER_ACTIONS,
  REQUEST_CUSTOMER_ACTION,
  RECEIVE_CUSTOMER_ACTION,
  CLEAR_CUSTOMER_ACTIONS,
} from "./customer-timeline.actions";

const defaultCustomerActionState = {
  isFetching: false,
  loaded: false,
  actions: [],
  openedAction: null,
  isFetchingOpenedAction: false,
  loadedOpenedAction: false,
  limit: 50,
  page: 1,
  totalPages: 1,
};
function customerActions(state = defaultCustomerActionState, action) {
  switch (action.type) {
    case REQUEST_CUSTOMER_ACTIONS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_CUSTOMER_ACTIONS:
      return {
        ...state,
        loaded: true,
        isFetching: false,
        actions: [...state.actions, ...action.actions],
        lastUpdated: action.receivedAt,
        page: action.pagination.currentPage,
        totalPages: action.pagination.totalPages,
      };

    case REQUEST_CUSTOMER_ACTION:
      return {
        ...state,
        isFetchingOpenedAction: true,
      };

    case RECEIVE_CUSTOMER_ACTION:
      return {
        ...state,
        loadedOpenedAction: true,
        isFetchingOpenedAction: false,
        openedAction: action.data,
        lastUpdated: action.receivedAt,
      };
    case CLEAR_CUSTOMER_ACTIONS:
      return { ...defaultCustomerActionState };

    default:
      return state;
  }
}

export default customerActions;
