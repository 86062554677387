import { useCallback, useEffect, useRef } from "react";

import L from "leaflet";
import { FeatureGroup } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";

import { chunkIntersect } from "./chunkGridUtils";

//this component uses useRef because in other case handleRectangularSelection function will not be updated with updated props, useCallbeck does not help

const EditControlComponent = ({
  scratchZones,
  loadedChunks,
  activeZoneId,
  refMap,
  setScratchZones,
  drawOptions,
  editOptions,
}) => {
  const loadedChunksRef = useRef([]);
  const scratchZonesRef = useRef([]);
  const activeZoneIdRef = useRef(null);
  const refMapRef = useRef(null);
  const setScratchZonesRef = useRef(null);

  useEffect(() => {
    setScratchZonesRef.current = setScratchZones;
  }, [setScratchZones]);

  useEffect(() => {
    scratchZonesRef.current = scratchZones;
  }, [scratchZones]);

  useEffect(() => {
    loadedChunksRef.current = loadedChunks;
  }, [loadedChunks]);

  useEffect(() => {
    activeZoneIdRef.current = activeZoneId;
  }, [activeZoneId]);

  useEffect(() => {
    refMapRef.current = refMap;
  }, [refMap]);

  // Select zip codes with the rectangular selection tool
  const handleRectangularSelection = useCallback(
    ({ layer: selectionLayer }) => {
      // Use refs to get the latest values for dependencies
      const latestScratchZones = scratchZonesRef.current;
      const latestLoadedChunks = loadedChunksRef.current;
      const latestActiveZoneId = activeZoneIdRef.current;
      const latestRefMap = refMapRef.current;
      const latestSetScratchZones = setScratchZonesRef.current;

      // Remove the selection layer
      selectionLayer._map.removeLayer(selectionLayer);

      const activeScratchZone = latestScratchZones.find(
        ({ id }) => id === latestActiveZoneId
      );

      if (!activeScratchZone) return;

      const selectedZipCodes = new Set();

      for (const chunk of latestLoadedChunks) {
        if (!chunkIntersect(chunk.coords, latestRefMap.getBounds())) continue;

        for (const feature of chunk.data.features) {
          const coords = feature.geometry.coordinates.flat(Infinity);
          const zipBounds = L.latLngBounds(
            [coords[1], coords[0]],
            [coords[3], coords[2]]
          );
          for (let i = 4; i < coords.length; i++) {
            zipBounds.extend(coords[i + 1], coords[i]);
          }

          if (selectionLayer.getBounds().intersects(zipBounds)) {
            selectedZipCodes.add(feature.properties.ZCTA5CE10);
          }
        }
      }

      // Update scratch zones using the ref to the current zones
      const updatedScratchZones = [
        ...latestScratchZones.filter(({ id }) => id !== latestActiveZoneId),
        {
          ...activeScratchZone,
          zipCodes: [
            ...activeScratchZone.zipCodes.filter(
              (zip) => !selectedZipCodes.has(zip)
            ),
            ...selectedZipCodes,
          ],
        },
      ];

      latestSetScratchZones(updatedScratchZones); // Update state to re-render
    },
    []
  );

  return (
    <FeatureGroup>
      <EditControl
        draw={drawOptions}
        edit={editOptions}
        onCreated={handleRectangularSelection}
      />
    </FeatureGroup>
  );
};

export default EditControlComponent;
