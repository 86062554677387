import React, { useState } from "react";
import CompareActions from "pages/donors/donor-single/compare-actions";
import { Button } from "react-bootstrap";
import Calendar from "./calendar-component";
import { SelectElement } from "./components";

import { DonorDisplay } from "../../donor-info";
import { listItemLocationProps } from "../../donor-locations";
import { listItemPickupProps } from "../../donor-pickups";
import NotificationSettings from "forms/notification-settings";
import { Note } from "forms/notes";
import { detailedDiff } from "deep-object-diff";
import { convertCamelCaseToReadable } from "utils/helpers";
import { iso8601Pattern, formatTime } from "utils/dates";

const transformObjectEntriesToArray = (obj) => {
  const resultString = Object.entries(obj).map(([key, value]) => {
    if (typeof value === "object" && value !== null) {
      return null;
    }
    return `${key}: ${value}`;
  });
  return resultString.filter((string) => !!string);
};

const RenderLocationBox = ({ data }) => {
  const { before, after, action } = data;

  return (
    <SelectElement
      action={action}
      propsBefore={listItemLocationProps(before)}
      propsAfter={listItemLocationProps(after)}
    />
  );
};

const RenderPickupBox = ({ data, pickupStatusesMap }) => {
  const {
    before,
    after,
    action,
    readableBefore,
    readableAfter,
    pickupStatuses,
  } = data;

  const defaultListItemPickupProps = (data, main) => {
    if (!data || !Object.keys(data).length) return {};
    if (data.statusId) data.pickupStatus = pickupStatusesMap[data.statusId];
    return {
      ...listItemPickupProps(data, transformObjectEntriesToArray(main)),
    };
  };

  return (
    <SelectElement
      action={action}
      propsBefore={defaultListItemPickupProps(before, readableBefore)}
      propsAfter={defaultListItemPickupProps(after, readableAfter)}
    />
  );
};

const RenderUserBox = ({ data }) => {
  const { before, after, action, readableBefore, readableAfter } = data;

  return (
    <SelectElement
      action={action}
      childrenAfter={
        <DonorDisplay
          record={after}
          additionalData={transformObjectEntriesToArray(readableAfter || {})}
        />
      }
      childrenBefore={
        <DonorDisplay
          record={before}
          additionalData={transformObjectEntriesToArray(readableBefore || {})}
        />
      }
    />
  );
};

const RenderNotificationBox = ({ data }) => {
  const { before, after, action } = data;

  const afterData = {
    email: after.pickupNotifications.email,
    text: after.pickupNotifications.text,
    allowMarketingCalls: after.allowMarketingCalls,
  };
  const beforeData = {
    email: before.pickupNotifications.email,
    text: before.pickupNotifications.text,
    allowMarketingCalls: before.allowMarketingCalls,
  };

  return (
    <SelectElement
      action={action}
      childrenAfter={<NotificationSettings externalData={afterData} />}
      childrenBefore={<NotificationSettings externalData={beforeData} />}
    />
  );
};

const RenderNoteBox = ({ data, noteCategories }) => {
  const { before, after, actionInitiator, action } = data;
  const category =
    noteCategories[after.noteCategoryId || before.noteCategoryId];

  const createProps = (actionData) => {
    return {
      notEditable: true,
      note: { poster: actionInitiator, ...actionData, category },
    };
  };

  return (
    <SelectElement
      action={action}
      childrenAfter={<Note {...createProps(after)} />}
      childrenBefore={<Note {...createProps(before)} />}
    />
  );
};

const ImageContainer = ({ url, imgSize }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <img
        style={{ objectFit: "contain" }}
        src={url}
        width={imgSize}
        height={imgSize}
      />
    </div>
  );
};
const RenderImageBox = ({ data }) => {
  const { before, after, action } = data;
  const imgSize = 200;
  return (
    <SelectElement
      action={action}
      childrenAfter={
        <ImageContainer url={after.url || before.url} imgSize={imgSize} />
      }
      childrenBefore={
        <ImageContainer url={before.url || after.url} imgSize={imgSize} />
      }
    />
  );
};

//entityType: 'users', 'pickups', 'locations', 'notes'
const SelectComponent = ({
  entityType,
  createdAt,
  before,
  after,
  actionInitiator,
  action,
  additionalData,
  calendarHandlers,
}) => {
  const [showSource, setShowSource] = useState(false);
  const { common, locations, regions } = additionalData;

  const keysToCheck = Object.keys(common).map((k) => {
    if (k.endsWith("s")) {
      return k.slice(0, -1);
    }
    return k;
  });

  const selectReferenceFromCommon = (key, idRef) => {
    let selectedKey;
    let selectedKeyToReturn;
    let selectedReference;
    switch (key) {
      //Replace id part to select necessary data from common. Some tablenames is without s
      case "userTypeId":
      case "leadSourceId":
      case "locationTypeId":
        selectedKey = key.replace("Id", "");
        selectedKeyToReturn = key.replace("Id", "");
        break;

      default:
        selectedKey = key.replace("Id", "s");
        selectedKeyToReturn = key.replace("Id", "");
        break;
    }
    selectedReference = common[selectedKey][idRef];
    const objToReturn = { key: selectedKeyToReturn, label: null };

    switch (selectedKey) {
      case "languages":
        objToReturn.label = selectedReference.name;
        break;
      case "pickupStatusDescriptions":
        objToReturn.label = selectedReference.description;
        break;

      default:
        objToReturn.label = selectedReference.label;
        break;
    }
    return objToReturn;
  };

  //set changes to values in after and before to show to user
  const modifier = (data) => {
    data?.createdAt && delete data.createdAt;
    data?.updatedAt && delete data.updatedAt;
    data?.passwordHash && delete data.passwordHash;
    data?.id && delete data.id;

    const modifiedData = {};
    Object.entries(data).forEach(([key, value]) => {
      //format data values
      if (iso8601Pattern.test(value)) {
        modifiedData[key] = formatTime(value);
        return;
      }
      //logic to replace id values with its label from common/location/region
      if (value && key.endsWith("Id")) {
        let keyToReplace;
        switch (key) {
          case "locationId":
            modifiedData.location = locations[value]?.fullAddress;
            return;
          case "regionId":
            modifiedData.region = regions[value]?.label;
            return;

          //replaces to bypass  exceptions in names between a tablename and  its column name
          case "statusId":
            keyToReplace = "pickupStatuseId";
            break;
          case "scheduleSourceId":
            keyToReplace = "pickupSourceId";
            break;
          case "statusReasonId":
            keyToReplace = "pickupStatusDescriptionId";
            break;

          default:
            keyToReplace = key;
            break;
        }
        const keyWithoutId = keyToReplace.replace("Id", "");
        // check if key exists in common
        const includesAny = keysToCheck.some((substring) =>
          keyWithoutId.includes(substring)
        );

        if (includesAny) {
          const { key: newKey, label } = selectReferenceFromCommon(
            keyToReplace,
            value
          );
          modifiedData[newKey] = label;
          return;
        }
        return;
      }
      modifiedData[key] = value;
    });
    return modifiedData;
  };

  const keyConverter = (data) => {
    const obj = {};

    if (typeof data === "object") {
      Object.entries(data).forEach(([key, value]) => {
        //hide value if not exist or object. To show object - create in modifier fn certain case and create a string to show needed info
        if (
          (value || typeof value === "boolean" || typeof value === "number") &&
          typeof value !== "object"
        ) {
          obj[convertCamelCaseToReadable(key)] = value;
        }
      });
    }
    return obj;
  };

  const actionDataToReadable = () => {
    const modifiedBefore = modifier(before);
    const modifiedAfter = modifier(after);

    let readableBefore = {};
    let readableAfter = {};

    //remove undefind values and convert keys to readeble format
    switch (action) {
      case "destroy":
        readableBefore = keyConverter(modifiedBefore);
        break;

      case "create":
        readableAfter = keyConverter(modifiedAfter);
        break;
      //if update set values to before and after object based on keys from diffValues.updated
      case "update":
        const diffValues = detailedDiff(modifiedBefore, modifiedAfter);

        Object.entries(diffValues.updated).forEach(([key, value]) => {
          readableAfter[convertCamelCaseToReadable(key)] = value;
          readableBefore[convertCamelCaseToReadable(key)] = modifiedBefore[key];
        });
        readableBefore = readableBefore;
        break;

      default:
        break;
    }
    return { readableBefore, readableAfter };
  };

  const { readableAfter, readableBefore } = actionDataToReadable();

  const data = {
    entityType,
    createdAt,
    before,
    after,
    readableBefore,
    readableAfter,
    actionInitiator,
    action,
  };

  let componentToReturn = <></>;

  switch (entityType) {
    case "users":
      if (
        before &&
        typeof before === "object" &&
        Object.keys(before).length &&
        (before.pickupNotifications.email !== after.pickupNotifications.email ||
          before.pickupNotifications.text !== after.pickupNotifications.text ||
          before.allowMarketingCalls !== after.allowMarketingCalls)
      ) {
        componentToReturn = <RenderNotificationBox data={data} />;
        break;
      }
      componentToReturn = <RenderUserBox data={data} />;
      break;
    case "pickups":
      componentToReturn = (
        <RenderPickupBox
          data={data}
          pickupStatusesMap={additionalData.pickupStatusesMap}
        />
      );
      break;
    case "locations":
      componentToReturn = <RenderLocationBox data={data} />;
      break;

    case "phoneNumbers":
      const phoneNumbersData = {
        ...data,
        before: {
          ...data.before,
          phoneNumbers: [data.before.number && { number: data.before.number }],
        },
        after: {
          ...data.after,
          phoneNumbers: [data.after.number && { number: data.after.number }],
        },
      };
      componentToReturn = <RenderUserBox data={phoneNumbersData} />;
      break;
    case "notes":
      componentToReturn = (
        <RenderNoteBox
          noteCategories={additionalData.common.noteCategories}
          data={data}
        />
      );
      break;
    case "pickupImages":
      componentToReturn = <RenderImageBox data={data} />;
      break;

    default:
      break;
  }

  return (
    <div style={{ padding: 30, backgroundColor: "#eee" }}>
      <Calendar time={createdAt} {...calendarHandlers} />
      <div className="d-flex justify-content-center">
        <additionalData.TitleComponent
          userName={actionInitiator.fullName}
          actionType={action}
          entityType={entityType}
        />
      </div>
      <div style={{ padding: "20px 0" }} className="d-flex justify-content-end">
        <Button
          style={{ padding: "0", border: 0 }}
          variant="link"
          onClick={() => setShowSource((prev) => !prev)}
        >
          {showSource ? " Hide Source" : " View Source"}
        </Button>
      </div>
      {!showSource ? (
        <div>{componentToReturn}</div>
      ) : (
        <CompareActions createdAt={createdAt} before={before} after={after} />
      )}
    </div>
  );
};

export default SelectComponent;
