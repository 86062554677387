import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Badge, Button, Collapse } from "react-bootstrap";
import Icon from "components/icon";
import { formatPhone, getTextColor } from "utils/helpers";
import PickupModal from "components/pickup-modal";
import { findDOMNode } from "react-dom";
import { getFormatedTime } from "utils/helpers";
import { Note } from "forms/notes";

const NoteSection = ({ notes }) => {
  const length = notes?.length;
  const [showAllNotes, setShowAllNotes] = useState(false);

  if (!(Array.isArray(notes) && length)) {
    return null;
  }

  return (
    <>
      <Collapse in={showAllNotes}>
        <div>
          {notes.map((note, idx) => {
            //don't show last note
            if (idx === length - 1) {
              return null;
            }
            return <Note note={note} />;
          })}
        </div>
      </Collapse>

      <Note note={notes[length - 1]} />
      {length > 1 && (
        <div className="d-grid mb-2">
          <Button
            onClick={() => {
              setShowAllNotes(!showAllNotes);
            }}
            variant="outline-primary"
            size="sm"
          >
            {!showAllNotes ? `Show +${length - 1} More Notes` : "Hide"}
          </Button>
        </div>
      )}
    </>
  );
};

// https://www.npmjs.com/package/react-loading

class RoutePickup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPickupForm: false,
      editPickup: {},
      routes: {},
      showAllNotes: false,
      // Assign Pickup Modal
      showAssignPickupForm: false,
      assignPickupRouteId: null,
    };
  }

  assignPickupRouteId = () => {
    const routeId = findDOMNode(
      this.refs[`pickup-assign-route-${this.state.editPickup.id}`]
    ).value;
    this.setState({
      assignPickupRouteId: routeId,
    });
  };

  assignStopOptions(props, state) {
    let { pickup } = this.props;

    const pickupStops = props.pickupRoutes.routesMap[state.assignPickupRouteId]
      ? [...props.pickupRoutes.routesMap[state.assignPickupRouteId].pickups]
      : [];

    if (
      (pickup && !pickup.routeId) ||
      (pickup && pickup.routeId !== state.assignPickupRouteId)
    ) {
      pickupStops.push(1);
    }

    if (pickupStops.length === 0) {
      pickupStops.push(1);
    }

    return pickupStops.map((pickupId, index) => {
      return (
        <option value={index + 1} key={index}>
          Stop {index + 1}
        </option>
      );
    });
  }

  // updatePickupStopNumber = (pickupId) => {
  //   const pickup = this.props.pickupRoutes.pickupsMap[pickupId];
  //   const oldStopNumber = pickup.stopNumber;
  //   const newStopNumber = +findDOMNode(
  //     this.refs[`pickupRoute-${pickupId}-stopNumber`]
  //   ).value; // convert to number
  //
  //   const pickupOrder = this.props.pickupRoutes.routesMap[pickup.routeId]
  //     .pickups;
  //   const newPickupOrder = moveArrayElement(
  //     pickupOrder,
  //     oldStopNumber,
  //     newStopNumber
  //   );
  //
  //   return this.props
  //     .updatePickupOrder(pickup.routeId, { pickupIds: newPickupOrder })
  //     .then(() => {
  //       this.setDate(this.props.pickupRoutes.pickupDate, pickup.routeId);
  //     });
  // };

  _handleEditPickupClick() {
    let { pickup, requestRoutePickupBlocks } = this.props;

    // Requests the PickupBlocks for this
    requestRoutePickupBlocks({
      zipCode: pickup.location.zip,
      includePickupBlockId: pickup.pickupBlockId,
    });
    this.setState({
      showPickupForm: true,
      editPickup: pickup,
    });
  }

  _closeModal = () =>
    this.setState({
      showPickupForm: false,
      showAssignPickupForm: false,
      showUpdateRouteDriverConfirmation: false,
      updateRouteDriverRouteId: null,
      showUpdatePickupStatus: false,
      showConfirmRouteClear: false,
    });

  renderPickupForm() {
    return (
      <Modal show={this.state.showPickupForm} onHide={this._closeModal} lg>
        <Modal.Header closeButton>
          <Modal.Title>Edit Pickup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PickupModal
            initialValues={this.state.editPickup}
            locations={[this.state.editPickup.location]}
            pickupStatuses={this.props.pickupRoutes.pickupStatuses}
            pickupStatusDescriptions={
              this.props.pickupRoutes.pickupStatusDescriptions
            }
            pickupSources={this.props.pickupRoutes.pickupSources}
            pickupBlocks={this.props.routePickupBlocks}
            onCancel={this._closeModal}
            onSubmit={this.handleEditPickupSubmit}
          />
        </Modal.Body>
      </Modal>
    );
  }

  render() {
    let { pickup, auth, index, color } = this.props;
    const isPickupStatus = pickup.statusId === 1;

    // Get address from either the map or put one together from the address provided
    let mapAddress = pickup.location.mapAddress
      ? pickup.location.mapAddress
      : pickup.location.address +
        ", " +
        pickup.location.city +
        ", " +
        pickup.location.state +
        ", " +
        pickup.location.zip;

    return (
      <div>
        <h3>
          <Badge
            ref={(el) => {
              if (el) {
                el.style.setProperty("background-color", color, "important");
                el.style.setProperty("color", getTextColor(color), "important");
              }
            }}
            bg={"secondary"}
            pill
            style={{
              fontSize: "1.25rem",
              fontWeight: "bold",
            }}
          >
            {index + 1}
          </Badge>
          <Link to={`/dashboard/donors/${pickup.userId}`}>
            {" "}
            {pickup.user.fname} {pickup.user.lname}
          </Link>
          <small>
            <Badge bg={"light"}>{pickup.id}</Badge>
          </small>
        </h3>

        {isPickupStatus
          ? pickup.user.phoneNumbers.map((phone) => {
              return (
                <div key={phone.id}>
                  <a href={`tel:${phone.number}`}>
                    {formatPhone(phone.number)}
                  </a>
                </div>
              );
            })
          : null}
        {mapAddress ? (
          <a
            rel="noopener noreferrer"
            href={`http://maps.google.com/?q=${encodeURIComponent(mapAddress)}`}
            target="_blank"
          >
            {mapAddress}
          </a>
        ) : null}
        {pickup.location.unit ? (
          <div>
            <label style={{ display: "inline-block" }}>Unit:</label>{" "}
            {pickup.location.unit}{" "}
          </div>
        ) : null}
        {pickup.estimatedPickupDuration ? (
          <div>
            <label style={{ display: "inline-block" }}>Pickup Duration:</label>{" "}
            {pickup.estimatedPickupDuration} minutes
          </div>
        ) : null}

        {isPickupStatus ? (
          <span>
            {pickup.location.development ? (
              <div>
                <label style={{ display: "inline-block" }}>Development:</label>{" "}
                {pickup.location.development}{" "}
              </div>
            ) : null}
            {pickup.location.subdivision ? (
              <div>
                <label style={{ display: "inline-block" }}>Subdivision:</label>{" "}
                {pickup.location.subdivision}{" "}
              </div>
            ) : null}
            {pickup.location.gateCode ? (
              <div>
                <label style={{ display: "inline-block" }}>Gate Code:</label>{" "}
                {pickup.location.gateCode}{" "}
              </div>
            ) : null}
            {pickup.location.notes ? (
              <div>
                <label style={{ display: "inline-block" }}>
                  Cross Streets:
                </label>{" "}
                {pickup.location.notes}{" "}
              </div>
            ) : null}
          </span>
        ) : null}
        {pickup.routeId && pickup.stopNumber ? (
          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            {isPickupStatus && pickup.nextStopNotificationSent ? (
              <span
                style={{
                  padding: "8px 15px",
                  backgroundColor: color,
                  color: getTextColor(color),
                  borderRadius: "5em",
                  fontSize: "16px",
                }}
              >
                {!pickup.arrivedAt
                  ? `Started (${getFormatedTime(
                      pickup.nextStopNotificationSent
                    )})`
                  : `Arrived (${getFormatedTime(pickup.arrivedAt)})`}
              </span>
            ) : null}
            <Badge variant="secondary">
              {isPickupStatus &&
              auth.credentials.userType.label !== "driver" &&
              !pickup.nextStopNotificationSent ? (
                <span>
                  {" "}
                  <Icon type="timeWindow" />{" "}
                  {pickup.timeWindow ? pickup.timeWindow : "NA"}{" "}
                </span>
              ) : null}
            </Badge>
            {pickup.distanceFromPreviousMiles ? (
              <div>
                <Badge variant="secondary">
                  <Icon type="truck" /> {"  "}
                  {pickup.distanceFromPreviousMiles} Miles
                </Badge>
              </div>
            ) : null}
          </div>
        ) : null}
        <NoteSection notes={pickup.notes} />
        {/* {Array.isArray(pickup.notes) && pickup.notes.length && (
          <>
            {this.state.showAllNotes ? (
              Array.isArray(pickup.notes) &&
              pickup.notes.map((note) => {
                return <Note note={note} />;
              })
            ) : (
              <Note note={pickup.notes[pickup.notes.length - 1]} />
            )}
            <div className="d-grid mb-2">
              {pickup?.notes?.length > 1 && (
                <Button
                  onClick={() => {
                    this.setState({ showAllNotes: !this.state.showAllNotes });
                  }}
                  variant="outline-primary"
                  size="sm"
                >
                  {!this.state.showAllNotes
                    ? `Show +${pickup.notes.length - 1} More Notes`
                    : "Hide"}
                </Button>
              )}
            </div>
          </>
        )} */}

        {this.renderPickupForm()}
      </div>
    );
  }
}

export default RoutePickup;
