import "leaflet.vectorgrid";
import { GeoJSON } from "react-leaflet";
import { useEffect, useRef } from "react";
const ChunksLayer = ({ onClick, chunks }) => {
  const computeGeoJSON = (chunks) => {
    if (!chunks) {
      return null;
    }
    return {
      type: "FeatureCollection",
      features: chunks.map(({ data }) => data.features).flat(),
    };
  };

  const geoJsonLayer = useRef(null);

  useEffect(() => {
    if (geoJsonLayer.current) {
      geoJsonLayer.current.clearLayers().addData(computeGeoJSON(chunks));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chunks]);

  return (
    <GeoJSON
      eventHandlers={{
        click: onClick,
      }}
      ref={geoJsonLayer}
      data={computeGeoJSON(chunks)}
      style={() => {
        return {
          color: "#afa2c3",
          weight: 1,
          fill: true,
          fillOpacity: 0,
        };
      }}
    />
  );
};
export default ChunksLayer;
