import { connect } from "react-redux";
import {
  requestCustomerAction,
  requestCustomerActions,
  requestPickupsActions,
  clearCustomerActions,
  clearPickupActions,
} from "./customer-timeline.actions";

import DonorTimeline from "./customer-timeline-new.component";

function mapStateToProps(state, ownProps) {
  const locationsMap = {};
  const regionsMap = {};
  state.locations.locations.forEach((element) => {
    locationsMap[element.id] = element;
  });
  state.regions.regions.forEach((element) => {
    regionsMap[element.id] = element;
  });
  const timelineActions = ownProps.pickupMode
    ? state.pickupActions
    : state.customerActions;
  return {
    pickupStatuses: state.common.data.pickupStatuses,
    pickupStatusesMap: state.common.dataMap.pickupStatuses,
    common: state.common.dataMap,
    locations: locationsMap,
    regions: regionsMap,
    timelineActions,
    isFetching: timelineActions.isFetching,
    isLoaded: timelineActions.loaded,
    loadedOpenedAction: timelineActions.loadedOpenedAction,
    isFetchingOpenedAction: timelineActions.isFetchingOpenedAction,
    pagination: {
      page: timelineActions.page,
      totalPages: timelineActions.totalPages,
      limit: timelineActions.limit,
    },
  };
}

export default connect(mapStateToProps, {
  requestCustomerAction,
  requestCustomerActions,
  requestPickupsActions,
  clearCustomerActions,
  clearPickupActions,
})(DonorTimeline);
