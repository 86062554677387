import React from "react";
import { Field } from "redux-form";
import FormField from "forms/FormField";
import DatePicker from "forms/date-picker";
import { required, requiredDropdown } from "utils/validators";

const PickupExport = (props) => {
  const { pickupTypes } = props;

  const filteredPickupStatusDescriptions =
    props.pickupStatusDescriptions.filter(
      (description) => description.statusId === Number(props.pickupStatusId)
    );

  return (
    <React.Fragment>
      <Field
        component={FormField}
        label={"Filter By"}
        name={"pickupFilterType"}
        type="select"
        options={[
          { id: "pickupDate", label: "Pickup Date" },
          { id: "createdAt", label: "Date Pickup Created" },
        ]}
        required={true}
        validate={requiredDropdown}
      />
      <Field
        className="report-date-picker"
        component={DatePicker}
        label="Start Date"
        name="startDate"
        required={true}
        type="text"
        validate={required}
      />
      <Field
        className="report-date-picker"
        component={DatePicker}
        label="End Date"
        name="endDate"
        required={true}
        type="text"
        validate={required}
      />
      <Field
        name="regionId"
        component={FormField}
        label="Region"
        placeholder="All Regions"
        type="select"
        options={props.regions}
        required={true}
        validate={required}
      />
      <Field
        name="zip"
        component={FormField}
        label="Zip"
        placeholder="All Zipcodes"
        type="text"
        maxLength={5}
        // required={true}
        // validate={required}
      />

      <Field
        label="Pickup Type"
        name="pickupType"
        options={pickupTypes}
        placeholder="All"
        component={FormField}
        type="select"
        required={true}
        validate={required}
      />

      <Field
        label="Pickup Source"
        name="sourceId"
        options={props.pickupSources}
        placeholder="All"
        component={FormField}
        type="select"
        className="form-control"
        required={true}
        validate={required}
      />

      <Field
        label="Verification Status"
        name="verified"
        options={[
          { id: false, label: "Unverified" },
          { id: true, label: "Verified" },
        ]}
        placeholder="All"
        component={FormField}
        type="select"
        required={true}
        validate={required}
      />

      <Field
        name="statusId"
        label="Pickup Status"
        options={props.pickupStatuses}
        placeholder="All"
        component={FormField}
        type="select"
        className="form-control"
        required={true}
        validate={required}
        onChange={() => {
          props.change("statusReasonId", "-1");
        }}
      />

      <Field
        label="Pickup Status Description"
        name="statusDescriptionId"
        renderCondition={filteredPickupStatusDescriptions.length > 0}
        options={filteredPickupStatusDescriptions}
        placeholder="All"
        component={FormField}
        type="select"
        optionField="description"
        className="form-control"
        required={true}
        validate={required}
      />
    </React.Fragment>
  );
};
export default PickupExport;
