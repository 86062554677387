import { useCallback, useState } from "react";
import { Button } from "react-bootstrap";

import Modal from "components/modal";
import RouteAssignPickup from "../route-assign-pickup";

const RouteAssignPickupButton = ({
  auth,
  modalOnly,
  showModal,
  closeModal,
  label,
  ...rest
}) => {
  const [showForm, setShowForm] = useState(false);

  const closeForm = useCallback(() => {
    setShowForm(false);
    if (closeModal) closeModal();
  }, [closeModal]);

  return (
    <>
      {!modalOnly &&
      (auth.credentials.userTypeId === 1 ||
        auth.credentials.userTypeId === 2) ? (
        <Button
          variant="primary"
          onClick={() => {
            setShowForm(true);
          }}
        >
          {label || "Re-Assign"}
        </Button>
      ) : null}

      <Modal
        show={showForm || showModal}
        onHide={closeForm}
        backdrop={"static"}
        lg
        title={"Assign Pickup"}
      >
        <RouteAssignPickup
          {...rest}
          onCancel={closeForm}
          onSubmitSuccess={closeForm}
        />
      </Modal>
    </>
  );
};

export default RouteAssignPickupButton;
