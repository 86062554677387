import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import RenderIf from "../hoc/render-if";
import RichTextEditor from "components/rich-text-editor";
import { Form, FormLabel, FormGroup, FormControl } from "react-bootstrap";

const FormField = ({
  input,
  label,
  type,
  maxLength,
  placeholder,
  removeField,
  disabled,
  optionField = "label",
  options,
  renderCondition = true,
  meta: { touched, error, valid },
  required,
  invert,
  style,
}) => {
  useEffect(() => {
    if (type === "select" && !input.value) input.onChange("-1");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If there was an error because there was an interaction
  const validationError = touched && error;
  const validationSuccess = valid && !!input.value;

  let errorMessage;

  // If we've deteced an error
  if (validationError) {
    // create error message var for storing message to display

    // Attempt to parse Joi errors from the API
    if (error[0] === '"' && error.slice(1).indexOf('"')) {
      errorMessage = `${label || "This"}${error.substr(
        error.slice(1).indexOf('"') + 2
      )}`;
    }
  }

  const RenderField = () => {
    let field;
    switch (type) {
      case "checkbox":
        field = (
          <Form.Check
            {...input} // TODO: Verify we need to be passing this prop
            {...(invert
              ? {
                  checked: !input.checked,
                  value: !input.value,
                  onChange: (e) => {
                    e.preventDefault();
                    input.onChange(!input.checked);
                  },
                }
              : {})}
            type="checkbox"
            // label must have at least some value to make wrap div with propper class
            label={label ? label : " "}
            id={input.name}
            disabled={disabled}
            key={input.checked}
          />
        );
        break;

      case "select":
        field = (
          <FormControl
            as="select"
            {...input} // TODO: Verify we need to be passing this prop
            placeholder={placeholder}
            isInvalid={validationError}
            isValid={validationSuccess}
            required={required}
            disabled={disabled}
            label={label}
          >
            {[
              { id: -1, [optionField]: placeholder || "Select an option" },
              ...options,
            ].map((option, i) => {
              return (
                <option key={i} value={option.id}>
                  {option[optionField]}
                </option>
              );
            })}
          </FormControl>
        );
        break;

      case "textarea":
        field = (
          <FormControl
            as="textarea"
            {...input} // TODO: Verify we need to be passing this prop
            placeholder={placeholder}
            type={type}
            maxLength={maxLength}
            isInvalid={validationError}
            isValid={validationSuccess}
            required={required}
            disabled={disabled}
            label={label}
          />
        );
        break;

      case "rich":
        field = (
          <RichTextEditor value={input.value} onChange={input.onChange} />
        );
        break;

      default:
        field = (
          <FormControl
            {...input} // TODO: Verify we need to be passing this prop
            placeholder={placeholder}
            type={type}
            maxLength={maxLength}
            isInvalid={validationError}
            isValid={validationSuccess}
            required={required}
            disabled={disabled}
            label={label}
          />
        );
    }
    return field;
  };

  return (
    <RenderIf condition={renderCondition}>
      <FormGroup className={`${type}-form-group`} style={style}>
        <RenderIf condition={type !== "hidden" && type !== "checkbox"}>
          <FormLabel>
            {label}
            <RenderIf condition={required}>
              <span className="required-asterick">*</span>
            </RenderIf>
          </FormLabel>
        </RenderIf>

        <div className={"d-flex"}>
          {RenderField()}
          <RenderIf condition={removeField}>
            <div>
              <Button variant="primary" onClick={removeField}>
                X
              </Button>
            </div>
          </RenderIf>
        </div>
        <RenderIf condition={validationError}>
          <FormControl.Feedback className="d-block" type="invalid">
            {errorMessage || error}
          </FormControl.Feedback>
        </RenderIf>
      </FormGroup>
    </RenderIf>
  );
};

FormField.propTypes = {
  input: PropTypes.object,
  isReports: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
  touched: PropTypes.bool,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  removeField: PropTypes.func,
  required: PropTypes.bool,
};

export default FormField;
