import { AUTHENTICATE_USER_COMPLETE } from "actions/login.actions";

function auth(
  state = {
    isFetching: false,
    loaded: false,
    credentials: {},
    authenticated: false,
  },
  action
) {
  switch (action.type) {
    case AUTHENTICATE_USER_COMPLETE:
      return {
        ...state,
        loaded: true,
        isFetching: false,
        credentials: action.auth,
        authenticated: true,
        lastUpdated: action.receivedAt,
      };
    default:
      return state;
  }
}
const dashboardReducers = { auth };

export default dashboardReducers;
