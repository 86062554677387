import { getPickups, postPickup, putPickup, getPickup } from "api/pickups";

export const REQUEST_PICKUPS = "REQUEST_PICKUPS";
export const RECEIVE_PICKUPS = "RECEIVE_PICKUPS";

export const REQUEST_PICKUP = "REQUEST_PICKUP";
export const RECEIVE_PICKUP = "RECEIVE_PICKUP";

export const REQUEST_PICKUPS_DONOR_PAGE = "REQUEST_PICKUPS_DONOR_PAGE";
export const RECEIVE_PICKUPS_DONOR_PAGE = "RECEIVE_PICKUPS_DONOR_PAGE";

export function requestPickupsForDonor(data = {}) {
  data.searchType = "donorPage";
  return (dispatch) => {
    dispatch({ type: REQUEST_PICKUPS_DONOR_PAGE, data });

    getPickups(data)
      .then((res) => dispatch(receivePickupsForDonor(res.data)))
      .catch((res) => console.error(res));
  };
}
export function receivePickupsForDonor(data = {}) {
  return {
    type: RECEIVE_PICKUPS_DONOR_PAGE,
    pickups: normalizePickups(data.data),
  };
}
export function requestPickup(id, data = {}) {
  data.type = "form";
  return async (dispatch) => {
    dispatch({ type: REQUEST_PICKUP });

    return await getPickup(id, data)
      .then((res) => dispatch(receivePickup(res.data)))
      .catch((res) => console.error(res));
  };
}
export function receivePickup(data = {}) {
  return {
    type: RECEIVE_PICKUP,
    pickup: data.data[0],
  };
}

export function requestPickups(data = {}) {
  return (dispatch) => {
    dispatch({ type: REQUEST_PICKUPS, data });

    getPickups(data)
      .then((res) => dispatch(receivePickups(res.data)))
      .catch((res) => console.error(res));
  };
}

const normalizePickups = (pickups) =>
  pickups.map((pickup) => {
    pickup.pickupBlockId = pickup.pickupBlockId
      ? pickup.pickupBlockId
      : "false";
    return pickup;
  });

export function receivePickups(data = {}) {
  return {
    type: RECEIVE_PICKUPS,
    pickups: normalizePickups(data.data),
    pickupStatuses: data.meta && data.meta.pickupStatuses,
    pickupStatusDescriptions: data.meta && data.meta.pickupStatusDescriptions,
    pickupSources: data.meta && data.meta.pickupSources,
    count: data.count,
    pagination: data.pagination,
    receivedAt: Date.now(),
  };
}

export const CREATE_PICKUP = "CREATE_PICKUP";
export const CREATE_PICKUP_COMPLETED = "CREATE_PICKUP_COMPLETED";

export const UPDATE_PICKUP = "UPDATE_PICKUP";
export const UPDATE_PICKUP_COMPLETED = "UPDATE_PICKUP_COMPLETED";

export const CLEAR_PICKUPS = "CLEAR_PICKUPS";

export function createPickup(data = {}) {
  return (dispatch) => {
    // We store a string of "false" for pickupBlockId if "Other" is chosen, but we don't want to send this to the server.
    // We set this value when loading a pickup so that the "PickupBlock" select dropdown can have this "other" option

    // set a new variable to modify for the request or the interface resonds to updates to the original data object
    const pickup = { ...data };

    if (pickup.pickupBlockId === "false") {
      delete pickup.pickupBlockId;
    }

    dispatch({ type: CREATE_PICKUP, data: pickup });

    return postPickup(data).then((res) =>
      dispatch(createPickupCompleted(res.data))
    );
  };
}

function createPickupCompleted(data = {}) {
  return {
    type: CREATE_PICKUP_COMPLETED,
    pickup: data.data[0],
    receivedAt: Date.now(),
  };
}

export function clearPickups() {
  return {
    type: CLEAR_PICKUPS,
  };
}

export function updatePickup(data = {}) {
  return (dispatch) => {
    dispatch({ type: UPDATE_PICKUP, data: data });

    return putPickup(data.id, data).then((res) =>
      dispatch(updatePickupCompleted(res.data))
    );
  };
}

function updatePickupCompleted(data) {
  return {
    type: UPDATE_PICKUP_COMPLETED,
    pickup: normalizePickups(data.data)[0],
    receivedAt: Date.now(),
  };
}
