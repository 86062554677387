import { connect } from "react-redux";
import { formValueSelector, change } from "redux-form";

import PickupFormComponent from "./create-update-pickup.component";
import {
  REACT_APP_MP_DRIVER_PICKUP_STATUSES,
  REACT_APP_MP_SINGLE_REGION,
} from "config";
import { formatWeekdayHyphenDate } from "utils/dates";
import withForm from "forms/form-class";
import {
  requestPickupBlocks,
  clearPickupBlocks,
} from "actions/pickup-blocks.actions";
import { requestRegionsFiltered } from "actions/regions.actions";

// Validation
/*
 *
 * Validate Form
 *
 * */
const validate = (values) => {
  const errors = {};

  if (values.pickupBlockId === "" || values.pickupBlockId === undefined) {
    errors.pickupBlockId = `You must specify a value or "other" for a pickup block`;
  }

  if (!values.pickupDate) {
    errors.pickupDate = `You must specify a value for the pickup date`;
  }

  return errors;
};

// Redux-Form forValueSelector
const selector = formValueSelector("NewPickup");

const PickupBlockChanged = (newValues, dispatch, props) => {
  const customPickupBlockSelected = newValues.pickupBlockId === "false";
  const pickupBlocksLoaded =
    props.pickupBlocksLoaded && !props.pickupBlocksLoading;

  dispatch(props.clearFields(false, false, "pickupDate"));

  // If the pickupBlock is updated
  if (pickupBlocksLoaded && !customPickupBlockSelected) {
    const newPickupBlock = props.pickupBlocks.find(
      (pickupBlock) => pickupBlock.id === parseInt(newValues.pickupBlockId)
    );

    if (newPickupBlock) {
      dispatch(props.change("regionId", newPickupBlock.regionId));
      dispatch(props.change("pickupDate", newPickupBlock.date));
      dispatch(props.change("pickupBlockId", newPickupBlock.id));
    }
  }

  if (customPickupBlockSelected) {
    dispatch(props.clearFields(false, false, "regionId"));
    dispatch(props.clearFields(false, false, "pickupDate"));
    dispatch(props.change("pickupBlockId", "false"));
  }
};

const LocationChanged = (newValues, dispatch, props) => {
  // Reset pickup block if location is changed
  dispatch(props.clearFields(false, false, "pickupBlockId"));
  dispatch(props.clearFields(false, false, "regionId"));
  dispatch(props.clearFields(false, false, "pickupDate"));
};

const handleOnFormChange = (newValues, dispatch, props, oldValues) => {
  if (newValues.locationId !== oldValues.locationId) {
    LocationChanged(newValues, dispatch, props);
  }

  if (newValues.pickupBlockId !== oldValues.pickupBlockId) {
    PickupBlockChanged(newValues, dispatch, props);
  }
};

// Connect Redux Form
const PickupFormConnected = withForm(
  PickupFormComponent,
  "NewPickup",
  validate,
  handleOnFormChange
);

const selectRegion = (value) => {
  return (dispatch) => {
    dispatch(change("NewPickup", "regionId", value));
  };
};

function setPickupTypeId(value) {
  return (dispatch) => {
    dispatch(change("NewPickup", "pickupTypeId", value));
  };
}
const mapDispatchToProps = {
  clearPickupBlocks,
  requestPickupBlocks,

  requestRegionsFiltered,
  selectRegion,
  setPickupTypeId,
};

const mapStateToProps = (state, ownProps) => {
  const pickupStatus = selector(state, "statusId");
  const pickupDate = selector(state, "pickupDate");
  const largeItems = !!selector(state, "largeItems"); // make boolean because this can be undefined
  const pickupBlockId = selector(state, "pickupBlockId");
  const pickupTypeId = selector(state, "pickupTypeId");

  // Todo: Do better than hard coding these values, the intended values don't always go in to the database with these ID's and require manual updates in some cases
  const initialValues = {
    statusId: "1",
    scheduleSourceId: "7",
    userId: ownProps.userId,
    ...ownProps.initialValues,
    ...(REACT_APP_MP_SINGLE_REGION === "true" && { regionId: 1 }),
  };

  const filterStatuses = (statuses) => {
    return statuses.filter((status) => {
      // If this form already has a status associated with it, regardless of weather it's disabled, it should be included
      if (initialValues.status && initialValues.status.id === status.id) {
        return true;
      }
      // Include the rest based on the disabled fla
      return !status.disabled;
    });
  };

  const filterLocations = (locations) => {
    const generateLabel = (location) =>
      `${location.label ? location.label.toUpperCase() + " - " : ""}${
        location.fullAddress
      }`;

    if (initialValues.location)
      return [
        {
          ...initialValues.location,
          addressLabel: generateLabel(initialValues.location),
        },
      ];

    return locations.filter((location) => {
      if (
        location.customerId !==
        (Number(ownProps.userId) || ownProps.initialValues?.userId)
      )
        return false;

      // If there is a label show it with the address in the string
      location.addressLabel = generateLabel(location);

      // If this one is selected return true
      if (initialValues.location && initialValues.location.id === location.id) {
        return true;
      }

      return !location.disabled;
    });
  };

  //TODO: abstract moment out of here, this should be centralized
  const filterPickupBlocks = (pickupBlocks) => {
    // If a pickupBlock is already set, only return this pickupBlocks value
    /* TODO: use pickupType label instead of hasFurniture */
    if (initialValues.pickupBlockId && initialValues.pickupBlock)
      return [
        {
          ...initialValues.pickupBlock,
          label: `${formatWeekdayHyphenDate(
            initialValues.pickupBlock.date
          )}  -  ${initialValues.pickupBlock.zone.name}  - ${
            initialValues.pickupBlock.hasFurniture ? "Furniture" : "Standard"
          }`,
        },
      ];

    // Filter out any pickup blocks which have no spots available, arent enabled, and make sure we match pickupType
    const filteredBlocks = pickupBlocks.filter(
      (p) =>
        p.id === "false" ||
        (p.stopsAvailable > 0 &&
          p.enabled &&
          p.zone.pickupTypeId === parseInt(pickupTypeId))
    );

    return [
      ...filteredBlocks,
      { id: "false", label: "Other (No pickup block)" },
    ];
  };

  const pickupStatuses = filterStatuses(state.common.data.pickupStatuses);

  return {
    pickupStatus,
    pickupDate,
    initialValues,
    largeItems,
    pickupBlockId,
    pickupTypeId,
    locations: filterLocations(
      state.locations.loaded ? [...state.locations.locations] : []
    ),
    pickups: { ...state.pickups },
    pickupBlocksLoading: state.pickupBlocks.isFetching,
    pickupBlocksLoaded: state.pickupBlocks.loaded,
    pickupBlocks: filterPickupBlocks(state.pickupBlocks.pickupBlocks),
    pickupStatusDescriptions: state.common.data.pickupStatusDescriptions,
    pickupStatuses:
      state.auth?.credentials?.userTypeId === 3 &&
      REACT_APP_MP_DRIVER_PICKUP_STATUSES
        ? pickupStatuses.filter((ps) =>
            REACT_APP_MP_DRIVER_PICKUP_STATUSES.split(",").find(
              (pstatus) => Number(pstatus) === ps.id
            )
          )
        : pickupStatuses,
    pickupSources: state.common.data.pickupSources,
    pickupTypes: state.common.data.pickupTypes,
    regions: {
      ...state.regions,
      regions: state.regions.regions.filter((region) => !region.disabled),
    },
    regionsFiltered: {
      ...state.regionsFiltered,
      regionsFiltered: state.regionsFiltered.regionsFiltered.filter(
        (region) => !region.disabled
      ),
    },
    formValues: state.form.NewPickup && state.form.NewPickup.values,
    auth: state.auth,
    onSubmit: (data) => {
      if (data.pickupBlockId === "false") delete data.pickupBlockId;
      if (data.statusReasonId === "-1") delete data.statusReasonId;
      const notes = [];
      if (data.largeItemNote) {
        notes.push({
          note: data.largeItemNote,
          noteCategoryId: 5,
        });
      }
      if (data.note) {
        notes.push({
          note: data.note,
          noteCategoryId: 2,
        });
      }
      if (notes.length > 0) {
        data.notes = JSON.stringify(notes);
      }

      return ownProps.onSubmit(data);
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PickupFormConnected);
