import { connect } from "react-redux";

import { createCustomer } from "./customers.new.actions";

import NewCustomerComponent from "./customers.new.component";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  createCustomer,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewCustomerComponent);
