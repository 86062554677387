import { connect } from "react-redux";

import RouteAssignPickup from "./route-assign-pickup.component.js";
const mapStateToProps = (state) => ({
  pickupRoutes: {
    ...state.pickupRoutes,
  },
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RouteAssignPickup);
