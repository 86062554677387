import { connect } from "react-redux";
import { formValueSelector, change } from "redux-form";
import withForm from "forms/form-class";
import { showToast } from "actions/toast.actions";
import NewLocationForm from "./create-update-location.component";
import { LocationTypesProvider } from "../../contexts/location-types";

// Redux-Form forValueSelector
const selector = formValueSelector("NewLocation");

// Connect Redux Form
const NewLocationFormConnected = withForm(
  NewLocationForm,
  "NewLocation",
  null,
  null,
  true,
  [
    {
      label: "Create Pickup",
      action: async (props) => {
        if (!props.pristine || !props.initialValues.id) {
          const res = await props.handleSubmit();
          if (
            res?.type === "UPDATE_LOCATION_COMPLETED" ||
            res?.type === "CREATE_LOCATION_COMPLETED"
          )
            props.openCreatePickupForm(res.location.id);
        } else {
          await props.onCancel();
          props.openCreatePickupForm(props.initialValues.id);
        }
      },
    },
  ]
);

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleSelect: (addressInfo) => {
    const listUndefind = [];

    const checkObjValue = (key, customName) => {
      if (!addressInfo[key]) {
        listUndefind.push(customName || key);
      }
    };

    if (addressInfo.address.includes("false")) {
      listUndefind.push("street");
    }

    Object.keys(addressInfo).forEach((key) => {
      switch (key) {
        case "zipCode":
          checkObjValue(key, "zip code");
          break;
        case "city":
        case "address":
        case "state":
          checkObjValue(key);
          break;

        default:
          break;
      }
    });

    if (listUndefind.length > 0) {
      showToast({
        type: "error",
        timeToClose: 10000,
        message: `Address must contain: ${listUndefind.join(", ")}.`,
      });
    } else {
      dispatch(change("NewLocation", "zip", addressInfo.zipCode));
      dispatch(change("NewLocation", "state", addressInfo.state));
      dispatch(change("NewLocation", "city", addressInfo.city));
      dispatch(change("NewLocation", "addressSearch", addressInfo.address));
      dispatch(change("NewLocation", "address", addressInfo.address));
      dispatch(change("NewLocation", "lat", addressInfo.lat));
      dispatch(change("NewLocation", "lng", addressInfo.lng));
      dispatch(change("NewLocation", "mapAddress", addressInfo.mapAddress));
    }
  },
  onAddressChange: () => {
    dispatch(change("NewLocation", "mapAddress", null));
  },
});

const mapStateToProps = (state, ownProps) => {
  const isGatedCommunity = !!selector(state, "gated");

  return {
    locationTypes: state.common.data.locationType,
    isGatedCommunity,
    initialValues: {
      statusId: 1,
      customerId: ownProps.userId,
      ...ownProps.initialValues,
    },
    ...ownProps,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props) => (
  <LocationTypesProvider>
    <NewLocationFormConnected {...props} />
  </LocationTypesProvider>
));
