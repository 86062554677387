import LeafletSearch from "leaflet-search";

import { createControlComponent } from "@react-leaflet/core";
import config from "config";

const createLeafletElement = (props) => {
  const sourceData = (query, setData) => {
    setData(
      props.zipList.filter(({ zipCode }) => `${zipCode}`.startsWith(query))
    );
  };

  return new LeafletSearch({
    sourceData: sourceData,
    layer: props.geoJSONLayer,
    propertyName: "zipCode",
    propertyLoc: ["lat", "lng"],
    zoom: 12,
    collapsed: false,
    textPlaceholder: config.REACT_APP_MP_PLACEHOLDER_ZIP,
    minLength: 1,
    autoType: true,
    firstTipSubmit: true,
    tipAutoSubmit: true,
    marker: {
      icon: false,
      circle: {
        color: config.REACT_APP_MP_PRIMARY_COLOR,
      },
      animate: false,
    },
  });
};

export default createControlComponent(createLeafletElement);
