import React, { Component } from "react";
import { Outlet } from "react-router";
import "./styles.scss";

import RegionLayout from "./region-layout.container";

const RegionRoutes = () => {
  return (
    <RegionLayout>
      <Outlet />
    </RegionLayout>
  );
};

// RegionRoutes = RegionRoutes;

class Regions extends Component {
  render() {
    return <Outlet />;
  }
}

export { RegionRoutes };
export default Regions;
