import { connect } from "react-redux";
import {
  requestRoutes,
  receiveRoutes,
  receivePickupRoutes,
  updateRoutePickup,
  requestUsers,
  updateRoute,
  updateRouteCompleted,
  updateRouteDriver,
  updatePickup,
  uploadCSV,
  requestRoutePickupBlocks,
  deleteRoute,
  updatePickupOrder,
  updateRouteFurniture,
  startPickup,
} from "actions/routes.actions";
import { getRegion } from "actions/regions.actions";

import { requestLocations } from "actions/locations.actions";

import SinglePickup from "./single-pickup.component";

const mapStateToProps = (state) => {
  return {
    auth: {
      ...state.auth,
    },

    activeRegion: state.regions.activeRegion,

    pickupRoutes: {
      ...state.pickupRoutes,
    },

    users: {
      ...state.users,
    },

    locations: {
      ...state.locations,
    },
    pickupStatuses: state.common.data.pickupStatuses,

    routePickupBlocks: {
      pickupBlocks: state.routePickupBlocks.routePickupBlocks,
      isFetching: state.routePickupBlocks.isFetching,
      loaded: state.routePickupBlocks.loaded,
    },
  };
};

const mapDispatchToProps = {
  requestRoutes,

  receivePickupRoutes,

  updateRoutePickup,

  requestUsers,

  requestLocations,

  updateRoute,

  updateRouteCompleted,

  updateRouteDriver,

  updateRouteFurniture,

  uploadCSV,

  requestRoutePickupBlocks,

  deleteRoute,

  updatePickupOrder,

  receiveRoutes,

  getRegion,

  updatePickup,

  startPickup,
};

export default connect(mapStateToProps, mapDispatchToProps)(SinglePickup);
