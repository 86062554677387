import React from "react";
import NewDonorForm from "forms/create-update-donor";
import ListGroupWithEdit from "components/list-group-with-edit";
import DisplayDonor from "./display.container";

class CustomerInfo extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillUnmount() {
    this.props.clearCustomer();
  }

  render() {
    const { customer, common } = this.props;

    return (
      <ListGroupWithEdit
        name={"Donor"}
        objectArray={[customer]}
        EditComponent={NewDonorForm}
        EditSubmit={async (data) => {
          await this.props.updateCustomer(data);
          await this.props.requestCustomerActions(customer.id);
        }}
        DisplayComponent={DisplayDonor}
      />
    );
  }
}

export default CustomerInfo;
