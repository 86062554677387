import { useLocation, Navigate } from "react-router";
import React from "react";

//temporary solution, until route version 2 will be ready. Will be replaced by role-guard
export const RoleGuardDashboard = ({ allowedRoles, children, auth }) => {
  const location = useLocation();
  const userType = auth.credentials.userType?.label;

  if (!userType) {
    return;
  }

  if (
    !allowedRoles.includes(userType) &&
    !location.pathname.includes("dashboard/route") &&
    auth.credentials.userTypeId === 3
  ) {
    return <Navigate to="/dashboard/route" replace />;
  }

  return children;
};
